import styled from "styled-components"

export const Container = styled.div`
width: 80%;
padding: 4rem 0;
margin: auto;
`;

export const Title = styled.h1`
font-size: 2rem;
margin-bottom: 2rem;
`;

export const Directory = styled.div`
width: 100%;
display: grid;
column-gap: 2.5rem;
row-gap: 1.5rem;
@media ${props => props.theme.devices.mobileXm}{
    grid-template-columns: repeat(1, minmax(0, 1fr));

}
@media ${props => props.theme.devices.tablet}{
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

`;
