export default {
    status: 0,
    data: [
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AFOURAR",
            id: 31,
            $version: null
          },
          {
            name: "Afourer",
            id: 156,
            $version: null
          }
        ],
        displayName: "Afourer",
        artmin: null,
        createdOn: "2020-09-29T16:33:46.278Z",
        inseeCode: null,
        id: 49,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1495,
            $version: 1
          },
          {
            name: "Afourer",
            id: 1046,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1833,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2008,
            $version: null
          },
          {
            name: "Autre",
            id: 2175,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Afourer",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AGADIR",
            id: 24,
            $version: null
          },
          {
            name: "Agadir",
            id: 148,
            $version: null
          }
        ],
        displayName: "Agadir",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 10,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1461,
            $version: 1
          },
          {
            name: "Bouargane",
            id: 621,
            $version: 1
          },
          {
            name: "Charaff",
            id: 622,
            $version: 1
          },
          {
            name: "El Houda",
            id: 624,
            $version: 1
          },
          {
            name: "Founty",
            id: 625,
            $version: 1
          },
          {
            name: "Abattoirs",
            id: 656,
            $version: 0
          },
          {
            name: "Ben Serguaou",
            id: 644,
            $version: 1
          },
          {
            name: "Centre Ville",
            id: 645,
            $version: 1
          },
          {
            name: "Ennahda",
            id: 646,
            $version: 1
          },
          {
            name: "Hay Massira",
            id: 647,
            $version: 1
          },
          {
            name: "Marina",
            id: 649,
            $version: 1
          },
          {
            name: "Riad Salam",
            id: 650,
            $version: 1
          },
          {
            name: "Sonaba",
            id: 651,
            $version: 1
          },
          {
            name: "Tamraght",
            id: 652,
            $version: 1
          },
          {
            name: "Tilila",
            id: 653,
            $version: 1
          },
          {
            name: "Ville Nouvelle",
            id: 654,
            $version: 1
          },
          {
            name: "Wifaq",
            id: 655,
            $version: 1
          },
          {
            name: "Agadir Oufella",
            id: 657,
            $version: 1
          },
          {
            name: "Amicales",
            id: 658,
            $version: 1
          },
          {
            name: "Anahda",
            id: 660,
            $version: 1
          },
          {
            name: "Anza",
            id: 661,
            $version: 2
          },
          {
            name: "Assaka",
            id: 662,
            $version: 1
          },
          {
            name: "Boutchakat",
            id: 663,
            $version: 1
          },
          {
            name: "Charaf",
            id: 664,
            $version: 1
          },
          {
            name: "Cité Adrar",
            id: 665,
            $version: 1
          },
          {
            name: "Haut Anza",
            id: 668,
            $version: 2
          },
          {
            name: "Hay Al Farah",
            id: 669,
            $version: 1
          },
          {
            name: "Hay Al Wafaa",
            id: 670,
            $version: 1
          },
          {
            name: "Agadir",
            id: 1602,
            $version: 1
          },
          {
            name: "Hay Dakhla",
            id: 623,
            $version: 1
          },
          {
            name: "Iligh",
            id: 628,
            $version: 1
          },
          {
            name: "Wafaa",
            id: 638,
            $version: 1
          },
          {
            name: "Av des Far",
            id: 642,
            $version: 1
          },
          {
            name: "Av Mokawama",
            id: 643,
            $version: 1
          },
          {
            name: "Hay Najah",
            id: 648,
            $version: 1
          },
          {
            name: "Amsernate",
            id: 659,
            $version: 1
          },
          {
            name: "Extension Dakhla",
            id: 666,
            $version: 1
          },
          {
            name: "Founti",
            id: 667,
            $version: 1
          },
          {
            name: "Lekhiam",
            id: 677,
            $version: 1
          },
          {
            name: "Haut-Founty",
            id: 626,
            $version: 1
          },
          {
            name: "Hay Mohammadi",
            id: 627,
            $version: 1
          },
          {
            name: "Lkhiam",
            id: 629,
            $version: 1
          },
          {
            name: "Le bord de mer",
            id: 630,
            $version: 1
          },
          {
            name: "Le centre-ville",
            id: 631,
            $version: 1
          },
          {
            name: "Les Amicales",
            id: 632,
            $version: 1
          },
          {
            name: "Najah",
            id: 633,
            $version: 1
          },
          {
            name: "Hay Qods",
            id: 634,
            $version: 1
          },
          {
            name: "Hay Salam",
            id: 635,
            $version: 1
          },
          {
            name: "Suisse",
            id: 636,
            $version: 1
          },
          {
            name: "Talborjt",
            id: 637,
            $version: 1
          },
          {
            name: "Arghoud",
            id: 639,
            $version: 1
          },
          {
            name: "Al Wifaq",
            id: 640,
            $version: 1
          },
          {
            name: "Anoual Souss",
            id: 641,
            $version: 1
          },
          {
            name: "Hay Hassani",
            id: 671,
            $version: 1
          },
          {
            name: "Hay Houda",
            id: 672,
            $version: 1
          },
          {
            name: "Hay Zaytoun",
            id: 673,
            $version: 1
          },
          {
            name: "Ihchach",
            id: 674,
            $version: 1
          },
          {
            name: "Illigh",
            id: 675,
            $version: 1
          },
          {
            name: "Laazib",
            id: 676,
            $version: 1
          },
          {
            name: "Port",
            id: 678,
            $version: 1
          },
          {
            name: "Secteur Touristique",
            id: 679,
            $version: 1
          },
          {
            name: "Siusse",
            id: 680,
            $version: 1
          },
          {
            name: "Taddart",
            id: 681,
            $version: 1
          },
          {
            name: "Taddart Anza",
            id: 682,
            $version: 2
          },
          {
            name: "Zone Industrielle Agadir",
            id: 683,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1799,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2115,
            $version: null
          },
          {
            name: "Autre",
            id: 2282,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Agadir",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Aghmat (Inactive)",
            id: 255,
            $version: null
          }
        ],
        displayName: "Aghmat (Inactive)",
        artmin: null,
        createdOn: "2021-03-09T10:05:01.037Z",
        inseeCode: null,
        id: 163,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Aghmat",
            id: 1285,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1579,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1914,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2083,
            $version: null
          },
          {
            name: "Autre",
            id: 2250,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Aghmat (Inactive)",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Aïn Attiq",
            id: 202,
            $version: null
          }
        ],
        displayName: "Aïn Attiq",
        artmin: null,
        createdOn: "2021-02-02T09:41:03.307Z",
        inseeCode: null,
        id: 145,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Aïn Attiq",
            id: 1266,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1563,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1898,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2067,
            $version: null
          },
          {
            name: "Autre",
            id: 2234,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Aïn Attiq",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AIN CHEGGAG FES",
            id: 68,
            $version: null
          },
          {
            name: "Ain Cheggag",
            id: 201,
            $version: null
          }
        ],
        displayName: "Ain Cheggag",
        artmin: null,
        createdOn: "2020-10-19T13:23:24.736Z",
        inseeCode: null,
        id: 92,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1536,
            $version: 1
          },
          {
            name: "Ain Cheggag",
            id: 1202,
            $version: 5
          },
          {
            name: "Aksal",
            id: 1873,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2051,
            $version: null
          },
          {
            name: "Autre",
            id: 2218,
            $version: null
          }
        ],
        updatedBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        shippingMethod: "LAD",
        active: true,
        updatedOn: "2020-10-19T13:23:44.533Z",
        deliveryAvailability: true,
        version: 1,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ain Cheggag",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Ain El Aouda",
            id: 203,
            $version: null
          }
        ],
        displayName: "Ain El Aouda",
        artmin: null,
        createdOn: "2021-02-02T09:41:25.572Z",
        inseeCode: null,
        id: 146,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Ain El Aouda",
            id: 1267,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1564,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1899,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2068,
            $version: null
          },
          {
            name: "Autre",
            id: 2235,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ain El Aouda",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AIN HARROUDA",
            id: 23,
            $version: null
          },
          {
            name: "Ain Harrouda",
            id: 138,
            $version: null
          }
        ],
        displayName: "Ain Harrouda",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 8,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1459,
            $version: 1
          },
          {
            name: "Ain Harrouda",
            id: 798,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1797,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2113,
            $version: null
          },
          {
            name: "Autre",
            id: 2280,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Ain Harrouda",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AIN ICHA",
            id: 75,
            $version: null
          },
          {
            name: "Ain icha",
            id: 214,
            $version: null
          }
        ],
        displayName: "Ain icha",
        artmin: null,
        createdOn: "2020-10-19T13:27:51Z",
        inseeCode: null,
        id: 100,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Marwa",
            id: 2041,
            $version: null
          },
          {
            name: "Ain icha",
            id: 1210,
            $version: 4
          },
          {
            name: "electroplanet",
            id: 1539,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1876,
            $version: 1
          },
          {
            name: "Autre",
            id: 2208,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ain icha",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AIN TAOUJDATE FES",
            id: 69,
            $version: null
          },
          {
            name: "Ain Taoujtate",
            id: 204,
            $version: null
          }
        ],
        displayName: "Ain Taoujtate",
        artmin: null,
        createdOn: "2020-10-19T13:10:46.687Z",
        inseeCode: null,
        id: 84,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Ain Taoujtate",
            id: 1183,
            $version: 4
          },
          {
            name: "electroplanet",
            id: 1528,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1865,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2043,
            $version: null
          },
          {
            name: "Autre",
            id: 2210,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ain Taoujtate",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AIN TIZGHI",
            id: 38,
            $version: null
          },
          {
            name: "Ain Tizghi",
            id: 163,
            $version: null
          }
        ],
        displayName: "Ain Tizghi",
        artmin: null,
        createdOn: "2020-09-29T16:17:53.309Z",
        inseeCode: null,
        id: 37,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1487,
            $version: 1
          },
          {
            name: "Ain Tizghi",
            id: 1008,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1825,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2001,
            $version: null
          },
          {
            name: "Autre",
            id: 2168,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ain Tizghi",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Ait Amira",
            id: 110,
            $version: null
          }
        ],
        displayName: "Ait Amira",
        artmin: null,
        createdOn: "2021-08-13T12:52:11.223Z",
        inseeCode: null,
        id: 198,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Ait Amira",
            id: 1951,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1952,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1972,
            $version: null
          },
          {
            name: "Autre",
            id: 2139,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Ait Amira",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Ait Atab (Inactive)",
            id: 181,
            $version: null
          }
        ],
        displayName: "Ait Atab (Inactive)",
        artmin: null,
        createdOn: "2020-09-29T16:32:48.937Z",
        inseeCode: null,
        id: 47,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1493,
            $version: 1
          },
          {
            name: "Ait Atab",
            id: 1044,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1831,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2006,
            $version: null
          },
          {
            name: "Autre",
            id: 2173,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ait Atab (Inactive)",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AIT MELLOUL",
            id: 21,
            $version: null
          },
          {
            name: "Ait Melloul",
            id: 134,
            $version: null
          }
        ],
        displayName: "Ait Melloul",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 13,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1464,
            $version: 1
          },
          {
            name: "Ait Melloul",
            id: 802,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1802,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2118,
            $version: null
          },
          {
            name: "Autre",
            id: 2285,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Ait Melloul",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AIT OURIR MARRAKECH",
            id: 86,
            $version: null
          },
          {
            name: "Aït Ourir",
            id: 240,
            $version: null
          }
        ],
        displayName: "Aït Ourir",
        artmin: null,
        createdOn: "2021-03-09T10:06:39.700Z",
        inseeCode: null,
        id: 165,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Aït Ourir",
            id: 1287,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1581,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1916,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2084,
            $version: null
          },
          {
            name: "Autre",
            id: 2251,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Aït Ourir",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Aksal",
            id: 144,
            $version: null
          }
        ],
        displayName: "Aksal",
        artmin: null,
        createdOn: "2021-05-24T11:56:46.624Z",
        inseeCode: null,
        id: 184,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Aksal",
            id: 1625,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2123,
            $version: null
          },
          {
            name: "Autre",
            id: 2290,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Aksal",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Al hoceima",
            id: 139,
            $version: null
          }
        ],
        displayName: "Al hoceima",
        artmin: null,
        createdOn: "2021-06-11T10:47:17.948Z",
        inseeCode: null,
        id: 186,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "AL HOCEIMA",
            id: 1949,
            $version: 0
          },
          {
            name: "Al hoceima",
            id: 1636,
            $version: 1
          },
          {
            name: "Barrio",
            id: 1633,
            $version: 1
          },
          {
            name: "Centre ville",
            id: 1635,
            $version: 1
          },
          {
            name: "Mirador haut",
            id: 1632,
            $version: 1
          },
          {
            name: "Sraghna",
            id: 1634,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1937,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2127,
            $version: null
          },
          {
            name: "Autre",
            id: 2294,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Al hoceima",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Al Ouidane (Inactive)",
            id: 221,
            $version: null
          }
        ],
        displayName: "Al Ouidane (Inactive)",
        artmin: null,
        createdOn: "2021-03-09T10:00:18.381Z",
        inseeCode: null,
        id: 157,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Al Ouidane",
            id: 1279,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1575,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1910,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2079,
            $version: null
          },
          {
            name: "Autre",
            id: 2246,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Al Ouidane (Inactive)",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Asilah",
            id: 164,
            $version: null
          }
        ],
        displayName: "Asilah",
        artmin: null,
        createdOn: "2020-10-19T11:17:51.816Z",
        inseeCode: null,
        id: 80,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Asilah",
            id: 1179,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1524,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1862,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2037,
            $version: null
          },
          {
            name: "Autre",
            id: 2204,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Asilah",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Autres",
            id: 143,
            $version: null
          }
        ],
        displayName: "Autres",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 9,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1460,
            $version: 1
          },
          {
            name: "Autres",
            id: 799,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1798,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2114,
            $version: null
          },
          {
            name: "Autre",
            id: 2281,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Autres",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Azilal (Inactive)",
            id: 251,
            $version: null
          }
        ],
        displayName: "Azilal (Inactive)",
        artmin: null,
        createdOn: "2021-03-09T10:13:06.870Z",
        inseeCode: null,
        id: 173,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Azilal",
            id: 1295,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1587,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1922,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2091,
            $version: null
          },
          {
            name: "Autre",
            id: 2258,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Azilal (Inactive)",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "AZROU FES",
            id: 70,
            $version: null
          },
          {
            name: "Azrou",
            id: 205,
            $version: null
          }
        ],
        displayName: "Azrou",
        artmin: null,
        createdOn: "2020-10-19T13:15:02.009Z",
        inseeCode: null,
        id: 88,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1532,
            $version: 1
          },
          {
            name: "Azrou",
            id: 1187,
            $version: 5
          },
          {
            name: "Aksal",
            id: 1869,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2047,
            $version: null
          },
          {
            name: "Autre",
            id: 2214,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Azrou",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BEDOUZA",
            id: 39,
            $version: null
          },
          {
            name: "Bedouza",
            id: 165,
            $version: null
          }
        ],
        displayName: "Bedouza",
        artmin: null,
        createdOn: "2020-09-30T17:25:09.398Z",
        inseeCode: null,
        id: 57,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1502,
            $version: 1
          },
          {
            name: "Bedouza",
            id: 1144,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1840,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2015,
            $version: null
          },
          {
            name: "Autre",
            id: 2182,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Bedouza",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BEJAAD",
            id: 87,
            $version: null
          },
          {
            name: "Bejaad",
            id: 241,
            $version: null
          }
        ],
        displayName: "Bejaad",
        artmin: null,
        createdOn: "2021-03-16T15:35:57.270Z",
        inseeCode: null,
        id: 182,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Bejaad",
            id: 1304,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1596,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1931,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2105,
            $version: null
          },
          {
            name: "Autre",
            id: 2272,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Bejaad",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BEN AHMED",
            id: 37,
            $version: null
          },
          {
            name: "Ben Ahmed",
            id: 162,
            $version: null
          }
        ],
        displayName: "Ben Ahmed",
        artmin: null,
        createdOn: "2020-09-30T17:36:47.370Z",
        inseeCode: null,
        id: 72,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1516,
            $version: 1
          },
          {
            name: "Ben Ahmed",
            id: 1171,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1854,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2029,
            $version: null
          },
          {
            name: "Autre",
            id: 2196,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ben Ahmed",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BENGUERIR",
            id: 80,
            $version: null
          },
          {
            name: "Ben Guerir",
            id: 227,
            $version: null
          }
        ],
        displayName: "Ben Guerir",
        artmin: null,
        createdOn: "2021-03-09T10:07:17.334Z",
        inseeCode: null,
        id: 166,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1582,
            $version: 1
          },
          {
            name: "Ben Guerir",
            id: 1288,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1917,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2085,
            $version: null
          },
          {
            name: "Autre",
            id: 2252,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Ben Guerir",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BENI MELLAL",
            id: 35,
            $version: null
          },
          {
            name: "Beni Mellal",
            id: 160,
            $version: null
          }
        ],
        displayName: "Beni Mellal",
        artmin: null,
        createdOn: "2020-09-29T16:20:05.243Z",
        inseeCode: null,
        id: 39,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1489,
            $version: 1
          },
          {
            name: "Ayat",
            id: 1012,
            $version: 1
          },
          {
            name: "Bab Ftouh",
            id: 1017,
            $version: 1
          },
          {
            name: "Bouachouch",
            id: 1013,
            $version: 1
          },
          {
            name: "Hay Adarissa",
            id: 1019,
            $version: 1
          },
          {
            name: "Hay Al Houda",
            id: 1018,
            $version: 1
          },
          {
            name: "Hay amal",
            id: 1014,
            $version: 1
          },
          {
            name: "Hay El Atlas",
            id: 1010,
            $version: 2
          },
          {
            name: "Jnan Taher",
            id: 1016,
            $version: 1
          },
          {
            name: "Qasba",
            id: 1011,
            $version: 1
          },
          {
            name: "Riad salam",
            id: 1015,
            $version: 1
          },
          {
            name: "Takkadom",
            id: 1020,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1827,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2003,
            $version: null
          },
          {
            name: "Autre",
            id: 2170,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Beni Mellal",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BENI YAKHLEF",
            id: 11,
            $version: null
          },
          {
            name: "Beni yakhlef",
            id: 111,
            $version: null
          }
        ],
        displayName: "Beni yakhlef",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 30,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Beni yakhlef",
            id: 976,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1480,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1818,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1995,
            $version: null
          },
          {
            name: "Autre",
            id: 2162,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Beni yakhlef",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BENSLIMANE",
            id: 30,
            $version: null
          },
          {
            name: "Benslimane",
            id: 155,
            $version: null
          }
        ],
        displayName: "Benslimane",
        artmin: null,
        createdOn: "2020-09-29T16:15:19.355Z",
        inseeCode: null,
        id: 34,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1484,
            $version: 1
          },
          {
            name: "Benslimane",
            id: 1612,
            $version: 2
          },
          {
            name: "Administratif",
            id: 993,
            $version: 2
          },
          {
            name: "Al Filline",
            id: 994,
            $version: 2
          },
          {
            name: "Hay al Hassani",
            id: 998,
            $version: 2
          },
          {
            name: "Hay Al Mohammadi",
            id: 999,
            $version: 2
          },
          {
            name: "Hay Farah",
            id: 996,
            $version: 2
          },
          {
            name: "Hay Nejma",
            id: 997,
            $version: 2
          },
          {
            name: "Industriel",
            id: 1000,
            $version: 2
          },
          {
            name: "Jradi",
            id: 1001,
            $version: 2
          },
          {
            name: "Karima",
            id: 1002,
            $version: 2
          },
          {
            name: "Lallamaryem",
            id: 1003,
            $version: 2
          },
          {
            name: "Les chenes",
            id: 1004,
            $version: 2
          },
          {
            name: "Qods",
            id: 995,
            $version: 2
          },
          {
            name: "Quartier Soufal",
            id: 1005,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1822,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1999,
            $version: null
          },
          {
            name: "Autre",
            id: 2166,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Benslimane",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BRAKT RADI",
            id: 2,
            $version: null
          },
          {
            name: "Berakat Radi",
            id: 94,
            $version: null
          }
        ],
        displayName: "Berakat Radi",
        artmin: null,
        createdOn: "2020-09-30T17:21:42.430Z",
        inseeCode: null,
        id: 53,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1498,
            $version: 1
          },
          {
            name: "Berakat Radi",
            id: 1140,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1836,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2011,
            $version: null
          },
          {
            name: "Autre",
            id: 2178,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Berakat Radi",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BERCHID",
            id: 36,
            $version: null
          },
          {
            name: "Berrechid",
            id: 161,
            $version: null
          }
        ],
        displayName: "Berrechid",
        artmin: null,
        createdOn: "2020-09-30T17:31:20.702Z",
        inseeCode: null,
        id: 68,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1513,
            $version: 1
          },
          {
            name: "Berrechid",
            id: 1155,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1851,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2026,
            $version: null
          },
          {
            name: "Autre",
            id: 2193,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Berrechid",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BHALIL FES",
            id: 71,
            $version: null
          },
          {
            name: "Bhalil",
            id: 206,
            $version: null
          }
        ],
        displayName: "Bhalil",
        artmin: null,
        createdOn: "2020-12-08T14:26:03.102Z",
        inseeCode: null,
        id: 106,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Bhalil",
            id: 1216,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1550,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1885,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2056,
            $version: null
          },
          {
            name: "Autre",
            id: 2223,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Bhalil",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BIRKOUAT",
            id: 40,
            $version: null
          },
          {
            name: "Birkouat",
            id: 166,
            $version: null
          }
        ],
        displayName: "Birkouat",
        artmin: null,
        createdOn: "2020-09-30T17:29:27.357Z",
        inseeCode: null,
        id: 64,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1509,
            $version: 1
          },
          {
            name: "Birkouat",
            id: 1151,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1847,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2022,
            $version: null
          },
          {
            name: "Autre",
            id: 2189,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Birkouat",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BOUFEKRANE",
            id: 61,
            $version: null
          },
          {
            name: "Boufekrane",
            id: 190,
            $version: null
          }
        ],
        displayName: "Boufekrane",
        artmin: null,
        createdOn: "2020-10-19T13:11:16.695Z",
        inseeCode: null,
        id: 86,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1530,
            $version: 1
          },
          {
            name: "Boufekrane",
            id: 1185,
            $version: 3
          },
          {
            name: "Aksal",
            id: 1867,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2045,
            $version: null
          },
          {
            name: "Autre",
            id: 2212,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Boufekrane",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "boujniba",
            id: 145,
            $version: null
          }
        ],
        displayName: "boujniba",
        artmin: null,
        createdOn: "2021-06-12T11:12:05.899Z",
        inseeCode: null,
        id: 187,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "boujniba",
            id: 1637,
            $version: 0
          },
          {
            name: "Aksal",
            id: 1938,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2128,
            $version: null
          },
          {
            name: "Autre",
            id: 2295,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "25",
          fullName: "Ayoub CHABA",
          id: 298,
          $version: 15
        },
        hasZipOnRight: false,
        name: "boujniba",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Boujniba",
            id: 96,
            $version: null
          }
        ],
        displayName: "Boujniba",
        artmin: null,
        createdOn: "2021-07-19T10:32:09.393Z",
        inseeCode: null,
        id: 194,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Boujniba",
            id: 1944,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1967,
            $version: null
          },
          {
            name: "Autre",
            id: 2134,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Boujniba",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Boulemane",
            id: 191,
            $version: null
          }
        ],
        displayName: "Boulemane",
        artmin: null,
        createdOn: "2020-12-08T14:26:17.712Z",
        inseeCode: null,
        id: 107,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Boulemane",
            id: 1217,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1551,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1886,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2057,
            $version: null
          },
          {
            name: "Autre",
            id: 2224,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Boulemane",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "boulnouar",
            id: 130,
            $version: null
          }
        ],
        displayName: "boulnouar",
        artmin: null,
        createdOn: "2021-06-12T11:12:42.297Z",
        inseeCode: null,
        id: 188,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "boulnouar",
            id: 1638,
            $version: 0
          },
          {
            name: "Aksal",
            id: 1939,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2129,
            $version: null
          },
          {
            name: "Autre",
            id: 2296,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "25",
          fullName: "Ayoub CHABA",
          id: 298,
          $version: 15
        },
        hasZipOnRight: false,
        name: "boulnouar",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BOUSKOURA",
            id: 12,
            $version: null
          },
          {
            name: "Bouskoura",
            id: 112,
            $version: null
          }
        ],
        displayName: "Bouskoura",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 17,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1468,
            $version: 1
          },
          {
            name: "Bouskoura",
            id: 806,
            $version: 3
          },
          {
            name: "Aksal",
            id: 1806,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1983,
            $version: null
          },
          {
            name: "Autre",
            id: 2150,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Bouskoura",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BOUZNIKA",
            id: 7,
            $version: null
          },
          {
            name: "Bouznika",
            id: 102,
            $version: null
          }
        ],
        displayName: "Bouznika",
        artmin: null,
        createdOn: "2020-09-29T16:11:05.190Z",
        inseeCode: null,
        id: 33,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1483,
            $version: 1
          },
          {
            name: "Hamza",
            id: 991,
            $version: 2
          },
          {
            name: "Bouznika",
            id: 1611,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1821,
            $version: 1
          },
          {
            name: "Al Kawtar",
            id: 990,
            $version: 2
          },
          {
            name: "Plage Oued Cherrat",
            id: 992,
            $version: 2
          },
          {
            name: "Quartier Industriel",
            id: 989,
            $version: 2
          },
          {
            name: "Marwa",
            id: 1998,
            $version: null
          },
          {
            name: "Autre",
            id: 2165,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Bouznika",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BureauAM",
            id: 113,
            $version: null
          }
        ],
        displayName: "BureauAM",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 21,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1472,
            $version: 1
          },
          {
            name: "BureauAM",
            id: 808,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1810,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1987,
            $version: null
          },
          {
            name: "Autre",
            id: 2154,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "BureauAM",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "BureauAMF",
            id: 114,
            $version: null
          }
        ],
        displayName: "BureauAMF",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 24,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1475,
            $version: 1
          },
          {
            name: "BureauAMF",
            id: 810,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1813,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1990,
            $version: null
          },
          {
            name: "Autre",
            id: 2157,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "BureauAMF",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "CASABLANCA",
            id: 13,
            $version: null
          },
          {
            name: "Casablanca",
            id: 115,
            $version: null
          }
        ],
        displayName: "Casablanca",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 1,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Les Camps",
            id: 179,
            $version: 0
          },
          {
            name: "Longchamps",
            id: 183,
            $version: 0
          },
          {
            name: "Hay Almassira 3",
            id: 221,
            $version: 0
          },
          {
            name: "Hay Moulay Rachid 5",
            id: 226,
            $version: 0
          },
          {
            name: "Sour Jdid",
            id: 203,
            $version: 0
          },
          {
            name: "Bernoussi",
            id: 209,
            $version: 0
          },
          {
            name: "Val Fleuri",
            id: 207,
            $version: 1
          },
          {
            name: "Florida",
            id: 164,
            $version: 0
          },
          {
            name: "Hay Inara",
            id: 170,
            $version: 0
          },
          {
            name: "Hermitage",
            id: 172,
            $version: 0
          },
          {
            name: "Ben M'sick",
            id: 142,
            $version: 0
          },
          {
            name: "Al Fida",
            id: 144,
            $version: 0
          },
          {
            name: "Hay Moulay Rachid",
            id: 146,
            $version: 0
          },
          {
            name: "Aïn Borja",
            id: 148,
            $version: 0
          },
          {
            name: "Benmsik",
            id: 155,
            $version: 0
          },
          {
            name: "Habous",
            id: 167,
            $version: 0
          },
          {
            name: "La Gironde",
            id: 177,
            $version: 1
          },
          {
            name: "Aïn Diab",
            id: 149,
            $version: 1
          },
          {
            name: "Californie",
            id: 157,
            $version: 1
          },
          {
            name: "C.I.L",
            id: 159,
            $version: 1
          },
          {
            name: "Beauséjour",
            id: 152,
            $version: 1
          },
          {
            name: "El Manar El Hank",
            id: 162,
            $version: 0
          },
          {
            name: "Hippodrome",
            id: 173,
            $version: 0
          },
          {
            name: "Aïn Sebaâ",
            id: 140,
            $version: 0
          },
          {
            name: "Sidi Bernoussi",
            id: 143,
            $version: 0
          },
          {
            name: "Industriel Ain Sebaa",
            id: 175,
            $version: 1
          },
          {
            name: "La Gare",
            id: 176,
            $version: 0
          },
          {
            name: "Hay Hassani",
            id: 145,
            $version: 0
          },
          {
            name: "Ferme Bretone",
            id: 163,
            $version: 0
          },
          {
            name: "Hay Hana",
            id: 169,
            $version: 0
          },
          {
            name: "Sidi Moumen",
            id: 201,
            $version: 0
          },
          {
            name: "Al Qods",
            id: 208,
            $version: 0
          },
          {
            name: "Hay Al Amal",
            id: 210,
            $version: 0
          },
          {
            name: "Hay Mansour",
            id: 212,
            $version: 0
          },
          {
            name: "Hay Tarik",
            id: 213,
            $version: 0
          },
          {
            name: "Laimoune",
            id: 178,
            $version: 0
          },
          {
            name: "Route d'Azemmour",
            id: 196,
            $version: 0
          },
          {
            name: "Route d'El Jadida",
            id: 197,
            $version: 0
          },
          {
            name: "Zone Industrielle Lissasfa",
            id: 214,
            $version: 0
          },
          {
            name: "Alsace Lorraine",
            id: 151,
            $version: 2
          },
          {
            name: "Hay Laymouna",
            id: 171,
            $version: 2
          },
          {
            name: "Bourgogne",
            id: 156,
            $version: 1
          },
          {
            name: "Al Batha",
            id: 150,
            $version: 2
          },
          {
            name: "Oasis",
            id: 187,
            $version: 0
          },
          {
            name: "Anfa",
            id: 141,
            $version: 1
          },
          {
            name: "Fonciere",
            id: 165,
            $version: 1
          },
          {
            name: "Gauthier",
            id: 166,
            $version: 1
          },
          {
            name: "Hôrloge",
            id: 174,
            $version: 1
          },
          {
            name: "Les Cretes",
            id: 180,
            $version: 1
          },
          {
            name: "Lusitania",
            id: 184,
            $version: 1
          },
          {
            name: "Mers Sultan",
            id: 186,
            $version: 1
          },
          {
            name: "Palmier",
            id: 189,
            $version: 1
          },
          {
            name: "Polo",
            id: 191,
            $version: 0
          },
          {
            name: "Tantonville",
            id: 204,
            $version: 0
          },
          {
            name: "Sbata",
            id: 198,
            $version: 0
          },
          {
            name: "Hay M'barka",
            id: 211,
            $version: 0
          },
          {
            name: "Hay Albaraka",
            id: 217,
            $version: 0
          },
          {
            name: "Hay Alfalah",
            id: 218,
            $version: 0
          },
          {
            name: "Hay Almassira 2",
            id: 220,
            $version: 0
          },
          {
            name: "Hay Moulay Rachid 1",
            id: 222,
            $version: 0
          },
          {
            name: "Hay Moulay Rachid 2",
            id: 223,
            $version: 0
          },
          {
            name: "Hay Moulay Rachid 3",
            id: 224,
            $version: 0
          },
          {
            name: "Hay Moulay Rachid 4",
            id: 225,
            $version: 0
          },
          {
            name: "Hay Moulay Rachid 6",
            id: 227,
            $version: 0
          },
          {
            name: "Hay Rajaa",
            id: 228,
            $version: 0
          },
          {
            name: "Hay Sadri",
            id: 229,
            $version: 0
          },
          {
            name: "Zone Industrielle Moulay Rachid",
            id: 230,
            $version: 0
          },
          {
            name: "Les Princesses",
            id: 181,
            $version: 0
          },
          {
            name: "Maarif",
            id: 185,
            $version: 0
          },
          {
            name: "Parc",
            id: 190,
            $version: 0
          },
          {
            name: "Racine",
            id: 193,
            $version: 0
          },
          {
            name: "Sidi Belyout",
            id: 199,
            $version: 0
          },
          {
            name: "Bournazil",
            id: 216,
            $version: 1
          },
          {
            name: "Derb Omar",
            id: 161,
            $version: 2
          },
          {
            name: "Centre Ville",
            id: 158,
            $version: 2
          },
          {
            name: "Derb Ghallef",
            id: 160,
            $version: 2
          },
          {
            name: "Sidi Maarouf",
            id: 200,
            $version: 3
          },
          {
            name: "Sidi Othmane",
            id: 202,
            $version: 1
          },
          {
            name: "Oulfa",
            id: 188,
            $version: 1
          },
          {
            name: "Quartier des Hôpitaux",
            id: 192,
            $version: 1
          },
          {
            name: "Riviera",
            id: 194,
            $version: 1
          },
          {
            name: "Triangle d'Or",
            id: 205,
            $version: 1
          },
          {
            name: "Val d'Anfa",
            id: 206,
            $version: 1
          },
          {
            name: "Roches Noires",
            id: 195,
            $version: 3
          },
          {
            name: "2 Mars",
            id: 147,
            $version: 6
          },
          {
            name: "Ben Ejdia",
            id: 154,
            $version: 2
          },
          {
            name: "electroplanet",
            id: 1452,
            $version: 1
          },
          {
            name: "casablanca",
            id: 1598,
            $version: 2
          },
          {
            name: "Hay Almassira 1",
            id: 219,
            $version: 1
          },
          {
            name: "Derb Sultan",
            id: 1626,
            $version: 0
          },
          {
            name: "Hay Mohammadi",
            id: 168,
            $version: 1
          },
          {
            name: "Lissasfa",
            id: 182,
            $version: 1
          },
          {
            name: "Aïn Chock",
            id: 139,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1790,
            $version: 1
          },
          {
            name: "Belvédère",
            id: 153,
            $version: 2
          },
          {
            name: "Marwa",
            id: 1981,
            $version: null
          },
          {
            name: "Autre",
            id: 2148,
            $version: null
          }
        ],
        updatedBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        shippingMethod: "LAD",
        active: true,
        updatedOn: "2022-01-27T15:54:39.797Z",
        deliveryAvailability: true,
        version: 1,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Casablanca",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "CHMAIA",
            id: 41,
            $version: null
          },
          {
            name: "Chemaia",
            id: 167,
            $version: null
          }
        ],
        displayName: "Chemaia",
        artmin: null,
        createdOn: "2020-09-30T17:28:07.726Z",
        inseeCode: null,
        id: 61,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1506,
            $version: 1
          },
          {
            name: "Chemaia",
            id: 1148,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1844,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2019,
            $version: null
          },
          {
            name: "Autre",
            id: 2186,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Chemaia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "CHICHAOUA",
            id: 85,
            $version: null
          },
          {
            name: "Chichaoua",
            id: 235,
            $version: null
          }
        ],
        displayName: "Chichaoua",
        artmin: null,
        createdOn: "2021-03-09T10:09:53.026Z",
        inseeCode: null,
        id: 169,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Chichaoua",
            id: 1291,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1584,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1919,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2087,
            $version: null
          },
          {
            name: "Autre",
            id: 2254,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Chichaoua",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Chrifia",
            id: 236,
            $version: null
          }
        ],
        displayName: "Chrifia",
        artmin: null,
        createdOn: "2021-03-09T10:03:09.436Z",
        inseeCode: null,
        id: 160,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Chrifia",
            id: 1282,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1577,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1912,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2081,
            $version: null
          },
          {
            name: "Autre",
            id: 2248,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Chrifia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Chwiter",
            id: 192,
            $version: null
          }
        ],
        displayName: "Chwiter",
        artmin: null,
        createdOn: "2021-03-09T10:02:31.886Z",
        inseeCode: null,
        id: 159,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Chwiter",
            id: 1281,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1576,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1911,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2080,
            $version: null
          },
          {
            name: "Autre",
            id: 2247,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Chwiter",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "DAKHLA",
            id: 88,
            $version: null
          },
          {
            name: "Dakhla",
            id: 242,
            $version: null
          }
        ],
        displayName: "Dakhla",
        artmin: null,
        createdOn: "2021-03-16T15:18:27.075Z",
        inseeCode: null,
        id: 177,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Centre ville",
            id: 1299,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1591,
            $version: 1
          },
          {
            name: "Dakhla",
            id: 1621,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1926,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2100,
            $version: null
          },
          {
            name: "Autre",
            id: 2267,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Dakhla",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "DAR BOUAZZA",
            id: 19,
            $version: null
          },
          {
            name: "DarBouazza",
            id: 131,
            $version: null
          }
        ],
        displayName: "DarBouazza",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 15,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1466,
            $version: 1
          },
          {
            name: "DarBouazza",
            id: 804,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1804,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2120,
            $version: null
          },
          {
            name: "Autre",
            id: 2287,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "DarBouazza",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "DCHEIRA AGADIR",
            id: 22,
            $version: null
          },
          {
            name: "Dchira",
            id: 135,
            $version: null
          }
        ],
        displayName: "Dchira",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 14,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1465,
            $version: 1
          },
          {
            name: "Dchira",
            id: 803,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1803,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2119,
            $version: null
          },
          {
            name: "Autre",
            id: 2286,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Dchira",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Demnate",
            id: 249,
            $version: null
          }
        ],
        displayName: "Demnate",
        artmin: null,
        createdOn: "2021-03-09T10:13:39.740Z",
        inseeCode: null,
        id: 174,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Demnate",
            id: 1296,
            $version: 2
          },
          {
            name: "electroplanet",
            id: 1588,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1923,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2092,
            $version: null
          },
          {
            name: "Autre",
            id: 2259,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Demnate",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Deroua",
            id: 97,
            $version: null
          }
        ],
        displayName: "Deroua",
        artmin: null,
        createdOn: "2021-07-19T10:33:37.021Z",
        inseeCode: null,
        id: 196,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Deroua",
            id: 1946,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1969,
            $version: null
          },
          {
            name: "Autre",
            id: 2136,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Deroua",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Douar Al Hana (Inactive)",
            id: 222,
            $version: null
          }
        ],
        displayName: "Douar Al Hana (Inactive)",
        artmin: null,
        createdOn: "2021-03-09T09:46:00.517Z",
        inseeCode: null,
        id: 150,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Douar Al Hana",
            id: 1271,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1568,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1903,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2072,
            $version: null
          },
          {
            name: "Autre",
            id: 2239,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Douar Al Hana (Inactive)",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "El Attaouia",
            id: 237,
            $version: null
          }
        ],
        displayName: "El Attaouia",
        artmin: null,
        createdOn: "2021-03-09T10:15:15.613Z",
        inseeCode: null,
        id: 176,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "El Attaouia",
            id: 1298,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1590,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1925,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2098,
            $version: null
          },
          {
            name: "Autre",
            id: 2265,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "El Attaouia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "LHAJEB MEKNES",
            id: 64,
            $version: null
          },
          {
            name: "El Hajeb",
            id: 195,
            $version: null
          }
        ],
        displayName: "El Hajeb",
        artmin: null,
        createdOn: "2020-10-19T13:14:36.350Z",
        inseeCode: null,
        id: 87,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1531,
            $version: 1
          },
          {
            name: "El Hajeb",
            id: 1186,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1868,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2046,
            $version: null
          },
          {
            name: "Autre",
            id: 2213,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "El Hajeb",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "HAJ KADDOUR",
            id: 63,
            $version: null
          },
          {
            name: "El Haj Kaddour",
            id: 194,
            $version: null
          }
        ],
        displayName: "El Haj Kaddour",
        artmin: null,
        createdOn: "2020-10-19T13:24:10.772Z",
        inseeCode: null,
        id: 93,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1537,
            $version: 1
          },
          {
            name: "El Haj Kaddour",
            id: 1203,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1874,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2052,
            $version: null
          },
          {
            name: "Autre",
            id: 2219,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "El Haj Kaddour",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "El Harhoura",
            id: 132,
            $version: null
          }
        ],
        displayName: "El Harhoura",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 6,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1457,
            $version: 1
          },
          {
            name: "El Harhoura",
            id: 796,
            $version: 3
          },
          {
            name: "Aksal",
            id: 1795,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2111,
            $version: null
          },
          {
            name: "Autre",
            id: 2278,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "El Harhoura",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "ELJADIDA",
            id: 10,
            $version: null
          },
          {
            name: "Eljadida",
            id: 109,
            $version: null
          }
        ],
        displayName: "Eljadida",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 23,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1474,
            $version: 1
          },
          {
            name: "Centre",
            id: 902,
            $version: 0
          },
          {
            name: "Alaouiyine",
            id: 903,
            $version: 0
          },
          {
            name: "Amal",
            id: 904,
            $version: 0
          },
          {
            name: "Najd",
            id: 905,
            $version: 0
          },
          {
            name: "Saada",
            id: 906,
            $version: 0
          },
          {
            name: "Salama",
            id: 907,
            $version: 0
          },
          {
            name: "Al Boustane",
            id: 908,
            $version: 0
          },
          {
            name: "Al Kalaa",
            id: 909,
            $version: 0
          },
          {
            name: "Al Kodia",
            id: 910,
            $version: 0
          },
          {
            name: "Al Qods",
            id: 911,
            $version: 0
          },
          {
            name: "Biranzarane",
            id: 912,
            $version: 0
          },
          {
            name: "Cheminoits",
            id: 913,
            $version: 0
          },
          {
            name: "Cité Des Cadres - Saniat Al Mourakib",
            id: 914,
            $version: 0
          },
          {
            name: "Cité Portugaise",
            id: 915,
            $version: 0
          },
          {
            name: "Corniche",
            id: 916,
            $version: 0
          },
          {
            name: "Dar Assalam",
            id: 917,
            $version: 0
          },
          {
            name: "Derb Al Hajjar",
            id: 918,
            $version: 0
          },
          {
            name: "Derb Ghalef",
            id: 919,
            $version: 0
          },
          {
            name: "Derb Lahona",
            id: 920,
            $version: 0
          },
          {
            name: "Derb Sid Daoui",
            id: 921,
            $version: 0
          },
          {
            name: "Ezzohor",
            id: 922,
            $version: 0
          },
          {
            name: "Hay Al Amal",
            id: 923,
            $version: 0
          },
          {
            name: "Hay Essalam",
            id: 924,
            $version: 0
          },
          {
            name: "Hay Nassim",
            id: 925,
            $version: 0
          },
          {
            name: "Hay Riad",
            id: 926,
            $version: 0
          },
          {
            name: "Jawhara",
            id: 927,
            $version: 0
          },
          {
            name: "Lalla Zahra",
            id: 928,
            $version: 0
          },
          {
            name: "Lot Rida",
            id: 929,
            $version: 0
          },
          {
            name: "Mouilha",
            id: 930,
            $version: 0
          },
          {
            name: "Najmat Al Janoub",
            id: 931,
            $version: 0
          },
          {
            name: "Narjiss",
            id: 932,
            $version: 0
          },
          {
            name: "Plateau",
            id: 933,
            $version: 0
          },
          {
            name: "Port",
            id: 934,
            $version: 0
          },
          {
            name: "Quartier Aérodrome",
            id: 935,
            $version: 0
          },
          {
            name: "Riad Essalam",
            id: 936,
            $version: 0
          },
          {
            name: "Sidi Moussa",
            id: 937,
            $version: 0
          },
          {
            name: "Eljadida",
            id: 1607,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1812,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1989,
            $version: null
          },
          {
            name: "Autre",
            id: 2156,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Eljadida",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "MANSOURIA",
            id: 62,
            $version: null
          },
          {
            name: "EL MANSOURIA",
            id: 193,
            $version: null
          }
        ],
        displayName: "EL MANSOURIA",
        artmin: null,
        createdOn: "2021-03-03T09:30:32.416Z",
        inseeCode: null,
        id: 149,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "CENTRE EL MANSOURIA",
            id: 1270,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1567,
            $version: 1
          },
          {
            name: "EL MANSOURIA",
            id: 1620,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1902,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2071,
            $version: null
          },
          {
            name: "Autre",
            id: 2238,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "meryem",
          fullName: "Meryem Taibi",
          id: 103,
          $version: 26
        },
        hasZipOnRight: false,
        name: "EL MANSOURIA",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "El menzeh",
            id: 196,
            $version: null
          }
        ],
        displayName: "El menzeh",
        artmin: null,
        createdOn: "2021-02-02T09:41:45.524Z",
        inseeCode: null,
        id: 147,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "El menzeh",
            id: 1268,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1565,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1900,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2069,
            $version: null
          },
          {
            name: "Autre",
            id: 2236,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "El menzeh",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "ESSAOUIRA",
            id: 32,
            $version: null
          },
          {
            name: "Essaouira",
            id: 157,
            $version: null
          }
        ],
        displayName: "Essaouira",
        artmin: null,
        createdOn: "2020-09-29T18:05:47.324Z",
        inseeCode: null,
        id: 51,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1497,
            $version: 1
          },
          {
            name: "Argana",
            id: 1107,
            $version: 1
          },
          {
            name: "Arrière Pays",
            id: 1108,
            $version: 1
          },
          {
            name: "Bab Al Bahar",
            id: 1109,
            $version: 1
          },
          {
            name: "Bab Doukkala",
            id: 1110,
            $version: 1
          },
          {
            name: "Bab Enzh",
            id: 1111,
            $version: 1
          },
          {
            name: "Bab Sbaa",
            id: 1112,
            $version: 1
          },
          {
            name: "Bani Antar",
            id: 1113,
            $version: 1
          },
          {
            name: "Bhibah",
            id: 1114,
            $version: 1
          },
          {
            name: "Borj",
            id: 1115,
            $version: 1
          },
          {
            name: "Bouakhir",
            id: 1116,
            $version: 1
          },
          {
            name: "Centre ville",
            id: 1117,
            $version: 1
          },
          {
            name: "Douar Lareïch",
            id: 1120,
            $version: 1
          },
          {
            name: "Essaouira El Jadida",
            id: 1121,
            $version: 1
          },
          {
            name: "Chbanat",
            id: 1118,
            $version: 1
          },
          {
            name: "Douar Laarab",
            id: 1119,
            $version: 1
          },
          {
            name: "Ghazoua",
            id: 1122,
            $version: 1
          },
          {
            name: "Idaougard",
            id: 1123,
            $version: 1
          },
          {
            name: "Kasbah",
            id: 1124,
            $version: 1
          },
          {
            name: "Lahrarta",
            id: 1125,
            $version: 1
          },
          {
            name: "Le boulevard Mohammed V",
            id: 1127,
            $version: 1
          },
          {
            name: "Le Mellah",
            id: 1126,
            $version: 1
          },
          {
            name: "Medina",
            id: 1128,
            $version: 1
          },
          {
            name: "Nouvelle Ville",
            id: 1129,
            $version: 1
          },
          {
            name: "Place Orson Welles",
            id: 1130,
            $version: 1
          },
          {
            name: "Raounak",
            id: 1131,
            $version: 1
          },
          {
            name: "Route d'Agadir",
            id: 1132,
            $version: 1
          },
          {
            name: "Route de Marrakech",
            id: 1133,
            $version: 1
          },
          {
            name: "Route de Safi",
            id: 1134,
            $version: 1
          },
          {
            name: "Sidi Kaouki",
            id: 1135,
            $version: 1
          },
          {
            name: "Skala",
            id: 1136,
            $version: 1
          },
          {
            name: "Tafoukt",
            id: 1137,
            $version: 1
          },
          {
            name: "Tidzi",
            id: 1139,
            $version: 1
          },
          {
            name: "Tamanar",
            id: 1138,
            $version: 2
          },
          {
            name: "Essaouira",
            id: 1616,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1835,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2010,
            $version: null
          },
          {
            name: "Autre",
            id: 2177,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Essaouira",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "FES",
            id: 14,
            $version: null
          },
          {
            name: "Fes",
            id: 116,
            $version: null
          }
        ],
        displayName: "Fes",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 19,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Zouagha",
            id: 732,
            $version: 0
          },
          {
            name: "Dar Mehrez",
            id: 737,
            $version: 0
          },
          {
            name: "Narjis",
            id: 752,
            $version: 0
          },
          {
            name: "Centre",
            id: 762,
            $version: 0
          },
          {
            name: "Aaouinat Hajjaj",
            id: 790,
            $version: 0
          },
          {
            name: "Ain Amiyer",
            id: 791,
            $version: 0
          },
          {
            name: "Hay Mohammedi",
            id: 783,
            $version: 2
          },
          {
            name: "Jnan El ward",
            id: 729,
            $version: 2
          },
          {
            name: "Mellah",
            id: 740,
            $version: 2
          },
          {
            name: "Sehb El Ward",
            id: 773,
            $version: 2
          },
          {
            name: "Zekak Erroumane",
            id: 759,
            $version: 2
          },
          {
            name: "electroplanet",
            id: 1470,
            $version: 1
          },
          {
            name: "Saies",
            id: 731,
            $version: 0
          },
          {
            name: "Ben Souda",
            id: 735,
            $version: 0
          },
          {
            name: "Hay Saada",
            id: 738,
            $version: 0
          },
          {
            name: "Lyrac",
            id: 739,
            $version: 0
          },
          {
            name: "Mont fleuri",
            id: 741,
            $version: 0
          },
          {
            name: "Moulay Abdallah",
            id: 742,
            $version: 0
          },
          {
            name: "Route d'Immouzere",
            id: 746,
            $version: 0
          },
          {
            name: "Mont Fleuri 2",
            id: 747,
            $version: 0
          },
          {
            name: "Route d'Aeroport",
            id: 748,
            $version: 0
          },
          {
            name: "Route de Sefrou",
            id: 750,
            $version: 0
          },
          {
            name: "Route Ain Chkaf",
            id: 751,
            $version: 0
          },
          {
            name: "Agdal",
            id: 727,
            $version: 1
          },
          {
            name: "Ain Haroun",
            id: 733,
            $version: 2
          },
          {
            name: "Fès Médina",
            id: 728,
            $version: 2
          },
          {
            name: "Les Mérinides",
            id: 730,
            $version: 2
          },
          {
            name: "Ancienne Médina",
            id: 734,
            $version: 2
          },
          {
            name: "Boutaaa",
            id: 753,
            $version: 2
          },
          {
            name: "Dar Dbibagh",
            id: 736,
            $version: 2
          },
          {
            name: "El Keddan",
            id: 754,
            $version: 2
          },
          {
            name: "El Mokhfia",
            id: 755,
            $version: 2
          },
          {
            name: "Hay Atlas",
            id: 765,
            $version: 0
          },
          {
            name: "Hay Fadela",
            id: 766,
            $version: 0
          },
          {
            name: "Hay Palestine",
            id: 767,
            $version: 0
          },
          {
            name: "Sidi Brahim",
            id: 769,
            $version: 0
          },
          {
            name: "Jnane Eddar",
            id: 771,
            $version: 0
          },
          {
            name: "Oued Bou Fekrane",
            id: 772,
            $version: 0
          },
          {
            name: "Hay Moulay Abdellah",
            id: 788,
            $version: 0
          },
          {
            name: "Mechouar",
            id: 789,
            $version: 0
          },
          {
            name: "Hay Riad",
            id: 793,
            $version: 0
          },
          {
            name: "Mourabitine",
            id: 794,
            $version: 0
          },
          {
            name: "Zone Industrielle Sidi Brahim",
            id: 795,
            $version: 0
          },
          {
            name: "Laayoun",
            id: 756,
            $version: 2
          },
          {
            name: "Oued Fès",
            id: 743,
            $version: 2
          },
          {
            name: "Place Alaouine",
            id: 744,
            $version: 2
          },
          {
            name: "Talaa",
            id: 745,
            $version: 2
          },
          {
            name: "Champs de course",
            id: 979,
            $version: 0
          },
          {
            name: "Ain kadous",
            id: 775,
            $version: 2
          },
          {
            name: "Ain Noqbi",
            id: 770,
            $version: 2
          },
          {
            name: "Al Hadiqa",
            id: 776,
            $version: 2
          },
          {
            name: "Bab Boujloud",
            id: 760,
            $version: 2
          },
          {
            name: "Batha",
            id: 761,
            $version: 2
          },
          {
            name: "Belkhiat",
            id: 777,
            $version: 2
          },
          {
            name: "Ben Debbab",
            id: 778,
            $version: 2
          },
          {
            name: "Ben Zakour",
            id: 779,
            $version: 2
          },
          {
            name: "Dher Lkhmiss",
            id: 780,
            $version: 2
          },
          {
            name: "Douh",
            id: 763,
            $version: 2
          },
          {
            name: "Hay Agadir",
            id: 781,
            $version: 2
          },
          {
            name: "Hay Hassani",
            id: 782,
            $version: 2
          },
          {
            name: "Hay Ouifak",
            id: 784,
            $version: 2
          },
          {
            name: "Qasbat Ennouar",
            id: 757,
            $version: 2
          },
          {
            name: "Quartier Ben Slimane",
            id: 785,
            $version: 2
          },
          {
            name: "Rahbet Zbib",
            id: 758,
            $version: 2
          },
          {
            name: "Route de Meknes",
            id: 768,
            $version: 2
          },
          {
            name: "Sehrij Gnawa",
            id: 774,
            $version: 2
          },
          {
            name: "Tghat",
            id: 786,
            $version: 2
          },
          {
            name: "Fes",
            id: 1604,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1808,
            $version: 1
          },
          {
            name: "Fes Jdid",
            id: 787,
            $version: 3
          },
          {
            name: "Marwa",
            id: 1985,
            $version: null
          },
          {
            name: "Autre",
            id: 2152,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Fes",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "FNIDEQ",
            id: 65,
            $version: null
          },
          {
            name: "Fnideq",
            id: 197,
            $version: null
          }
        ],
        displayName: "Fnideq",
        artmin: null,
        createdOn: "2020-10-19T13:29:29.786Z",
        inseeCode: null,
        id: 102,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Fnideq",
            id: 1212,
            $version: 4
          },
          {
            name: "electroplanet",
            id: 1540,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2042,
            $version: null
          },
          {
            name: "Aksal",
            id: 1877,
            $version: 1
          },
          {
            name: "Autre",
            id: 2209,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Fnideq",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "FKIH BEN SALAH",
            id: 15,
            $version: null
          },
          {
            name: "Fquih Ben Salah",
            id: 117,
            $version: null
          }
        ],
        displayName: "Fquih Ben Salah",
        artmin: null,
        createdOn: "2020-09-29T16:29:33.608Z",
        inseeCode: null,
        id: 45,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1490,
            $version: 1
          },
          {
            name: "Hay Bader",
            id: 1042,
            $version: 2
          },
          {
            name: "Hay El kayraouane",
            id: 1041,
            $version: 2
          },
          {
            name: "Hay El Zohour",
            id: 1040,
            $version: 2
          },
          {
            name: "Hay Essaada",
            id: 1038,
            $version: 2
          },
          {
            name: "Hay Jamila",
            id: 1039,
            $version: 2
          },
          {
            name: "Hay Rajae",
            id: 1037,
            $version: 2
          },
          {
            name: "Fquih Ben Salah",
            id: 1613,
            $version: 4
          },
          {
            name: "Aksal",
            id: 1828,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1980,
            $version: null
          },
          {
            name: "Autre",
            id: 2147,
            $version: null
          }
        ],
        updatedBy: {
          code: "admin",
          fullName: "Admin",
          id: 1,
          $version: 21
        },
        shippingMethod: "LAD",
        active: true,
        updatedOn: "2021-08-05T12:12:16.915Z",
        deliveryAvailability: true,
        version: 1,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Fquih Ben Salah",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "GUELMIM",
            id: 66,
            $version: null
          },
          {
            name: "Guelmim",
            id: 198,
            $version: null
          }
        ],
        displayName: "Guelmim",
        artmin: null,
        createdOn: "2020-12-08T14:28:20.895Z",
        inseeCode: null,
        id: 115,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Guelmim",
            id: 1225,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1559,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1894,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2065,
            $version: null
          },
          {
            name: "Autre",
            id: 2232,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Guelmim",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "GUERCIF",
            id: 72,
            $version: null
          },
          {
            name: "Guercif",
            id: 207,
            $version: null
          }
        ],
        displayName: "Guercif",
        artmin: null,
        createdOn: "2020-12-08T14:25:21.282Z",
        inseeCode: null,
        id: 103,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Guercif",
            id: 1213,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1547,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1884,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2055,
            $version: null
          },
          {
            name: "Autre",
            id: 2222,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Guercif",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Guigo",
            id: 199,
            $version: null
          }
        ],
        displayName: "Guigo",
        artmin: null,
        createdOn: "2020-12-08T14:27:21.368Z",
        inseeCode: null,
        id: 111,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Guigo",
            id: 1221,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1555,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1890,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2061,
            $version: null
          },
          {
            name: "Autre",
            id: 2228,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Guigo",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "HAD HRARA",
            id: 33,
            $version: null
          },
          {
            name: "Had Hrara",
            id: 158,
            $version: null
          }
        ],
        displayName: "Had Hrara",
        artmin: null,
        createdOn: "2020-09-30T17:25:38.792Z",
        inseeCode: null,
        id: 58,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1503,
            $version: 1
          },
          {
            name: "Had Hrara",
            id: 1145,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1841,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2016,
            $version: null
          },
          {
            name: "Autre",
            id: 2183,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Had Hrara",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "HAD SOUALEM",
            id: 34,
            $version: null
          },
          {
            name: "Had soualem",
            id: 159,
            $version: null
          }
        ],
        displayName: "Had soualem",
        artmin: null,
        createdOn: "2020-10-12T13:59:21.806Z",
        inseeCode: null,
        id: 74,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Had soualem",
            id: 1173,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1518,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1856,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2031,
            $version: null
          },
          {
            name: "Autre",
            id: 2198,
            $version: null
          }
        ],
        updatedBy: {
          code: "meryem",
          fullName: "Meryem Taibi",
          id: 103,
          $version: 26
        },
        shippingMethod: "LAD",
        active: true,
        updatedOn: "2020-10-12T16:20:01.612Z",
        deliveryAvailability: true,
        version: 1,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Had soualem",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "IFRANE",
            id: 67,
            $version: null
          },
          {
            name: "Ifrane",
            id: 200,
            $version: null
          }
        ],
        displayName: "Ifrane",
        artmin: null,
        createdOn: "2020-10-19T13:21:10.158Z",
        inseeCode: null,
        id: 89,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Cité al alkhawaine Club onicl",
            id: 1191,
            $version: 3
          },
          {
            name: "Atlas",
            id: 1189,
            $version: 5
          },
          {
            name: "Cité al alkhawaine",
            id: 1190,
            $version: 3
          },
          {
            name: "Zaouiat sidi abdeslam",
            id: 1198,
            $version: 3
          },
          {
            name: "Autre secteur",
            id: 1199,
            $version: 5
          },
          {
            name: "electroplanet",
            id: 1533,
            $version: 1
          },
          {
            name: "Famille-française",
            id: 1192,
            $version: 3
          },
          {
            name: "Résidence al arz",
            id: 1197,
            $version: 3
          },
          {
            name: "Public camping",
            id: 1196,
            $version: 3
          },
          {
            name: "Lotissement en phase d'amenagement",
            id: 1195,
            $version: 3
          },
          {
            name: "La belle vue",
            id: 1193,
            $version: 3
          },
          {
            name: "La Prairie",
            id: 1188,
            $version: 3
          },
          {
            name: "Les jardins d'ifrane",
            id: 1194,
            $version: 4
          },
          {
            name: "Ifrane",
            id: 1618,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1870,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2048,
            $version: null
          },
          {
            name: "Autre",
            id: 2215,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ifrane",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Imintanoute",
            id: 238,
            $version: null
          }
        ],
        displayName: "Imintanoute",
        artmin: null,
        createdOn: "2021-03-09T10:09:27.576Z",
        inseeCode: null,
        id: 168,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Imintanoute",
            id: 1290,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1583,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1918,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2086,
            $version: null
          },
          {
            name: "Autre",
            id: 2253,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Imintanoute",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Imouzzer Kandar",
            id: 208,
            $version: null
          }
        ],
        displayName: "Imouzzer Kandar",
        artmin: null,
        createdOn: "2020-10-19T13:22:52.374Z",
        inseeCode: null,
        id: 90,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1534,
            $version: 1
          },
          {
            name: "Imouzzer Kandar",
            id: 1200,
            $version: 4
          },
          {
            name: "Aksal",
            id: 1871,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2049,
            $version: null
          },
          {
            name: "Autre",
            id: 2216,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Imouzzer Kandar",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "IMSOUANE",
            id: 42,
            $version: null
          },
          {
            name: "Imsouane",
            id: 168,
            $version: null
          }
        ],
        displayName: "Imsouane",
        artmin: null,
        createdOn: "2020-09-30T17:30:22.085Z",
        inseeCode: null,
        id: 67,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1512,
            $version: 1
          },
          {
            name: "Imsouane",
            id: 1154,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1850,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2025,
            $version: null
          },
          {
            name: "Autre",
            id: 2192,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Imsouane",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "INZEGAN",
            id: 20,
            $version: null
          },
          {
            name: "Inzegane",
            id: 133,
            $version: null
          }
        ],
        displayName: "Inzegane",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 12,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1463,
            $version: 1
          },
          {
            name: "Inzegane",
            id: 801,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1801,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2117,
            $version: null
          },
          {
            name: "Autre",
            id: 2284,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Inzegane",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "JM3AT SHIM",
            id: 45,
            $version: null
          },
          {
            name: "Jemaat Shaim",
            id: 172,
            $version: null
          }
        ],
        displayName: "Jemaat Shaim",
        artmin: null,
        createdOn: "2020-09-30T17:28:34.236Z",
        inseeCode: null,
        id: 62,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1507,
            $version: 1
          },
          {
            name: "Jemaat Shaim",
            id: 1149,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1845,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2020,
            $version: null
          },
          {
            name: "Autre",
            id: 2187,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Jemaat Shaim",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "KASBAT TADLA",
            id: 243,
            $version: null
          }
        ],
        displayName: "KASBAT TADLA",
        artmin: null,
        createdOn: "2021-03-16T15:34:44.393Z",
        inseeCode: null,
        id: 181,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "KASBAT TADLA",
            id: 1303,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1595,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1930,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2104,
            $version: null
          },
          {
            name: "Autre",
            id: 2271,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "KASBAT TADLA",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Kelâa des Sraghna",
            id: 239,
            $version: null
          }
        ],
        displayName: "Kelâa des Sraghna",
        artmin: null,
        createdOn: "2021-03-09T10:14:47.006Z",
        inseeCode: null,
        id: 175,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Kelâa des Sraghna",
            id: 1297,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1589,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1924,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2093,
            $version: null
          },
          {
            name: "Autre",
            id: 2260,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Kelâa des Sraghna",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "KENITRA",
            id: 16,
            $version: null
          },
          {
            name: "Kenitra",
            id: 118,
            $version: null
          }
        ],
        displayName: "Kenitra",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 26,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Bir Rami Industriel",
            id: 816,
            $version: 0
          },
          {
            name: "Corcica",
            id: 827,
            $version: 0
          },
          {
            name: "La Ville Haute",
            id: 838,
            $version: 0
          },
          {
            name: "Assam",
            id: 849,
            $version: 0
          },
          {
            name: "Khabazat",
            id: 813,
            $version: 0
          },
          {
            name: "Bir Rami",
            id: 814,
            $version: 0
          },
          {
            name: "El Haddada",
            id: 815,
            $version: 0
          },
          {
            name: "La Cité",
            id: 817,
            $version: 0
          },
          {
            name: "Maamora",
            id: 818,
            $version: 0
          },
          {
            name: "Ouled Oujih",
            id: 819,
            $version: 0
          },
          {
            name: "Al Alama",
            id: 822,
            $version: 0
          },
          {
            name: "Al Baladya",
            id: 823,
            $version: 0
          },
          {
            name: "Al Maghrib Al Arabi",
            id: 824,
            $version: 0
          },
          {
            name: "Bab Fès",
            id: 825,
            $version: 0
          },
          {
            name: "Bir Anzarane",
            id: 826,
            $version: 0
          },
          {
            name: "Diour 10000",
            id: 828,
            $version: 0
          },
          {
            name: "Diour Sniak",
            id: 829,
            $version: 0
          },
          {
            name: "El Assam",
            id: 830,
            $version: 0
          },
          {
            name: "El Haouzia",
            id: 831,
            $version: 0
          },
          {
            name: "El Ismailia",
            id: 832,
            $version: 0
          },
          {
            name: "El Menzah",
            id: 833,
            $version: 0
          },
          {
            name: "Fouarate",
            id: 834,
            $version: 0
          },
          {
            name: "Inara",
            id: 835,
            $version: 0
          },
          {
            name: "La Base",
            id: 836,
            $version: 0
          },
          {
            name: "La Cigogne",
            id: 837,
            $version: 0
          },
          {
            name: "Mellah",
            id: 839,
            $version: 0
          },
          {
            name: "Mimosas",
            id: 840,
            $version: 0
          },
          {
            name: "Ouled M'Barek",
            id: 841,
            $version: 0
          },
          {
            name: "PAM",
            id: 842,
            $version: 0
          },
          {
            name: "Quartier Al Fath",
            id: 843,
            $version: 0
          },
          {
            name: "Quartier Nahda",
            id: 844,
            $version: 0
          },
          {
            name: "Seyad",
            id: 845,
            $version: 0
          },
          {
            name: "Taïbia",
            id: 846,
            $version: 0
          },
          {
            name: "Val Fleury",
            id: 847,
            $version: 0
          },
          {
            name: "Village",
            id: 848,
            $version: 0
          },
          {
            name: "Bir Rami Est",
            id: 850,
            $version: 0
          },
          {
            name: "Bir Rami Ouest",
            id: 851,
            $version: 0
          },
          {
            name: "Centre",
            id: 852,
            $version: 0
          },
          {
            name: "Ain Sebaa",
            id: 821,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1477,
            $version: 1
          },
          {
            name: "Kenitra",
            id: 1608,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1815,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1992,
            $version: null
          },
          {
            name: "Autre",
            id: 2159,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Kenitra",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "KHEMISSET",
            id: 1,
            $version: null
          },
          {
            name: "Khmisset",
            id: 93,
            $version: null
          }
        ],
        displayName: "Khmisset",
        artmin: null,
        createdOn: "2020-10-17T13:50:44.680Z",
        inseeCode: null,
        id: 79,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Khmisset",
            id: 1178,
            $version: 2
          },
          {
            name: "electroplanet",
            id: 1523,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1861,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2036,
            $version: null
          },
          {
            name: "Autre",
            id: 2203,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Khmisset",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "KHOURIBGA",
            id: 89,
            $version: null
          },
          {
            name: "Khouribga",
            id: 244,
            $version: null
          }
        ],
        displayName: "Khouribga",
        artmin: null,
        createdOn: "2021-03-16T15:33:16.022Z",
        inseeCode: null,
        id: 179,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Centre ville",
            id: 1301,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1593,
            $version: 1
          },
          {
            name: "Khouribga",
            id: 1623,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1928,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2102,
            $version: null
          },
          {
            name: "Autre",
            id: 2269,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Khouribga",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "LAAYOUNE",
            id: 76,
            $version: null
          },
          {
            name: "Laayoune",
            id: 215,
            $version: null
          }
        ],
        displayName: "Laayoune",
        artmin: null,
        createdOn: "2021-02-15T14:50:00.666Z",
        inseeCode: null,
        id: 148,
        department: {
          code: "LIVRAISON",
          name: "Livraison",
          id: 1,
          $version: 1
        },
        selected: false,
        zip: null,
        sectors: [
          {
            name: "centre ville",
            id: 1269,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1566,
            $version: 1
          },
          {
            name: "Laayoune",
            id: 1619,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1901,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2070,
            $version: null
          },
          {
            name: "Autre",
            id: 2237,
            $version: null
          }
        ],
        updatedBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        shippingMethod: "LAD",
        active: true,
        updatedOn: "2021-02-18T21:01:30.691Z",
        deliveryAvailability: true,
        version: 1,
        
        importId: null,
        createdBy: {
          code: "meryem",
          fullName: "Meryem Taibi",
          id: 103,
          $version: 26
        },
        hasZipOnRight: false,
        name: "Laayoune",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Lagouassem",
            id: 209,
            $version: null
          }
        ],
        displayName: "Lagouassem",
        artmin: null,
        createdOn: "2021-03-09T09:50:33.236Z",
        inseeCode: null,
        id: 151,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Lagouassem",
            id: 1272,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1569,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1904,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2073,
            $version: null
          },
          {
            name: "Autre",
            id: 2240,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Lagouassem",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Lahebichate (Inactive)",
            id: 92,
            $version: null
          }
        ],
        displayName: "Lahebichate (Inactive)",
        artmin: null,
        createdOn: "2021-03-09T09:51:29.368Z",
        inseeCode: null,
        id: 152,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Lahebichate",
            id: 1273,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1570,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1905,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2074,
            $version: null
          },
          {
            name: "Autre",
            id: 2241,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Lahebichate (Inactive)",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "LALA FATNA",
            id: 46,
            $version: null
          },
          {
            name: "Lalla Fatna",
            id: 173,
            $version: null
          }
        ],
        displayName: "Lalla Fatna",
        artmin: null,
        createdOn: "2020-09-30T17:25:57.521Z",
        inseeCode: null,
        id: 59,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1504,
            $version: 1
          },
          {
            name: "Lalla Fatna",
            id: 1146,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1842,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2017,
            $version: null
          },
          {
            name: "Autre",
            id: 2184,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Lalla Fatna",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Laqliaâ",
            id: 119,
            $version: null
          }
        ],
        displayName: "Laqliaâ",
        artmin: null,
        createdOn: "2021-08-13T12:54:00.501Z",
        inseeCode: null,
        id: 202,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Laqliaâ",
            id: 1959,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1960,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1976,
            $version: null
          },
          {
            name: "Autre",
            id: 2143,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Laqliaâ",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Larache",
            id: 169,
            $version: null
          }
        ],
        displayName: "Larache",
        artmin: null,
        createdOn: "2020-10-17T13:50:26.663Z",
        inseeCode: null,
        id: 78,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1522,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1860,
            $version: 1
          },
          {
            name: "Larache",
            id: 1177,
            $version: 3
          },
          {
            name: "Marwa",
            id: 2035,
            $version: null
          },
          {
            name: "Autre",
            id: 2202,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Larache",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Leqsabi",
            id: 216,
            $version: null
          }
        ],
        displayName: "Leqsabi",
        artmin: null,
        createdOn: "2020-12-08T14:28:08.025Z",
        inseeCode: null,
        id: 114,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Leqsabi",
            id: 1224,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1558,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1893,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2064,
            $version: null
          },
          {
            name: "Autre",
            id: 2231,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Leqsabi",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "LOUIZIA",
            id: 17,
            $version: null
          },
          {
            name: "Louizia",
            id: 120,
            $version: null
          }
        ],
        displayName: "Louizia",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 28,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Louizia",
            id: 977,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1479,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1817,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1994,
            $version: null
          },
          {
            name: "Autre",
            id: 2161,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Louizia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Marrakech",
            id: 245,
            $version: null
          }
        ],
        displayName: "Marrakech",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 2,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Ancienne Médina",
            id: 387,
            $version: 1
          },
          {
            name: "Assif",
            id: 388,
            $version: 1
          },
          {
            name: "Assouel",
            id: 443,
            $version: 1
          },
          {
            name: "Es Saada",
            id: 367,
            $version: 1
          },
          {
            name: "Annakhil",
            id: 364,
            $version: 0
          },
          {
            name: "Hivernage",
            id: 370,
            $version: 0
          },
          {
            name: "Sofia",
            id: 381,
            $version: 0
          },
          {
            name: "Av Mohammed VI",
            id: 391,
            $version: 0
          },
          {
            name: "El Ghoul",
            id: 399,
            $version: 0
          },
          {
            name: "Mabrouka",
            id: 410,
            $version: 0
          },
          {
            name: "M'Hamid",
            id: 413,
            $version: 0
          },
          {
            name: "Route de Lalla Takerkoust",
            id: 418,
            $version: 0
          },
          {
            name: "Route de Tahanaoute",
            id: 423,
            $version: 0
          },
          {
            name: "Hay Annahda",
            id: 471,
            $version: 0
          },
          {
            name: "Les Portes de Marrakech",
            id: 476,
            $version: 0
          },
          {
            name: "Jnane Laafia",
            id: 433,
            $version: 2
          },
          {
            name: "Kaat Ben Nahid",
            id: 451,
            $version: 2
          },
          {
            name: "Kbour Chou",
            id: 452,
            $version: 2
          },
          {
            name: "Kechich",
            id: 453,
            $version: 2
          },
          {
            name: "Michouar",
            id: 464,
            $version: 2
          },
          {
            name: "Mouassine",
            id: 455,
            $version: 2
          },
          {
            name: "Rahba Kedima",
            id: 456,
            $version: 2
          },
          {
            name: "Rmila",
            id: 458,
            $version: 2
          },
          {
            name: "Sidi Bou Amar",
            id: 460,
            $version: 2
          },
          {
            name: "Zaouia Sidi Ghalem",
            id: 462,
            $version: 2
          },
          {
            name: "Zaouiat Lahdar",
            id: 463,
            $version: 2
          },
          {
            name: "Zone Industrielle Sidi Ghanem",
            id: 480,
            $version: 2
          },
          {
            name: "Kennaria",
            id: 454,
            $version: 2
          },
          {
            name: "Route de Fès",
            id: 417,
            $version: 2
          },
          {
            name: "Semlalia",
            id: 425,
            $version: 2
          },
          {
            name: "Sidi Ben Slimane El Jazouli",
            id: 459,
            $version: 2
          },
          {
            name: "Sidi Youssef Ben Ali",
            id: 380,
            $version: 2
          },
          {
            name: "Bin Lkchali",
            id: 365,
            $version: 0
          },
          {
            name: "Guéliz",
            id: 369,
            $version: 0
          },
          {
            name: "Inara",
            id: 371,
            $version: 0
          },
          {
            name: "Masmoudi",
            id: 372,
            $version: 0
          },
          {
            name: "Massira",
            id: 373,
            $version: 0
          },
          {
            name: "Oued Ourika",
            id: 375,
            $version: 0
          },
          {
            name: "Oulad Said",
            id: 377,
            $version: 0
          },
          {
            name: "Targa",
            id: 382,
            $version: 0
          },
          {
            name: "Av Abdelkrim El Khattabi",
            id: 389,
            $version: 0
          },
          {
            name: "Av Mohammed V",
            id: 390,
            $version: 0
          },
          {
            name: "Azli",
            id: 392,
            $version: 0
          },
          {
            name: "Azzouzia",
            id: 393,
            $version: 0
          },
          {
            name: "Bab Ighli",
            id: 397,
            $version: 0
          },
          {
            name: "Harti",
            id: 400,
            $version: 0
          },
          {
            name: "Hay Massira",
            id: 404,
            $version: 0
          },
          {
            name: "Hôpital Militaire",
            id: 405,
            $version: 0
          },
          {
            name: "Kadissia",
            id: 408,
            $version: 0
          },
          {
            name: "Afaq",
            id: 383,
            $version: 1
          },
          {
            name: "Ain Mezouar",
            id: 465,
            $version: 1
          },
          {
            name: "Route d'Amezmiz",
            id: 415,
            $version: 0
          },
          {
            name: "Route d'Ourika",
            id: 420,
            $version: 0
          },
          {
            name: "Victor Hugo",
            id: 429,
            $version: 0
          },
          {
            name: "Bab Lhmar",
            id: 430,
            $version: 0
          },
          {
            name: "Berrima",
            id: 431,
            $version: 0
          },
          {
            name: "Sidi Mansour",
            id: 436,
            $version: 0
          },
          {
            name: "Bouaakkaz",
            id: 466,
            $version: 0
          },
          {
            name: "Hay Al Bahja",
            id: 467,
            $version: 0
          },
          {
            name: "Hay Al Haouz",
            id: 468,
            $version: 0
          },
          {
            name: "Hay Al Hassani",
            id: 469,
            $version: 0
          },
          {
            name: "Hay Lalla Haya",
            id: 472,
            $version: 0
          },
          {
            name: "Hay Targa",
            id: 473,
            $version: 0
          },
          {
            name: "Iziki",
            id: 474,
            $version: 0
          },
          {
            name: "Jawhar",
            id: 475,
            $version: 0
          },
          {
            name: "Massira 1",
            id: 477,
            $version: 0
          },
          {
            name: "Massira 2",
            id: 478,
            $version: 0
          },
          {
            name: "Massira 3",
            id: 479,
            $version: 0
          },
          {
            name: "Route d'Agadir - Essaouira",
            id: 481,
            $version: 0
          },
          {
            name: "Issil",
            id: 406,
            $version: 2
          },
          {
            name: "Akioud",
            id: 362,
            $version: 1
          },
          {
            name: "Jemaa El Fna",
            id: 407,
            $version: 2
          },
          {
            name: "Jnan ben chagra",
            id: 449,
            $version: 2
          },
          {
            name: "Kaa El Machraa",
            id: 450,
            $version: 2
          },
          {
            name: "Ksibat Nhas",
            id: 434,
            $version: 2
          },
          {
            name: "Majorelle",
            id: 411,
            $version: 2
          },
          {
            name: "Massar",
            id: 412,
            $version: 2
          },
          {
            name: "Moulay Lyazid",
            id: 435,
            $version: 2
          },
          {
            name: "Oued Tensift",
            id: 376,
            $version: 2
          },
          {
            name: "Palmeraie",
            id: 378,
            $version: 2
          },
          {
            name: "Rouidat",
            id: 414,
            $version: 2
          },
          {
            name: "Route de Casablanca",
            id: 416,
            $version: 2
          },
          {
            name: "Route de Ouarzazate",
            id: 419,
            $version: 2
          },
          {
            name: "Route de Safi",
            id: 421,
            $version: 2
          },
          {
            name: "Route de Sidi A. Ghiat",
            id: 422,
            $version: 2
          },
          {
            name: "Route de Tamansourt",
            id: 424,
            $version: 2
          },
          {
            name: "Sakar",
            id: 379,
            $version: 2
          },
          {
            name: "Sidi Abbad",
            id: 426,
            $version: 2
          },
          {
            name: "Sidi Abdellah Ghiat",
            id: 427,
            $version: 2
          },
          {
            name: "Sidi Ghanem",
            id: 428,
            $version: 2
          },
          {
            name: "Riad Zitoun",
            id: 457,
            $version: 2
          },
          {
            name: "Bab Hamra",
            id: 974,
            $version: 0
          },
          {
            name: "Agdal",
            id: 384,
            $version: 3
          },
          {
            name: "Allal El Fassi",
            id: 385,
            $version: 1
          },
          {
            name: "Amelkis",
            id: 386,
            $version: 1
          },
          {
            name: "Amerchich",
            id: 363,
            $version: 1
          },
          {
            name: "Arset Ben Chebli",
            id: 437,
            $version: 1
          },
          {
            name: "Arset El Houta",
            id: 438,
            $version: 1
          },
          {
            name: "Arset El Maach",
            id: 439,
            $version: 1
          },
          {
            name: "Arset Ihiri",
            id: 440,
            $version: 1
          },
          {
            name: "Arset Moulay Bouaazza",
            id: 441,
            $version: 1
          },
          {
            name: "Arset Moulay Moussa",
            id: 442,
            $version: 1
          },
          {
            name: "Bab Atlas",
            id: 394,
            $version: 1
          },
          {
            name: "Bab Aylan",
            id: 444,
            $version: 1
          },
          {
            name: "Bab Doukkala",
            id: 395,
            $version: 1
          },
          {
            name: "Bab El Khemis",
            id: 396,
            $version: 1
          },
          {
            name: "Bab Ghmat",
            id: 445,
            $version: 1
          },
          {
            name: "Bd Moulay Abdellah",
            id: 398,
            $version: 1
          },
          {
            name: "Ben Saleh",
            id: 446,
            $version: 1
          },
          {
            name: "Centre Ville",
            id: 374,
            $version: 1
          },
          {
            name: "Daoudiate",
            id: 366,
            $version: 1
          },
          {
            name: "Derb Chtouka",
            id: 432,
            $version: 1
          },
          {
            name: "El Moukef",
            id: 447,
            $version: 1
          },
          {
            name: "Essebtiyen",
            id: 448,
            $version: 1
          },
          {
            name: "Fassi",
            id: 368,
            $version: 1
          },
          {
            name: "Hay Al Massar",
            id: 470,
            $version: 1
          },
          {
            name: "Hay Andalous",
            id: 401,
            $version: 1
          },
          {
            name: "Hay Charaf",
            id: 402,
            $version: 1
          },
          {
            name: "Hay Izdihar",
            id: 403,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1453,
            $version: 1
          },
          {
            name: "Marrakech",
            id: 1599,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1791,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2090,
            $version: null
          },
          {
            name: "Autre",
            id: 2257,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Marrakech",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "MARTIL",
            id: 18,
            $version: null
          },
          {
            name: "Martil",
            id: 121,
            $version: null
          }
        ],
        displayName: "Martil",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 25,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1476,
            $version: 1
          },
          {
            name: "Martil",
            id: 811,
            $version: 0
          },
          {
            name: "Aksal",
            id: 1814,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1991,
            $version: null
          },
          {
            name: "Autre",
            id: 2158,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Martil",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Mechraa belksiri",
            id: 136,
            $version: null
          }
        ],
        displayName: "Mechraa belksiri",
        artmin: null,
        createdOn: "2021-06-29T17:56:54.985Z",
        inseeCode: null,
        id: 193,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "CENTRE VILLE",
            id: 1943,
            $version: 0
          },
          {
            name: "Marwa",
            id: 2130,
            $version: null
          },
          {
            name: "Autre",
            id: 2297,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Mechraa belksiri",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Medieq",
            id: 223,
            $version: null
          }
        ],
        displayName: "Medieq",
        artmin: null,
        createdOn: "2020-10-19T13:29:07.559Z",
        inseeCode: null,
        id: 101,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Medieq",
            id: 1211,
            $version: 4
          },
          {
            name: "electroplanet",
            id: 1546,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1883,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2054,
            $version: null
          },
          {
            name: "Autre",
            id: 2221,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Medieq",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "MEDIOUNA",
            id: 3,
            $version: null
          },
          {
            name: "Mediouna",
            id: 98,
            $version: null
          }
        ],
        displayName: "Mediouna",
        artmin: null,
        createdOn: "2021-07-19T10:33:58.405Z",
        inseeCode: null,
        id: 197,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Mediouna",
            id: 1947,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1970,
            $version: null
          },
          {
            name: "Autre",
            id: 2137,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Mediouna",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Mejjat",
            id: 256,
            $version: null
          }
        ],
        displayName: "Mejjat",
        artmin: null,
        createdOn: "2021-03-09T10:12:38.541Z",
        inseeCode: null,
        id: 172,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Mejjat",
            id: 1294,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1586,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1921,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2089,
            $version: null
          },
          {
            name: "Autre",
            id: 2256,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Mejjat",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "MEKNES",
            id: 4,
            $version: null
          },
          {
            name: "Meknes",
            id: 99,
            $version: null
          }
        ],
        displayName: "Meknes",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 27,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Cité Impériale",
            id: 485,
            $version: 0
          },
          {
            name: "Hamria",
            id: 502,
            $version: 0
          },
          {
            name: "Sidi Said",
            id: 507,
            $version: 0
          },
          {
            name: "Ben Mohammed",
            id: 482,
            $version: 0
          },
          {
            name: "Berrima",
            id: 483,
            $version: 0
          },
          {
            name: "Bourj Moulay Omar",
            id: 484,
            $version: 0
          },
          {
            name: "Dar Kebira",
            id: 486,
            $version: 0
          },
          {
            name: "El Bassatine",
            id: 487,
            $version: 0
          },
          {
            name: "El Mechouar Stinia",
            id: 488,
            $version: 0
          },
          {
            name: "Kasbat",
            id: 489,
            $version: 0
          },
          {
            name: "Khedrache",
            id: 490,
            $version: 0
          },
          {
            name: "Marjane 2",
            id: 491,
            $version: 0
          },
          {
            name: "Mèdina",
            id: 492,
            $version: 0
          },
          {
            name: "Nouveau Mellah",
            id: 493,
            $version: 0
          },
          {
            name: "Omar",
            id: 494,
            $version: 0
          },
          {
            name: "Riad Toulal",
            id: 495,
            $version: 0
          },
          {
            name: "Sbata",
            id: 496,
            $version: 0
          },
          {
            name: "Sidi Amar Hassini",
            id: 497,
            $version: 0
          },
          {
            name: "Ville Nouvelle",
            id: 498,
            $version: 0
          },
          {
            name: "Bele Vue",
            id: 501,
            $version: 0
          },
          {
            name: "Hay Salam",
            id: 503,
            $version: 0
          },
          {
            name: "La Hacienda",
            id: 504,
            $version: 0
          },
          {
            name: "Rouamzine",
            id: 505,
            $version: 0
          },
          {
            name: "Sidi Bouzekri",
            id: 506,
            $version: 0
          },
          {
            name: "Wislane",
            id: 508,
            $version: 0
          },
          {
            name: "Zerhounia",
            id: 509,
            $version: 0
          },
          {
            name: "Zitoune",
            id: 510,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1478,
            $version: 1
          },
          {
            name: "Meknes",
            id: 1609,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1816,
            $version: 1
          },
          {
            name: "Ancienne Médina",
            id: 500,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1993,
            $version: null
          },
          {
            name: "Autre",
            id: 2160,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Meknes",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "MHAYA FES",
            id: 73,
            $version: null
          },
          {
            name: "Mhaya",
            id: 210,
            $version: null
          }
        ],
        displayName: "Mhaya",
        artmin: null,
        createdOn: "2020-10-19T13:11:02.033Z",
        inseeCode: null,
        id: 85,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Mhaya",
            id: 1184,
            $version: 4
          },
          {
            name: "electroplanet",
            id: 1529,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1866,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2044,
            $version: null
          },
          {
            name: "Autre",
            id: 2211,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Mhaya",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "MIDELT",
            id: 74,
            $version: null
          },
          {
            name: "Midelt",
            id: 211,
            $version: null
          }
        ],
        displayName: "Midelt",
        artmin: null,
        createdOn: "2020-12-08T14:26:43.140Z",
        inseeCode: null,
        id: 109,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Midelt",
            id: 1219,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1553,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1888,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2059,
            $version: null
          },
          {
            name: "Autre",
            id: 2226,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Midelt",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Missour",
            id: 212,
            $version: null
          }
        ],
        displayName: "Missour",
        artmin: null,
        createdOn: "2020-12-08T14:26:29.093Z",
        inseeCode: null,
        id: 108,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Missour",
            id: 1218,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1552,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1887,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2058,
            $version: null
          },
          {
            name: "Autre",
            id: 2225,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Missour",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Mohammadia",
            id: 137,
            $version: null
          }
        ],
        displayName: "Mohammadia",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 7,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1458,
            $version: 1
          },
          {
            name: "Al Qods",
            id: 863,
            $version: 1
          },
          {
            name: "Amal",
            id: 853,
            $version: 1
          },
          {
            name: "Belle Vue",
            id: 854,
            $version: 1
          },
          {
            name: "Centre Ville",
            id: 864,
            $version: 1
          },
          {
            name: "Corniche",
            id: 855,
            $version: 1
          },
          {
            name: "Derb Bouchman",
            id: 865,
            $version: 1
          },
          {
            name: "Derb Jamila",
            id: 866,
            $version: 1
          },
          {
            name: "Derb Lfath",
            id: 867,
            $version: 1
          },
          {
            name: "Derb Marrakech",
            id: 868,
            $version: 1
          },
          {
            name: "Derb Meknes",
            id: 869,
            $version: 1
          },
          {
            name: "Derb Rbat",
            id: 870,
            $version: 1
          },
          {
            name: "Diour Dokala",
            id: 871,
            $version: 1
          },
          {
            name: "Diour Lakrai",
            id: 872,
            $version: 1
          },
          {
            name: "Diour Nicolas",
            id: 873,
            $version: 1
          },
          {
            name: "El Alia",
            id: 856,
            $version: 1
          },
          {
            name: "El Bradaa",
            id: 874,
            $version: 1
          },
          {
            name: "El Kasbah",
            id: 857,
            $version: 1
          },
          {
            name: "Hassania 1",
            id: 875,
            $version: 1
          },
          {
            name: "Hassania 2",
            id: 876,
            $version: 1
          },
          {
            name: "Hassania 3",
            id: 877,
            $version: 1
          },
          {
            name: "Hay Al Amal",
            id: 878,
            $version: 1
          },
          {
            name: "Hay Al Falah",
            id: 879,
            $version: 1
          },
          {
            name: "Hay Al Horria",
            id: 880,
            $version: 1
          },
          {
            name: "Hay Al Wahda",
            id: 881,
            $version: 1
          },
          {
            name: "Hay Annasr",
            id: 882,
            $version: 1
          },
          {
            name: "Hay Chabab",
            id: 883,
            $version: 1
          },
          {
            name: "Hay Essalama",
            id: 884,
            $version: 1
          },
          {
            name: "Hay Mesk Lil",
            id: 885,
            $version: 1
          },
          {
            name: "Hay saada",
            id: 887,
            $version: 1
          },
          {
            name: "Hay Salam",
            id: 886,
            $version: 1
          },
          {
            name: "Kasbah",
            id: 888,
            $version: 1
          },
          {
            name: "La Colline",
            id: 890,
            $version: 1
          },
          {
            name: "La Siesta",
            id: 858,
            $version: 1
          },
          {
            name: "Les Cretes",
            id: 891,
            $version: 1
          },
          {
            name: "L'habitat",
            id: 889,
            $version: 1
          },
          {
            name: "Mimosas",
            id: 859,
            $version: 1
          },
          {
            name: "Monica",
            id: 892,
            $version: 1
          },
          {
            name: "Nassim",
            id: 893,
            $version: 1
          },
          {
            name: "Partie Est",
            id: 860,
            $version: 1
          },
          {
            name: "Port",
            id: 894,
            $version: 1
          },
          {
            name: "Quartier du Parc",
            id: 896,
            $version: 1
          },
          {
            name: "Quartier Du Soleil",
            id: 895,
            $version: 1
          },
          {
            name: "Rachidia",
            id: 897,
            $version: 1
          },
          {
            name: "Riad 1",
            id: 898,
            $version: 1
          },
          {
            name: "Riad 2",
            id: 899,
            $version: 1
          },
          {
            name: "Tenoukchet",
            id: 900,
            $version: 1
          },
          {
            name: "Wafa",
            id: 861,
            $version: 1
          },
          {
            name: "Yassmine",
            id: 862,
            $version: 1
          },
          {
            name: "Zone industrielle",
            id: 901,
            $version: 1
          },
          {
            name: "Mohammadia",
            id: 1601,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1796,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2112,
            $version: null
          },
          {
            name: "Autre",
            id: 2279,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Mohammadia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Nador",
            id: 140,
            $version: null
          }
        ],
        displayName: "Nador",
        artmin: null,
        createdOn: "2021-06-11T10:38:06.580Z",
        inseeCode: null,
        id: 185,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Nador",
            id: 1628,
            $version: 1
          },
          {
            name: "Ismailia",
            id: 1631,
            $version: 1
          },
          {
            name: "Chaala",
            id: 1630,
            $version: 1
          },
          {
            name: "Centre ville",
            id: 1629,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1936,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2126,
            $version: null
          },
          {
            name: "Autre",
            id: 2293,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Nador",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "NOUACEUR",
            id: 103,
            $version: null
          }
        ],
        displayName: "NOUACEUR",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 31,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Nouaceur",
            id: 980,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1481,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1819,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1996,
            $version: null
          },
          {
            name: "Autre",
            id: 2163,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "NOUACEUR",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OUALIDIA",
            id: 25,
            $version: null
          },
          {
            name: "Oualidia",
            id: 149,
            $version: null
          }
        ],
        displayName: "Oualidia",
        artmin: null,
        createdOn: "2020-09-30T17:24:49.915Z",
        inseeCode: null,
        id: 56,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1501,
            $version: 1
          },
          {
            name: "Oualidia",
            id: 1143,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1839,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2014,
            $version: null
          },
          {
            name: "Autre",
            id: 2181,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Oualidia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OUAD AMLIL",
            id: 81,
            $version: null
          },
          {
            name: "Oued Amlil",
            id: 228,
            $version: null
          }
        ],
        displayName: "Oued Amlil",
        artmin: null,
        createdOn: "2020-10-19T13:25:49.334Z",
        inseeCode: null,
        id: 96,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Oued Amlil",
            id: 1206,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1542,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1879,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2095,
            $version: null
          },
          {
            name: "Autre",
            id: 2262,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Oued Amlil",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OUED CHERAT",
            id: 26,
            $version: null
          },
          {
            name: "Oued Cherat",
            id: 150,
            $version: null
          }
        ],
        displayName: "Oued Cherat",
        artmin: null,
        createdOn: "2020-09-29T16:17:32.648Z",
        inseeCode: null,
        id: 36,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1486,
            $version: 1
          },
          {
            name: "Oued Cherat",
            id: 1007,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1824,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2000,
            $version: null
          },
          {
            name: "Autre",
            id: 2167,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Oued Cherat",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OUAD ZEM",
            id: 90,
            $version: null
          },
          {
            name: "OUED ZEM",
            id: 246,
            $version: null
          }
        ],
        displayName: "OUED ZEM",
        artmin: null,
        createdOn: "2021-03-16T15:34:01.473Z",
        inseeCode: null,
        id: 180,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "OUED ZEM",
            id: 1302,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1594,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1929,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2103,
            $version: null
          },
          {
            name: "Autre",
            id: 2270,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "OUED ZEM",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OUJDA",
            id: 8,
            $version: null
          },
          {
            name: "Oujda",
            id: 104,
            $version: null
          }
        ],
        displayName: "Oujda",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 20,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1471,
            $version: 1
          },
          {
            name: "Centre Ville",
            id: 938,
            $version: 0
          },
          {
            name: "Bd Jaych Tahrir",
            id: 939,
            $version: 0
          },
          {
            name: "CGI",
            id: 940,
            $version: 0
          },
          {
            name: "Hay El Andalous",
            id: 941,
            $version: 0
          },
          {
            name: "Hay El Hikma",
            id: 942,
            $version: 0
          },
          {
            name: "Hay El Qods",
            id: 943,
            $version: 0
          },
          {
            name: "Hay Lazaret",
            id: 944,
            $version: 0
          },
          {
            name: "Hay Salam",
            id: 945,
            $version: 0
          },
          {
            name: "Hay Takkadom",
            id: 946,
            $version: 0
          },
          {
            name: "Iris",
            id: 947,
            $version: 0
          },
          {
            name: "Route Jerada",
            id: 948,
            $version: 0
          },
          {
            name: "Agdal",
            id: 949,
            $version: 0
          },
          {
            name: "Ancienne medina",
            id: 950,
            $version: 0
          },
          {
            name: "Andalous",
            id: 951,
            $version: 0
          },
          {
            name: "Bd Allal el fassi",
            id: 952,
            $version: 0
          },
          {
            name: "Bd Derfoufi",
            id: 953,
            $version: 0
          },
          {
            name: "Bd Hassan II",
            id: 954,
            $version: 0
          },
          {
            name: "Boustane",
            id: 955,
            $version: 0
          },
          {
            name: "El firdaws",
            id: 956,
            $version: 0
          },
          {
            name: "Grand boulevard Ahfir",
            id: 957,
            $version: 0
          },
          {
            name: "Hay Boudir",
            id: 958,
            $version: 0
          },
          {
            name: "Hay Ennasr",
            id: 959,
            $version: 0
          },
          {
            name: "Hay fath",
            id: 960,
            $version: 0
          },
          {
            name: "Hay massira",
            id: 961,
            $version: 0
          },
          {
            name: "Hay zitoune",
            id: 962,
            $version: 0
          },
          {
            name: "Hikma I",
            id: 963,
            $version: 0
          },
          {
            name: "Hikma II",
            id: 964,
            $version: 0
          },
          {
            name: "Jawhara",
            id: 965,
            $version: 0
          },
          {
            name: "Nahda",
            id: 966,
            $version: 0
          },
          {
            name: "Najd",
            id: 967,
            $version: 0
          },
          {
            name: "Nour",
            id: 968,
            $version: 0
          },
          {
            name: "Park",
            id: 969,
            $version: 0
          },
          {
            name: "Riad isli",
            id: 970,
            $version: 0
          },
          {
            name: "Route Tayret",
            id: 971,
            $version: 0
          },
          {
            name: "Sidi Yahya",
            id: 972,
            $version: 0
          },
          {
            name: "Oujda",
            id: 1605,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1809,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1986,
            $version: null
          },
          {
            name: "Autre",
            id: 2153,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Oujda",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Oulad Dahou",
            id: 107,
            $version: null
          }
        ],
        displayName: "Oulad Dahou",
        artmin: null,
        createdOn: "2021-08-13T12:53:23.418Z",
        inseeCode: null,
        id: 201,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Oulad Dahou",
            id: 1957,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1958,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1975,
            $version: null
          },
          {
            name: "Autre",
            id: 2142,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Oulad Dahou",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OULED AYAD",
            id: 54,
            $version: null
          },
          {
            name: "Ouled Ayad",
            id: 182,
            $version: null
          }
        ],
        displayName: "Ouled Ayad",
        artmin: null,
        createdOn: "2020-09-29T16:31:02.058Z",
        inseeCode: null,
        id: 46,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1492,
            $version: 1
          },
          {
            name: "Ouled Ayad",
            id: 1043,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1830,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2005,
            $version: null
          },
          {
            name: "Autre",
            id: 2172,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ouled Ayad",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Ouled Teima",
            id: 108,
            $version: null
          }
        ],
        displayName: "Ouled Teima",
        artmin: null,
        createdOn: "2021-08-13T12:52:39.397Z",
        inseeCode: null,
        id: 199,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Ouled Teima",
            id: 1953,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1954,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1973,
            $version: null
          },
          {
            name: "Autre",
            id: 2140,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Ouled Teima",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OULED ZIDOUH",
            id: 27,
            $version: null
          },
          {
            name: "Ouled Zidouh",
            id: 151,
            $version: null
          }
        ],
        displayName: "Ouled Zidouh",
        artmin: null,
        createdOn: "2020-09-29T16:33:04.076Z",
        inseeCode: null,
        id: 48,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1494,
            $version: 1
          },
          {
            name: "Ouled Zidouh",
            id: 1045,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1832,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2007,
            $version: null
          },
          {
            name: "Autre",
            id: 2174,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ouled Zidouh",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "OUNAGHA",
            id: 55,
            $version: null
          },
          {
            name: "Ounagha",
            id: 183,
            $version: null
          }
        ],
        displayName: "Ounagha",
        artmin: null,
        createdOn: "2020-09-30T17:30:01.342Z",
        inseeCode: null,
        id: 66,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1511,
            $version: 1
          },
          {
            name: "Ounagha",
            id: 1153,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1849,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2024,
            $version: null
          },
          {
            name: "Autre",
            id: 2191,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Ounagha",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Outatlhaj",
            id: 213,
            $version: null
          }
        ],
        displayName: "Outatlhaj",
        artmin: null,
        createdOn: "2020-12-08T14:27:09.100Z",
        inseeCode: null,
        id: 110,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Outatlhaj",
            id: 1220,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1554,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1889,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2060,
            $version: null
          },
          {
            name: "Autre",
            id: 2227,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Outatlhaj",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "RABAT",
            id: 5,
            $version: null
          },
          {
            name: "Rabat",
            id: 100,
            $version: null
          }
        ],
        displayName: "Rabat",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 3,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1454,
            $version: 1
          },
          {
            name: "Agdal",
            id: 232,
            $version: 1
          },
          {
            name: "Al Irfane",
            id: 252,
            $version: 1
          },
          {
            name: "Aakkari",
            id: 231,
            $version: 2
          },
          {
            name: "Abi Ragrag",
            id: 257,
            $version: 2
          },
          {
            name: "Aviation",
            id: 233,
            $version: 2
          },
          {
            name: "Aviation - Mabella",
            id: 258,
            $version: 1
          },
          {
            name: "Diour Jamaa",
            id: 234,
            $version: 1
          },
          {
            name: "Douar el Hajja",
            id: 235,
            $version: 1
          },
          {
            name: "El Youssoufia",
            id: 259,
            $version: 1
          },
          {
            name: "Guich Oudaya",
            id: 253,
            $version: 1
          },
          {
            name: "Hassan",
            id: 236,
            $version: 1
          },
          {
            name: "Haut Agdal",
            id: 254,
            $version: 1
          },
          {
            name: "Hay Arrachad",
            id: 261,
            $version: 1
          },
          {
            name: "Hay el Fath",
            id: 237,
            $version: 1
          },
          {
            name: "Hay Nahda",
            id: 238,
            $version: 1
          },
          {
            name: "Hay Riad",
            id: 239,
            $version: 1
          },
          {
            name: "Les Ambassadeurs",
            id: 240,
            $version: 1
          },
          {
            name: "Les Orangers",
            id: 241,
            $version: 1
          },
          {
            name: "Les Oudayas",
            id: 242,
            $version: 1
          },
          {
            name: "Hay Al Farah",
            id: 260,
            $version: 2
          },
          {
            name: "Mabella",
            id: 243,
            $version: 1
          },
          {
            name: "Massira",
            id: 244,
            $version: 1
          },
          {
            name: "Mechouar",
            id: 262,
            $version: 1
          },
          {
            name: "Quartier de l'Océan",
            id: 251,
            $version: 1
          },
          {
            name: "Riyad",
            id: 255,
            $version: 1
          },
          {
            name: "Riyad Extension",
            id: 256,
            $version: 1
          },
          {
            name: "Souissi",
            id: 246,
            $version: 1
          },
          {
            name: "Taqaddoum",
            id: 247,
            $version: 1
          },
          {
            name: "Yacoub El Mansour",
            id: 248,
            $version: 1
          },
          {
            name: "Youssoufia",
            id: 249,
            $version: 1
          },
          {
            name: "Médina de Rabat",
            id: 245,
            $version: 2
          },
          {
            name: "Rabat",
            id: 1624,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1792,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2108,
            $version: null
          },
          {
            name: "Autre",
            id: 2275,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Rabat",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SAFI",
            id: 47,
            $version: null
          },
          {
            name: "Safi",
            id: 174,
            $version: null
          }
        ],
        displayName: "Safi",
        artmin: null,
        createdOn: "2020-09-29T17:50:43.683Z",
        inseeCode: null,
        id: 50,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "M'dina K'dima",
            id: 1087,
            $version: 1
          },
          {
            name: "Miftah el kheir",
            id: 1088,
            $version: 1
          },
          {
            name: "Mouna",
            id: 1089,
            $version: 1
          },
          {
            name: "Nahda",
            id: 1090,
            $version: 1
          },
          {
            name: "Ourida 1 et 2",
            id: 1091,
            $version: 1
          },
          {
            name: "Plateau",
            id: 1092,
            $version: 1
          },
          {
            name: "Rhat A Rih",
            id: 1093,
            $version: 1
          },
          {
            name: "Saâida 1 et 2",
            id: 1099,
            $version: 1
          },
          {
            name: "Safi 1",
            id: 1094,
            $version: 1
          },
          {
            name: "Safi 2",
            id: 1095,
            $version: 1
          },
          {
            name: "Safi 3",
            id: 1096,
            $version: 1
          },
          {
            name: "Safiya",
            id: 1097,
            $version: 1
          },
          {
            name: "Sania",
            id: 1098,
            $version: 1
          },
          {
            name: "Sidi AbdelKrim",
            id: 1100,
            $version: 1
          },
          {
            name: "Sidi Bouzid",
            id: 1101,
            $version: 1
          },
          {
            name: "Swinia",
            id: 1102,
            $version: 1
          },
          {
            name: "Trab Assini",
            id: 1103,
            $version: 1
          },
          {
            name: "Ville Nouvelle",
            id: 1048,
            $version: 1
          },
          {
            name: "Wad El Bacha",
            id: 1104,
            $version: 1
          },
          {
            name: "Zawiyat Sidi Wassel",
            id: 1105,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1496,
            $version: 1
          },
          {
            name: "Achbar",
            id: 1049,
            $version: 1
          },
          {
            name: "Azib Derai",
            id: 1050,
            $version: 1
          },
          {
            name: "Bahia",
            id: 1051,
            $version: 1
          },
          {
            name: "Biar",
            id: 1052,
            $version: 1
          },
          {
            name: "Ahl Agadir",
            id: 1106,
            $version: 1
          },
          {
            name: "Hay essalam",
            id: 1070,
            $version: 1
          },
          {
            name: "Hay Sinaai",
            id: 1069,
            $version: 1
          },
          {
            name: "Hrayat Albayde",
            id: 1071,
            $version: 1
          },
          {
            name: "Ijennane",
            id: 1072,
            $version: 1
          },
          {
            name: "Jawhara",
            id: 1073,
            $version: 1
          },
          {
            name: "Jerifat",
            id: 1074,
            $version: 1
          },
          {
            name: "Jnane Chkouri",
            id: 1075,
            $version: 1
          },
          {
            name: "Jnane Colonne 1 et 2",
            id: 1076,
            $version: 1
          },
          {
            name: "Jnane El Mestari",
            id: 1077,
            $version: 1
          },
          {
            name: "Jnane Illane",
            id: 1078,
            $version: 1
          },
          {
            name: "Jnane Zitoune",
            id: 1079,
            $version: 1
          },
          {
            name: "Karyat ech-chams",
            id: 1080,
            $version: 1
          },
          {
            name: "Kawki",
            id: 1081,
            $version: 1
          },
          {
            name: "Koudia al Baida",
            id: 1082,
            $version: 1
          },
          {
            name: "LABC",
            id: 1083,
            $version: 1
          },
          {
            name: "Lamiaa",
            id: 1084,
            $version: 1
          },
          {
            name: "Les courses",
            id: 1085,
            $version: 1
          },
          {
            name: "Lkliaa",
            id: 1086,
            $version: 1
          },
          {
            name: "Biyada",
            id: 1053,
            $version: 1
          },
          {
            name: "Bled Eljed",
            id: 1054,
            $version: 1
          },
          {
            name: "Chenguite",
            id: 1055,
            $version: 1
          },
          {
            name: "Darb Lfarrane",
            id: 1056,
            $version: 1
          },
          {
            name: "Darb Moulay Hassan",
            id: 1057,
            $version: 1
          },
          {
            name: "Driba Lamziwka",
            id: 1058,
            $version: 1
          },
          {
            name: "El Massira",
            id: 1059,
            $version: 1
          },
          {
            name: "El Massira Boukalli",
            id: 1060,
            $version: 1
          },
          {
            name: "El Matar",
            id: 1061,
            $version: 1
          },
          {
            name: "Hay Anas",
            id: 1062,
            $version: 1
          },
          {
            name: "Hay El Bouwab",
            id: 1063,
            $version: 1
          },
          {
            name: "Hay El Majd",
            id: 1064,
            $version: 1
          },
          {
            name: "Hay Essaada",
            id: 1065,
            $version: 1
          },
          {
            name: "Hay Oued El Bacha",
            id: 1066,
            $version: 1
          },
          {
            name: "Hay Riyad",
            id: 1067,
            $version: 1
          },
          {
            name: "Hay Sanyat Zine El Abidine",
            id: 1068,
            $version: 1
          },
          {
            name: "Lalla Hnia Hamria",
            id: 1047,
            $version: 1
          },
          {
            name: "Safi",
            id: 1615,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1834,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2009,
            $version: null
          },
          {
            name: "Autre",
            id: 2176,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Safi",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Sala Al Jadida",
            id: 141,
            $version: null
          }
        ],
        displayName: "Sala Al Jadida",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 11,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1462,
            $version: 1
          },
          {
            name: "Sala Al Jadida",
            id: 800,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1800,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2116,
            $version: null
          },
          {
            name: "Autre",
            id: 2283,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Sala Al Jadida",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Salé",
            id: 229,
            $version: null
          }
        ],
        displayName: "Salé",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 4,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1455,
            $version: 1
          },
          {
            name: "Sala el Jadida",
            id: 280,
            $version: 2
          },
          {
            name: "Zone Industrielle Hay Arrahma",
            id: 309,
            $version: 2
          },
          {
            name: "Technopolis",
            id: 281,
            $version: 2
          },
          {
            name: "Sidi Hajji",
            id: 271,
            $version: 2
          },
          {
            name: "Mkinssia",
            id: 269,
            $version: 2
          },
          {
            name: "Ouled Moussa",
            id: 270,
            $version: 2
          },
          {
            name: "Sakani",
            id: 286,
            $version: 2
          },
          {
            name: "Sidi Abdellah",
            id: 287,
            $version: 2
          },
          {
            name: "Zone Industrielle Saniat Sbih",
            id: 272,
            $version: 2
          },
          {
            name: "Omar",
            id: 305,
            $version: 2
          },
          {
            name: "Pépinière",
            id: 306,
            $version: 2
          },
          {
            name: "Roustal",
            id: 307,
            $version: 2
          },
          {
            name: "Tabriquet",
            id: 308,
            $version: 2
          },
          {
            name: "Al Mouahidine",
            id: 289,
            $version: 1
          },
          {
            name: "Al Moukataa",
            id: 290,
            $version: 1
          },
          {
            name: "Andalouss",
            id: 291,
            $version: 1
          },
          {
            name: "Annaim",
            id: 292,
            $version: 1
          },
          {
            name: "Bab Al Bahr",
            id: 263,
            $version: 1
          },
          {
            name: "Bab Sebta",
            id: 264,
            $version: 1
          },
          {
            name: "Bettana",
            id: 273,
            $version: 1
          },
          {
            name: "Bled El Gharbi",
            id: 293,
            $version: 1
          },
          {
            name: "Cherkaoui - Marzouka",
            id: 265,
            $version: 1
          },
          {
            name: "Cité Militaire",
            id: 283,
            $version: 1
          },
          {
            name: "Dar El Lhamra",
            id: 294,
            $version: 1
          },
          {
            name: "Dyour El ghaba",
            id: 295,
            $version: 1
          },
          {
            name: "Dyour Nejjar",
            id: 296,
            $version: 1
          },
          {
            name: "El Qaria",
            id: 279,
            $version: 1
          },
          {
            name: "Frougui",
            id: 297,
            $version: 1
          },
          {
            name: "Ghrablia",
            id: 266,
            $version: 1
          },
          {
            name: "Hay Alqods",
            id: 298,
            $version: 1
          },
          {
            name: "Hay Cheikh Lamfadel",
            id: 299,
            $version: 1
          },
          {
            name: "Hay Chmaou",
            id: 267,
            $version: 1
          },
          {
            name: "Hay Inbiat",
            id: 284,
            $version: 1
          },
          {
            name: "Hay Karima",
            id: 300,
            $version: 1
          },
          {
            name: "Hay Moulay Ismail",
            id: 274,
            $version: 1
          },
          {
            name: "Abouab Sala",
            id: 282,
            $version: 2
          },
          {
            name: "Al Bassatine",
            id: 288,
            $version: 2
          },
          {
            name: "Hay Oued Dahab",
            id: 285,
            $version: 1
          },
          {
            name: "Hay Rahma",
            id: 301,
            $version: 1
          },
          {
            name: "Hay Salam",
            id: 275,
            $version: 1
          },
          {
            name: "Horria",
            id: 302,
            $version: 1
          },
          {
            name: "Inbiaat",
            id: 303,
            $version: 1
          },
          {
            name: "Kharouba",
            id: 276,
            $version: 1
          },
          {
            name: "Lakhmis",
            id: 277,
            $version: 1
          },
          {
            name: "Lalla Aicha",
            id: 304,
            $version: 1
          },
          {
            name: "Lharya",
            id: 278,
            $version: 1
          },
          {
            name: "Medina",
            id: 268,
            $version: 1
          },
          {
            name: "Salé",
            id: 1600,
            $version: 2
          },
          {
            name: "salé",
            id: 1950,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1793,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2109,
            $version: null
          },
          {
            name: "Autre",
            id: 2276,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Salé",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SBAA AYOUN",
            id: 28,
            $version: null
          },
          {
            name: "Sbaa ayoune",
            id: 152,
            $version: null
          }
        ],
        displayName: "Sbaa ayoune",
        artmin: null,
        createdOn: "2020-10-19T13:10:24.944Z",
        inseeCode: null,
        id: 83,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sbaa ayoune",
            id: 1182,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1527,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2039,
            $version: null
          },
          {
            name: "Aksal",
            id: 1864,
            $version: 1
          },
          {
            name: "Autre",
            id: 2206,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sbaa ayoune",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SBET GZOULA",
            id: 48,
            $version: null
          },
          {
            name: "Sebt Gzoula",
            id: 175,
            $version: null
          }
        ],
        displayName: "Sebt Gzoula",
        artmin: null,
        createdOn: "2020-09-30T17:22:32.541Z",
        inseeCode: null,
        id: 55,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1500,
            $version: 1
          },
          {
            name: "Sebt Gzoula",
            id: 1142,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1838,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2013,
            $version: null
          },
          {
            name: "Autre",
            id: 2180,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sebt Gzoula",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SEFROU FES",
            id: 77,
            $version: null
          },
          {
            name: "Sefrou",
            id: 217,
            $version: null
          }
        ],
        displayName: "Sefrou",
        artmin: null,
        createdOn: "2020-10-19T13:23:07.089Z",
        inseeCode: null,
        id: 91,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sefrou",
            id: 1201,
            $version: 5
          },
          {
            name: "electroplanet",
            id: 1535,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1872,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2050,
            $version: null
          },
          {
            name: "Autre",
            id: 2217,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sefrou",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Sekoura",
            id: 218,
            $version: null
          }
        ],
        displayName: "Sekoura",
        artmin: null,
        createdOn: "2020-12-08T14:27:54.776Z",
        inseeCode: null,
        id: 113,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sekoura",
            id: 1223,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1557,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1892,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2062,
            $version: null
          },
          {
            name: "Autre",
            id: 2229,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sekoura",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SETTAT",
            id: 59,
            $version: null
          },
          {
            name: "Settat",
            id: 187,
            $version: null
          }
        ],
        displayName: "Settat",
        artmin: null,
        createdOn: "2020-09-30T17:34:55.740Z",
        inseeCode: null,
        id: 69,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1514,
            $version: 1
          },
          {
            name: "Ahbas",
            id: 1156,
            $version: 1
          },
          {
            name: "Alouane",
            id: 1157,
            $version: 1
          },
          {
            name: "Hay Al Kamal",
            id: 1158,
            $version: 1
          },
          {
            name: "Hay Kassam",
            id: 1159,
            $version: 1
          },
          {
            name: "Hay Kilz",
            id: 1160,
            $version: 1
          },
          {
            name: "Hay Mabrouka",
            id: 1161,
            $version: 1
          },
          {
            name: "Hay Mimouna",
            id: 1162,
            $version: 1
          },
          {
            name: "Hay salam",
            id: 1163,
            $version: 1
          },
          {
            name: "Hensali",
            id: 1164,
            $version: 1
          },
          {
            name: "Mania",
            id: 1165,
            $version: 1
          },
          {
            name: "Sidi Abdelkarim",
            id: 1166,
            $version: 1
          },
          {
            name: "Smaâla",
            id: 1167,
            $version: 1
          },
          {
            name: "Tahiri",
            id: 1168,
            $version: 1
          },
          {
            name: "Tanmiya",
            id: 1169,
            $version: 1
          },
          {
            name: "Settat",
            id: 1617,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1852,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2027,
            $version: null
          },
          {
            name: "Autre",
            id: 2194,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Settat",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SFIRJLA",
            id: 49,
            $version: null
          },
          {
            name: "Sfirjla",
            id: 176,
            $version: null
          }
        ],
        displayName: "Sfirjla",
        artmin: null,
        createdOn: "2020-09-29T16:18:17.813Z",
        inseeCode: null,
        id: 38,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1488,
            $version: 1
          },
          {
            name: "Sfirjla",
            id: 1009,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1826,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2002,
            $version: null
          },
          {
            name: "Autre",
            id: 2169,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sfirjla",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Sidi Bibi",
            id: 123,
            $version: null
          }
        ],
        displayName: "Sidi Bibi",
        artmin: null,
        createdOn: "2021-08-13T12:53:00.635Z",
        inseeCode: null,
        id: 200,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sidi Bibi",
            id: 1955,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1956,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1974,
            $version: null
          },
          {
            name: "Autre",
            id: 2141,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Sidi Bibi",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SIDI HAJJAJ",
            id: 56,
            $version: null
          },
          {
            name: "Sidi Hajjaj",
            id: 184,
            $version: null
          }
        ],
        displayName: "Sidi Hajjaj",
        artmin: null,
        createdOn: "2020-09-30T17:37:02.838Z",
        inseeCode: null,
        id: 73,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1517,
            $version: 1
          },
          {
            name: "Sidi Hajjaj",
            id: 1172,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1855,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2030,
            $version: null
          },
          {
            name: "Autre",
            id: 2197,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sidi Hajjaj",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SIDI HARAZEM",
            id: 82,
            $version: null
          },
          {
            name: "Sidi Harazem",
            id: 230,
            $version: null
          }
        ],
        displayName: "Sidi Harazem",
        artmin: null,
        createdOn: "2020-10-19T13:25:21.057Z",
        inseeCode: null,
        id: 95,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sidi Harazem",
            id: 1205,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1541,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1878,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2094,
            $version: null
          },
          {
            name: "Autre",
            id: 2261,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sidi Harazem",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SIDI KACEM kenitra",
            id: 43,
            $version: null
          },
          {
            name: "Sidi Kacem",
            id: 170,
            $version: null
          }
        ],
        displayName: "Sidi Kacem",
        artmin: null,
        createdOn: "2020-10-17T13:49:10.888Z",
        inseeCode: null,
        id: 76,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sidi Kacem",
            id: 1175,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1520,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1858,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2033,
            $version: null
          },
          {
            name: "Autre",
            id: 2200,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sidi Kacem",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SIDI LAIDI",
            id: 29,
            $version: null
          },
          {
            name: "Sidi Laidi",
            id: 153,
            $version: null
          }
        ],
        displayName: "Sidi Laidi",
        artmin: null,
        createdOn: "2020-09-30T17:36:32.932Z",
        inseeCode: null,
        id: 71,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1515,
            $version: 1
          },
          {
            name: "Sidi Laidi",
            id: 1170,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1853,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2028,
            $version: null
          },
          {
            name: "Autre",
            id: 2195,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sidi Laidi",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Sidi rahal",
            id: 154,
            $version: null
          }
        ],
        displayName: "Sidi rahal",
        artmin: null,
        createdOn: "2020-10-12T16:20:21.690Z",
        inseeCode: null,
        id: 75,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sidi rahal",
            id: 1174,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1519,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1857,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2032,
            $version: null
          },
          {
            name: "Autre",
            id: 2199,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "meryem",
          fullName: "Meryem Taibi",
          id: 103,
          $version: 26
        },
        hasZipOnRight: false,
        name: "Sidi rahal",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SIDI SLIMANE",
            id: 57,
            $version: null
          },
          {
            name: "Sidi slimane",
            id: 185,
            $version: null
          }
        ],
        displayName: "Sidi slimane",
        artmin: null,
        createdOn: "2020-10-17T13:50:13.096Z",
        inseeCode: null,
        id: 77,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sidi slimane",
            id: 1176,
            $version: 2
          },
          {
            name: "electroplanet",
            id: 1521,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1859,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2034,
            $version: null
          },
          {
            name: "Autre",
            id: 2201,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Sidi slimane",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Sid L'Mokhtar",
            id: 257,
            $version: null
          }
        ],
        displayName: "Sid L'Mokhtar",
        artmin: null,
        createdOn: "2021-03-09T10:10:35.277Z",
        inseeCode: null,
        id: 170,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Sid L'Mokhtar",
            id: 1292,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1585,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1920,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2088,
            $version: null
          },
          {
            name: "Autre",
            id: 2255,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Sid L'Mokhtar",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Skhirat",
            id: 124,
            $version: null
          }
        ],
        displayName: "Skhirat",
        artmin: null,
        createdOn: "2020-09-29T16:09:47.465Z",
        inseeCode: null,
        id: 32,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1482,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1820,
            $version: 1
          },
          {
            name: "Skhirat",
            id: 1610,
            $version: 1
          },
          {
            name: "Ain El Hayat",
            id: 984,
            $version: 2
          },
          {
            name: "Ain Errouz",
            id: 982,
            $version: 2
          },
          {
            name: "Ain Laatris",
            id: 983,
            $version: 2
          },
          {
            name: "Al Fath",
            id: 985,
            $version: 2
          },
          {
            name: "Belkhir",
            id: 986,
            $version: 2
          },
          {
            name: "Imarat",
            id: 987,
            $version: 2
          },
          {
            name: "Kasbah",
            id: 981,
            $version: 2
          },
          {
            name: "Plage rose marae",
            id: 988,
            $version: 2
          },
          {
            name: "Marwa",
            id: 1997,
            $version: null
          },
          {
            name: "Autre",
            id: 2164,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Skhirat",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SOUIHLA",
            id: 79,
            $version: null
          },
          {
            name: "Souihla",
            id: 224,
            $version: null
          }
        ],
        displayName: "Souihla",
        artmin: null,
        createdOn: "2021-03-09T09:55:24.029Z",
        inseeCode: null,
        id: 154,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Souihla",
            id: 1276,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1572,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1907,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2076,
            $version: null
          },
          {
            name: "Autre",
            id: 2243,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Souihla",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Souk laarbaa",
            id: 129,
            $version: null
          }
        ],
        displayName: "Souk laarbaa",
        artmin: null,
        createdOn: "2021-06-29T17:53:26.967Z",
        inseeCode: null,
        id: 192,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "CENTRE VILLE",
            id: 1942,
            $version: 0
          },
          {
            name: "Marwa",
            id: 2124,
            $version: null
          },
          {
            name: "Autre",
            id: 2291,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Souk laarbaa",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "SOUK SEBT",
            id: 50,
            $version: null
          },
          {
            name: "Souk Sebt Ouled Nemma",
            id: 177,
            $version: null
          }
        ],
        displayName: "Souk Sebt Ouled Nemma",
        artmin: null,
        createdOn: "2020-09-29T16:24:44.438Z",
        inseeCode: null,
        id: 44,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1491,
            $version: 1
          },
          {
            name: "Dranha",
            id: 1028,
            $version: 1
          },
          {
            name: "El Haddada",
            id: 1030,
            $version: 1
          },
          {
            name: "Hassania",
            id: 1027,
            $version: 1
          },
          {
            name: "Hay Elmandour",
            id: 1033,
            $version: 1
          },
          {
            name: "Hay El Yassmine",
            id: 1026,
            $version: 1
          },
          {
            name: "Hay Enahda",
            id: 1029,
            $version: 1
          },
          {
            name: "Hay Essalam",
            id: 1032,
            $version: 1
          },
          {
            name: "Hay Nakhla",
            id: 1031,
            $version: 1
          },
          {
            name: "Khalouta",
            id: 1035,
            $version: 1
          },
          {
            name: "Pam",
            id: 1034,
            $version: 1
          },
          {
            name: "Rouajeh",
            id: 1025,
            $version: 1
          },
          {
            name: "Souk Sebt",
            id: 1036,
            $version: 1
          },
          {
            name: "Souk Sebt Ouled Nemma",
            id: 1614,
            $version: 0
          },
          {
            name: "Aksal",
            id: 1829,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2004,
            $version: null
          },
          {
            name: "Autre",
            id: 2171,
            $version: null
          }
        ],
        updatedBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        shippingMethod: "LAD",
        active: true,
        updatedOn: "2020-09-29T16:30:30.972Z",
        deliveryAvailability: true,
        version: 1,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Souk Sebt Ouled Nemma",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Taghazout",
            id: 105,
            $version: null
          }
        ],
        displayName: "Taghazout",
        artmin: null,
        createdOn: "2021-08-13T12:54:22.893Z",
        inseeCode: null,
        id: 203,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Taghazout",
            id: 1961,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1962,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1977,
            $version: null
          },
          {
            name: "Autre",
            id: 2144,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Taghazout",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TAHLA",
            id: 83,
            $version: null
          },
          {
            name: "Tahala",
            id: 231,
            $version: null
          }
        ],
        displayName: "Tahala",
        artmin: null,
        createdOn: "2020-10-19T13:26:12.244Z",
        inseeCode: null,
        id: 97,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tahala",
            id: 1207,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1543,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1880,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2096,
            $version: null
          },
          {
            name: "Autre",
            id: 2263,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Tahala",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Tahannaout",
            id: 232,
            $version: null
          }
        ],
        displayName: "Tahannaout",
        artmin: null,
        createdOn: "2021-03-09T10:03:52.873Z",
        inseeCode: null,
        id: 161,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tahannaout",
            id: 1283,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1578,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1913,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2082,
            $version: null
          },
          {
            name: "Autre",
            id: 2249,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Tahannaout",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TALMEST",
            id: 58,
            $version: null
          },
          {
            name: "Talmest",
            id: 186,
            $version: null
          }
        ],
        displayName: "Talmest",
        artmin: null,
        createdOn: "2020-09-30T17:29:44.880Z",
        inseeCode: null,
        id: 65,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1510,
            $version: 1
          },
          {
            name: "Talmest",
            id: 1152,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1848,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2023,
            $version: null
          },
          {
            name: "Autre",
            id: 2190,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Talmest",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Tamansourt (Inactive)",
            id: 220,
            $version: null
          }
        ],
        displayName: "Tamansourt (Inactive)",
        artmin: null,
        createdOn: "2021-03-09T09:57:58.170Z",
        inseeCode: null,
        id: 155,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tamansourt",
            id: 1277,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1573,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1908,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2077,
            $version: null
          },
          {
            name: "Autre",
            id: 2244,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Tamansourt (Inactive)",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TAMARIS",
            id: 6,
            $version: null
          },
          {
            name: "Tamaris",
            id: 101,
            $version: null
          }
        ],
        displayName: "Tamaris",
        artmin: null,
        createdOn: "2021-07-19T10:33:19.065Z",
        inseeCode: null,
        id: 195,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tamaris",
            id: 1945,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1968,
            $version: null
          },
          {
            name: "Autre",
            id: 2135,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Tamaris",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Tamesluht",
            id: 189,
            $version: null
          }
        ],
        displayName: "Tamesluht",
        artmin: null,
        createdOn: "2021-03-09T09:58:48.135Z",
        inseeCode: null,
        id: 156,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tamesluht",
            id: 1278,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1574,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1909,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2078,
            $version: null
          },
          {
            name: "Autre",
            id: 2245,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Tamesluht",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Tamesna",
            id: 225,
            $version: null
          }
        ],
        displayName: "Tamesna",
        artmin: null,
        createdOn: "2021-02-02T09:40:40.559Z",
        inseeCode: null,
        id: 144,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tamesna",
            id: 1265,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1562,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1897,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2066,
            $version: null
          },
          {
            name: "Autre",
            id: 2233,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Tamesna",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Tanger",
            id: 125,
            $version: null
          }
        ],
        displayName: "Tanger",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 18,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Cap spartel",
            id: 513,
            $version: 0
          },
          {
            name: "Ibn Taymia",
            id: 517,
            $version: 0
          },
          {
            name: "Marchane",
            id: 522,
            $version: 0
          },
          {
            name: "Val Fleuri",
            id: 527,
            $version: 0
          },
          {
            name: "Al Warda",
            id: 535,
            $version: 0
          },
          {
            name: "Beni Said",
            id: 541,
            $version: 0
          },
          {
            name: "Bouhout 2",
            id: 548,
            $version: 0
          },
          {
            name: "El Khair 2",
            id: 554,
            $version: 0
          },
          {
            name: "El Mers 1",
            id: 555,
            $version: 0
          },
          {
            name: "Les Rosiers",
            id: 565,
            $version: 0
          },
          {
            name: "Alia",
            id: 570,
            $version: 0
          },
          {
            name: "Sania",
            id: 579,
            $version: 0
          },
          {
            name: "Bel Air - Val fleuri",
            id: 584,
            $version: 0
          },
          {
            name: "Du Golf",
            id: 604,
            $version: 0
          },
          {
            name: "Marchan",
            id: 609,
            $version: 0
          },
          {
            name: "Msala",
            id: 615,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1469,
            $version: 1
          },
          {
            name: "Ahlane",
            id: 532,
            $version: 1
          },
          {
            name: "Al Kasaba",
            id: 511,
            $version: 0
          },
          {
            name: "Aviation",
            id: 512,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 514,
            $version: 0
          },
          {
            name: "Cité californie",
            id: 515,
            $version: 0
          },
          {
            name: "Girari",
            id: 516,
            $version: 0
          },
          {
            name: "M'nar",
            id: 518,
            $version: 0
          },
          {
            name: "M'sallah",
            id: 519,
            $version: 0
          },
          {
            name: "Makhoukha",
            id: 520,
            $version: 0
          },
          {
            name: "Malabata",
            id: 521,
            $version: 0
          },
          {
            name: "Achennad",
            id: 530,
            $version: 3
          },
          {
            name: "Marjane",
            id: 523,
            $version: 0
          },
          {
            name: "Moujahidine",
            id: 524,
            $version: 0
          },
          {
            name: "Moulay Youssef",
            id: 525,
            $version: 0
          },
          {
            name: "Zouitina",
            id: 567,
            $version: 0
          },
          {
            name: "Al Mandar Al Jamil",
            id: 569,
            $version: 0
          },
          {
            name: "Benkirane",
            id: 571,
            $version: 0
          },
          {
            name: "Charf",
            id: 572,
            $version: 0
          },
          {
            name: "Draoua",
            id: 573,
            $version: 0
          },
          {
            name: "Drissia",
            id: 574,
            $version: 0
          },
          {
            name: "El Majd",
            id: 575,
            $version: 0
          },
          {
            name: "El Oued",
            id: 576,
            $version: 0
          },
          {
            name: "Mghogha",
            id: 577,
            $version: 0
          },
          {
            name: "Nzaha",
            id: 578,
            $version: 0
          },
          {
            name: "Tanger City Center",
            id: 580,
            $version: 0
          },
          {
            name: "Tanja Balia",
            id: 581,
            $version: 0
          },
          {
            name: "Zone Industrielle Mghogha",
            id: 582,
            $version: 0
          },
          {
            name: "Azib Haj Kaddour",
            id: 583,
            $version: 0
          },
          {
            name: "Bir Chairi",
            id: 585,
            $version: 0
          },
          {
            name: "Branes 1",
            id: 586,
            $version: 0
          },
          {
            name: "Branes 2",
            id: 587,
            $version: 0
          },
          {
            name: "Casabarata",
            id: 588,
            $version: 0
          },
          {
            name: "Castilla",
            id: 589,
            $version: 0
          },
          {
            name: "Hay Al Bassatine",
            id: 590,
            $version: 0
          },
          {
            name: "Hay El Boughaz",
            id: 591,
            $version: 0
          },
          {
            name: "Hay Zaoudia",
            id: 592,
            $version: 0
          },
          {
            name: "Lalla Chafia",
            id: 593,
            $version: 0
          },
          {
            name: "Souani",
            id: 594,
            $version: 0
          },
          {
            name: "Administratif",
            id: 596,
            $version: 0
          },
          {
            name: "Ahammar",
            id: 597,
            $version: 0
          },
          {
            name: "Ain El Hayani",
            id: 598,
            $version: 0
          },
          {
            name: "Algerie",
            id: 599,
            $version: 0
          },
          {
            name: "Branes Kdima",
            id: 600,
            $version: 0
          },
          {
            name: "Californie",
            id: 601,
            $version: 0
          },
          {
            name: "Centre",
            id: 602,
            $version: 0
          },
          {
            name: "De La Plage",
            id: 603,
            $version: 0
          },
          {
            name: "Hay Hassani",
            id: 605,
            $version: 0
          },
          {
            name: "Iberie",
            id: 606,
            $version: 0
          },
          {
            name: "Jbel Kbir",
            id: 607,
            $version: 0
          },
          {
            name: "Laaouina",
            id: 608,
            $version: 0
          },
          {
            name: "Mediouna",
            id: 610,
            $version: 0
          },
          {
            name: "Mesnana",
            id: 611,
            $version: 0
          },
          {
            name: "Mghayer",
            id: 612,
            $version: 0
          },
          {
            name: "Mister Khouch",
            id: 613,
            $version: 0
          },
          {
            name: "Mozart",
            id: 614,
            $version: 0
          },
          {
            name: "Médina",
            id: 616,
            $version: 0
          },
          {
            name: "Port Tanger ville",
            id: 617,
            $version: 0
          },
          {
            name: "Rmilat",
            id: 618,
            $version: 0
          },
          {
            name: "Star Hill",
            id: 619,
            $version: 0
          },
          {
            name: "Manar",
            id: 620,
            $version: 0
          },
          {
            name: "Santa",
            id: 526,
            $version: 0
          },
          {
            name: "Vieille montagne",
            id: 528,
            $version: 0
          },
          {
            name: "Ziatene",
            id: 529,
            $version: 0
          },
          {
            name: "Aharrarine",
            id: 531,
            $version: 0
          },
          {
            name: "Aida",
            id: 533,
            $version: 0
          },
          {
            name: "Al Anbar",
            id: 534,
            $version: 0
          },
          {
            name: "Aouama Gharbia",
            id: 536,
            $version: 0
          },
          {
            name: "Beausejour",
            id: 537,
            $version: 0
          },
          {
            name: "Behair",
            id: 538,
            $version: 0
          },
          {
            name: "Ben Dibane",
            id: 539,
            $version: 0
          },
          {
            name: "Beni Makada Lakdima",
            id: 540,
            $version: 0
          },
          {
            name: "Beni Touzine",
            id: 542,
            $version: 0
          },
          {
            name: "Bir Aharchoune",
            id: 543,
            $version: 0
          },
          {
            name: "Bir Chifa",
            id: 544,
            $version: 0
          },
          {
            name: "Bir El Ghazi",
            id: 545,
            $version: 0
          },
          {
            name: "Bouchta-Abdelatif",
            id: 546,
            $version: 0
          },
          {
            name: "Bouhout 1",
            id: 547,
            $version: 0
          },
          {
            name: "Dher Ahjjam",
            id: 549,
            $version: 0
          },
          {
            name: "Dher Lahmam",
            id: 550,
            $version: 0
          },
          {
            name: "El Baraka",
            id: 551,
            $version: 0
          },
          {
            name: "El Haj El Mokhtar",
            id: 552,
            $version: 0
          },
          {
            name: "El Khair 1",
            id: 553,
            $version: 0
          },
          {
            name: "El Mers 2",
            id: 556,
            $version: 0
          },
          {
            name: "El Mrabet",
            id: 557,
            $version: 0
          },
          {
            name: "Ennasr",
            id: 558,
            $version: 0
          },
          {
            name: "Gourziana",
            id: 559,
            $version: 0
          },
          {
            name: "Haddad",
            id: 560,
            $version: 0
          },
          {
            name: "Hanaa 1",
            id: 561,
            $version: 0
          },
          {
            name: "Hanaa 2",
            id: 562,
            $version: 0
          },
          {
            name: "Hanaa 3 - Soussi",
            id: 563,
            $version: 0
          },
          {
            name: "Jirrari",
            id: 564,
            $version: 0
          },
          {
            name: "Zemmouri",
            id: 566,
            $version: 0
          },
          {
            name: "Al Amal",
            id: 568,
            $version: 1
          },
          {
            name: "Tanger",
            id: 1603,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1807,
            $version: 1
          },
          {
            name: "Achakar",
            id: 595,
            $version: 2
          },
          {
            name: "Marwa",
            id: 1984,
            $version: null
          },
          {
            name: "Autre",
            id: 2151,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Tanger",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TANTAN",
            id: 91,
            $version: null
          },
          {
            name: "Tan Tan",
            id: 247,
            $version: null
          }
        ],
        displayName: "Tan Tan",
        artmin: null,
        createdOn: "2021-03-16T15:32:29.197Z",
        inseeCode: null,
        id: 178,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1592,
            $version: 2
          },
          {
            name: "Tan Tan",
            id: 1622,
            $version: 1
          },
          {
            name: "Centre ville",
            id: 1300,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1927,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2101,
            $version: null
          },
          {
            name: "Autre",
            id: 2268,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Tan Tan",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TAOUNATE",
            id: 84,
            $version: null
          },
          {
            name: "Taounate",
            id: 233,
            $version: null
          }
        ],
        displayName: "Taounate",
        artmin: null,
        createdOn: "2020-10-19T13:26:54.777Z",
        inseeCode: null,
        id: 98,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Taounate",
            id: 1208,
            $version: 4
          },
          {
            name: "electroplanet",
            id: 1544,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1881,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2107,
            $version: null
          },
          {
            name: "Autre",
            id: 2274,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Taounate",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Taourirt",
            id: 258,
            $version: null
          }
        ],
        displayName: "Taourirt",
        artmin: null,
        createdOn: "2021-06-29T17:49:33.966Z",
        archived: false,
        inseeCode: null,
        id: 190,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "CENTRE VILLE",
            id: 1940,
            $version: 0
          },
          {
            name: "Marwa",
            id: 2133,
            $version: null
          },
          {
            name: "Autre",
            id: 2300,
            $version: null
          }
        ],
        updatedBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        shippingMethod: "LAD",
        active: true,
        updatedOn: "2021-09-17T08:56:08.796Z",
        deliveryAvailability: true,
        version: 2,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Taourirt",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Taroudant",
            id: 126,
            $version: null
          }
        ],
        displayName: "Taroudant",
        artmin: null,
        createdOn: "2021-08-13T12:54:48.299Z",
        inseeCode: null,
        id: 204,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Taroudant",
            id: 1963,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1964,
            $version: 5
          },
          {
            name: "Marwa",
            id: 1978,
            $version: null
          },
          {
            name: "Autre",
            id: 2145,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Taroudant",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Tassoultante",
            id: 226,
            $version: null
          }
        ],
        displayName: "Tassoultante",
        artmin: null,
        createdOn: "2021-03-09T09:54:01.697Z",
        inseeCode: null,
        id: 153,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "ٍSidi Moussa",
            id: 1275,
            $version: 1
          },
          {
            name: "Tassoultante",
            id: 1274,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1571,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1906,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2075,
            $version: null
          },
          {
            name: "Autre",
            id: 2242,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Tassoultante",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TAZA",
            id: 78,
            $version: null
          },
          {
            name: "Taza",
            id: 219,
            $version: null
          }
        ],
        displayName: "Taza",
        artmin: null,
        createdOn: "2020-10-19T13:24:53.714Z",
        inseeCode: null,
        id: 94,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Taza",
            id: 1204,
            $version: 2
          },
          {
            name: "electroplanet",
            id: 1538,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1875,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2040,
            $version: null
          },
          {
            name: "Autre",
            id: 2207,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Taza",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Temara",
            id: 248,
            $version: null
          }
        ],
        displayName: "Temara",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 5,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1456,
            $version: 1
          },
          {
            name: "Oulad Ogba",
            id: 357,
            $version: 1
          },
          {
            name: "Riad Oulad Mtaa",
            id: 317,
            $version: 2
          },
          {
            name: "Skikina",
            id: 318,
            $version: 1
          },
          {
            name: "Talaa",
            id: 319,
            $version: 1
          },
          {
            name: "Temara Plage",
            id: 320,
            $version: 1
          },
          {
            name: "Wifak",
            id: 321,
            $version: 2
          },
          {
            name: "Al Moustakbal",
            id: 325,
            $version: 2
          },
          {
            name: "Andalousse",
            id: 326,
            $version: 2
          },
          {
            name: "Centre Ville",
            id: 312,
            $version: 2
          },
          {
            name: "Cigali Cigalon",
            id: 327,
            $version: 2
          },
          {
            name: "Abbadi",
            id: 310,
            $version: 2
          },
          {
            name: "Oued Eddahab",
            id: 356,
            $version: 1
          },
          {
            name: "Sable d'or",
            id: 358,
            $version: 1
          },
          {
            name: "Sidi Mohamed Cherif",
            id: 359,
            $version: 1
          },
          {
            name: "Val d'or",
            id: 360,
            $version: 1
          },
          {
            name: "Wufac Erac",
            id: 361,
            $version: 1
          },
          {
            name: "Abbadi Braika",
            id: 323,
            $version: 2
          },
          {
            name: "Derd Bennaceur",
            id: 334,
            $version: 1
          },
          {
            name: "Douar si lamine",
            id: 335,
            $version: 1
          },
          {
            name: "Fadlallah",
            id: 336,
            $version: 1
          },
          {
            name: "Fadli",
            id: 337,
            $version: 1
          },
          {
            name: "Fath el Kheir",
            id: 338,
            $version: 1
          },
          {
            name: "Firdaouss",
            id: 339,
            $version: 1
          },
          {
            name: "Harhoura",
            id: 313,
            $version: 1
          },
          {
            name: "Hirafiyines",
            id: 340,
            $version: 1
          },
          {
            name: "Hoda",
            id: 341,
            $version: 1
          },
          {
            name: "Ittihad Arabi",
            id: 342,
            $version: 1
          },
          {
            name: "Khalota",
            id: 343,
            $version: 1
          },
          {
            name: "Lamnassir",
            id: 344,
            $version: 1
          },
          {
            name: "Lazrak",
            id: 345,
            $version: 1
          },
          {
            name: "Lazrek Nemsia",
            id: 346,
            $version: 1
          },
          {
            name: "Madwaz",
            id: 347,
            $version: 1
          },
          {
            name: "Maghreb Arabi",
            id: 314,
            $version: 1
          },
          {
            name: "Masrou 1",
            id: 348,
            $version: 1
          },
          {
            name: "Massira",
            id: 315,
            $version: 1
          },
          {
            name: "Massira 1",
            id: 349,
            $version: 1
          },
          {
            name: "Massira 2",
            id: 350,
            $version: 1
          },
          {
            name: "Massira 3",
            id: 351,
            $version: 1
          },
          {
            name: "Comatrav",
            id: 328,
            $version: 2
          },
          {
            name: "Contrebandier",
            id: 329,
            $version: 2
          },
          {
            name: "Derb Askar",
            id: 330,
            $version: 2
          },
          {
            name: "Derb Belmekki",
            id: 331,
            $version: 2
          },
          {
            name: "Derb Jdid",
            id: 332,
            $version: 2
          },
          {
            name: "Derb Sahraoua",
            id: 333,
            $version: 2
          },
          {
            name: "Messrour 2",
            id: 352,
            $version: 1
          },
          {
            name: "Mkitaa",
            id: 353,
            $version: 1
          },
          {
            name: "Nahda-1",
            id: 354,
            $version: 1
          },
          {
            name: "Nahda-2",
            id: 355,
            $version: 1
          },
          {
            name: "Nemsia",
            id: 316,
            $version: 1
          },
          {
            name: "Ain Atig",
            id: 311,
            $version: 3
          },
          {
            name: "Abi Horaira",
            id: 324,
            $version: 4
          },
          {
            name: "Temara",
            id: 1627,
            $version: 0
          },
          {
            name: "Aksal",
            id: 1794,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2110,
            $version: null
          },
          {
            name: "Autre",
            id: 2277,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Temara",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Temsia",
            id: 127,
            $version: null
          }
        ],
        displayName: "Temsia",
        artmin: null,
        createdOn: "2021-08-13T12:55:09.494Z",
        inseeCode: null,
        id: 205,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Temsia",
            id: 1965,
            $version: 0
          },
          {
            name: "Centre ville",
            id: 1966,
            $version: 0
          },
          {
            name: "Marwa",
            id: 1979,
            $version: null
          },
          {
            name: "Autre",
            id: 2146,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        attrs: null,
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Temsia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Tetouan",
            id: 128,
            $version: null
          }
        ],
        displayName: "Tetouan",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 22,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "El Ensanche",
            id: 704,
            $version: 1
          },
          {
            name: "Ichara",
            id: 709,
            $version: 1
          },
          {
            name: "Sidi Talha",
            id: 693,
            $version: 1
          },
          {
            name: "Touabel",
            id: 723,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1473,
            $version: 1
          },
          {
            name: "Slaoui",
            id: 694,
            $version: 1
          },
          {
            name: "Taboula",
            id: 720,
            $version: 1
          },
          {
            name: "Talaa",
            id: 721,
            $version: 1
          },
          {
            name: "Tamouda",
            id: 722,
            $version: 1
          },
          {
            name: "Torrota",
            id: 695,
            $version: 1
          },
          {
            name: "Touilaa",
            id: 696,
            $version: 1
          },
          {
            name: "Touilaa Foqia",
            id: 724,
            $version: 1
          },
          {
            name: "Trankat",
            id: 725,
            $version: 1
          },
          {
            name: "Wilaya",
            id: 726,
            $version: 1
          },
          {
            name: "Ziana",
            id: 699,
            $version: 1
          },
          {
            name: "Al Azhar",
            id: 684,
            $version: 1
          },
          {
            name: "Bled",
            id: 700,
            $version: 1
          },
          {
            name: "Borj",
            id: 701,
            $version: 1
          },
          {
            name: "Centre ville",
            id: 685,
            $version: 1
          },
          {
            name: "Dersa",
            id: 686,
            $version: 1
          },
          {
            name: "Dyor del Makhzen",
            id: 703,
            $version: 1
          },
          {
            name: "El Matar",
            id: 705,
            $version: 1
          },
          {
            name: "El Mdrassi",
            id: 706,
            $version: 1
          },
          {
            name: "Ennakata",
            id: 707,
            $version: 1
          },
          {
            name: "Essania",
            id: 708,
            $version: 1
          },
          {
            name: "Inara",
            id: 710,
            $version: 1
          },
          {
            name: "Korat Essba",
            id: 711,
            $version: 1
          },
          {
            name: "Kouilma",
            id: 687,
            $version: 1
          },
          {
            name: "Laayoune",
            id: 712,
            $version: 1
          },
          {
            name: "Laouziyine",
            id: 713,
            $version: 1
          },
          {
            name: "Masdae",
            id: 714,
            $version: 1
          },
          {
            name: "Medina",
            id: 688,
            $version: 1
          },
          {
            name: "Mellah",
            id: 715,
            $version: 1
          },
          {
            name: "Mhannech",
            id: 689,
            $version: 1
          },
          {
            name: "Mounia",
            id: 716,
            $version: 1
          },
          {
            name: "Msalla",
            id: 717,
            $version: 1
          },
          {
            name: "Rbat Aala",
            id: 718,
            $version: 1
          },
          {
            name: "Rbat Asfaa",
            id: 719,
            $version: 1
          },
          {
            name: "Route de Ceuta",
            id: 698,
            $version: 1
          },
          {
            name: "Safir",
            id: 690,
            $version: 1
          },
          {
            name: "Saniat Rmal",
            id: 691,
            $version: 1
          },
          {
            name: "Sidi Al Mandri",
            id: 692,
            $version: 1
          },
          {
            name: "Boujdade",
            id: 702,
            $version: 2
          },
          {
            name: "Tetouan",
            id: 1606,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1811,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1988,
            $version: null
          },
          {
            name: "Autre",
            id: 2155,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "Tetouan",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TIFELT",
            id: 44,
            $version: null
          },
          {
            name: "Tifelt",
            id: 171,
            $version: null
          }
        ],
        displayName: "Tifelt",
        artmin: null,
        createdOn: "2020-10-19T13:09:38.422Z",
        inseeCode: null,
        id: 81,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tifelt",
            id: 1180,
            $version: 1
          },
          {
            name: "electroplanet",
            id: 1525,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2038,
            $version: null
          },
          {
            name: "Aksal",
            id: 1863,
            $version: 1
          },
          {
            name: "Autre",
            id: 2205,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Tifelt",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TISSA",
            id: 60,
            $version: null
          },
          {
            name: "Tissa",
            id: 188,
            $version: null
          }
        ],
        displayName: "Tissa",
        artmin: null,
        createdOn: "2020-10-19T13:27:21.198Z",
        inseeCode: null,
        id: 99,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "Tissa",
            id: 1209,
            $version: 4
          },
          {
            name: "electroplanet",
            id: 1545,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1882,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2063,
            $version: null
          },
          {
            name: "Autre",
            id: 2230,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Tissa",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TITMELLIL",
            id: 9,
            $version: null
          },
          {
            name: "TiTMellil",
            id: 106,
            $version: null
          }
        ],
        displayName: "TiTMellil",
        artmin: null,
        createdOn: null,
        inseeCode: null,
        id: 16,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1467,
            $version: 1
          },
          {
            name: "Al Madina Aljadida",
            id: 978,
            $version: 2
          },
          {
            name: "TiTMellil",
            id: 805,
            $version: 2
          },
          {
            name: "Aksal",
            id: 1805,
            $version: 1
          },
          {
            name: "Marwa",
            id: 1982,
            $version: null
          },
          {
            name: "Autre",
            id: 2149,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: null,
        hasZipOnRight: false,
        name: "TiTMellil",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TLAT BOUGDRA",
            id: 51,
            $version: null
          },
          {
            name: "Tlat Bougedra",
            id: 178,
            $version: null
          }
        ],
        displayName: "Tlat Bougedra",
        artmin: null,
        createdOn: "2020-09-30T17:29:05.311Z",
        inseeCode: null,
        id: 63,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1508,
            $version: 1
          },
          {
            name: "Tlat Bougedra",
            id: 1150,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1846,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2021,
            $version: null
          },
          {
            name: "Autre",
            id: 2188,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Tlat Bougedra",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "TNIN LGHYAT",
            id: 52,
            $version: null
          },
          {
            name: "Tnin Laghyat",
            id: 179,
            $version: null
          }
        ],
        displayName: "Tnin Laghyat",
        artmin: null,
        createdOn: "2020-09-30T17:22:09.900Z",
        inseeCode: null,
        id: 54,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1499,
            $version: 1
          },
          {
            name: "Tnin Laghyat",
            id: 1141,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1837,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2012,
            $version: null
          },
          {
            name: "Autre",
            id: 2179,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Tnin Laghyat",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "YOUSSOUFIA",
            id: 53,
            $version: null
          },
          {
            name: "Youssoufia",
            id: 180,
            $version: null
          }
        ],
        displayName: "Youssoufia",
        artmin: null,
        createdOn: "2020-09-30T17:26:55.073Z",
        inseeCode: null,
        id: 60,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "electroplanet",
            id: 1505,
            $version: 1
          },
          {
            name: "Youssoufia",
            id: 1147,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1843,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2018,
            $version: null
          },
          {
            name: "Autre",
            id: 2185,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "ayoubelabassi",
          fullName: "Ayoub EL ABASSI",
          id: 331,
          $version: 15
        },
        hasZipOnRight: false,
        name: "Youssoufia",
        pickUpAvailability: true,
        canton: null
      },
      {
        country: null,
        importOrigin: null,
        nbInhCommune: 0,
        aliases: [
          {
            name: "Zaouiat ahensal",
            id: 234,
            $version: null
          }
        ],
        displayName: "Zaouiat ahensal",
        artmin: null,
        createdOn: "2021-03-16T15:36:55.893Z",
        inseeCode: null,
        id: 183,
        department: null,
        selected: false,
        zip: null,
        sectors: [
          {
            name: "zaouiat ahensal",
            id: 1305,
            $version: 0
          },
          {
            name: "electroplanet",
            id: 1597,
            $version: 1
          },
          {
            name: "Aksal",
            id: 1932,
            $version: 1
          },
          {
            name: "Marwa",
            id: 2106,
            $version: null
          },
          {
            name: "Autre",
            id: 2273,
            $version: null
          }
        ],
        updatedBy: null,
        shippingMethod: "LAD",
        active: true,
        updatedOn: null,
        deliveryAvailability: true,
        version: 0,
        
        importId: null,
        createdBy: {
          code: "Mkrimou",
          fullName: "Mouad Krimou",
          id: 1017,
          $version: 10
        },
        hasZipOnRight: false,
        name: "Zaouiat ahensal",
        pickUpAvailability: true,
        canton: null
      }
    ]
  }