import styled from "styled-components"

export const Header = styled.div`
width: 100%;
padding: 1rem;
`;

export const Directory = styled.div`
width: 100%;
padding: 1rem;
`;

export const Card = styled.div`
width: 100%;
display: flex;
align-items:center;
`;