import { Container, Title, GoalCard, Directory } from "./our-goal.styles";
import {MdHealthAndSafety} from "react-icons/md"
import {GiFarmTractor, GiFoodTruck, GiSolarTime} from "react-icons/gi"

const Goal = () => {
    return (
        <Container>
            <Title>أهدافنا </Title>
            <Directory>
                <GoalCard background="#007145" color="#fff">
                    <MdHealthAndSafety size="60px" style={{marginBottom: "1rem"}}/>
                    إدخال المورينغا إلى كل منزل إيماناً منّا بفوائدها المميزة للانسان والبيئة .
                    </GoalCard>
                <GoalCard background="#2f5a7c" color="#fff">
                <GiFarmTractor size="60px" style={{marginBottom: "1rem"}}/>
                     إثراء القطاع الزراعي التقليدي بمنتجات نباتية جديدة تشكل قيمة مضافة للمنتجات التقليدية
                     </GoalCard>
                <GoalCard background="#e89515" color="#fff">
                <GiFoodTruck size="60px" style={{marginBottom: "1rem"}}/>
                     تعزيز المنتجات ذات الفائدة الغذائية الكبيرة المتاحة للمستهلك محلياً
                     </GoalCard>
                <GoalCard background="#0aa0af" color="#fff">
                <GiSolarTime size="60px" />
                    العمل على تعزيز استخدام المواد الطبيعية والمنتجات المحلية الطبيعية في ابتكار منتجات مشتقة لخدمة حاجات المستهلك الأخرى المتعلقة بالصحة والجمال.</GoalCard>
            </Directory>
        </Container>
    )
}

export default Goal;