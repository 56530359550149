import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
align-self:stretch;
justify-content:flex-start;
@media ${props => props.theme.devices.mobileXm}{
    width: 100%;
    padding: 1rem;
}
@media ${props => props.theme.devices.tablet}{
    width: 70%;
    padding: 1rem 1rem 1rem 0;
}

`;

export const PaymentMethod = styled.div`
@media ${props => props.theme.devices.mobileXm}{
    width: 100%;
}
@media ${props => props.theme.devices.tablet}{
    width: 70%;
}
`;
export const Title = styled.h1`
font-size: 1.5rem;
margin-bottom: 1rem;
`;
export const Directory = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items:center;
`;

export const Method = styled.div`
display: flex;
flex-direction: row;
align-items:center;
h3{
    margin-right: .6rem;
}

`;

export const FormDetails = styled.div`
position: relative;
margin-top: 2rem;
@media ${props => props.theme.devices.mobileXm}{
    width: 100%;
}
@media ${props => props.theme.devices.tablet}{
    width: 90%;
}

`;

export const AdrDiv = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items:center;
justify-content:space-between;
`;

export const InputGroup = styled.div`
width: ${props => props.addr ? "48%" : "100%"};
padding-bottom:.5rem;
margin-bottom:2rem;
position: relative;
&::before{
    content :"";
    width: 100%;
    height: .7px;
    background: red;
    position: absolute;
    right:0;
    left:0;
    bottom:0;
    background: ${props => props.error ? "red" : "rgba(0, 0, 0, .1)"};
}
${props => props.error && `
&::after{
    position: absolute;
    content : "مطلوب";
    top: 50%;
    transform: translateY(-50%);
    color: red;
    left:0;
    font-size: 1rem;
    font-weight: 300;
}
`}
`

export const Input = styled.input`
width: 100%;
height: 100%;
border: ${props => props.error ? "1px solid red" : "0"};
outline: none;
padding: .5rem 0;
position: relative;
font-size: 1rem;
font-family: 'Cairo', sans-serif;
&::placeholder{
    font-size: 1rem;
    font-family: 'Cairo', sans-serif;
    font-weight: 300;
}

`;

export const BtnGroup = styled.div`
width: 100%;
display: flex;
align-items:center;
justify-content:space-between;
@media ${props => props.theme.devices.mobileXm}{
    flex-direction: column-reverse;
}
@media ${props => props.theme.devices.tablet}{
    flex-direction: row-reverse;
}
`;
export const Button = styled.button`
padding: .5rem 4rem;
background: ${props => props.background};
color: ${props => props.color};
cursor: pointer;
font-family: 'Cairo', sans-serif;
font-size: 1rem;
@media ${props => props.theme.devices.mobileXm}{
    margin-bottom: 1rem;
    border: 0;
}
@media ${props => props.theme.devices.tablet}{
    margin-bottom: 0;
    border: ${props => props.border ? props.border : "0"};
   
}
`;
export const CustomRadio = styled.label`
display: block;
position: relative;
padding-left: 35px;
margin-bottom: 12px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
& > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    &:after{
        content: "";
        position: absolute;
        display: none;
    }
  }
 &:hover input ~ .checkmark  {
    background-color: red;
 } 
 & > input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid #000;
  }
  & >input:checked ~ .checkmark:after {
    display: block;
  }
  & > .checkmark:after{
    left: 50%;
    transform: translate(-50%, -50%);
    top:50%;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: black;
  }
  
`;

export const Select = styled.select`
width: 100%;
height: 100%;
border: ${props => props.error ? "1px solid red" : "0"};
outline: none;
padding: .5rem 0;
position: relative;
font-size: 1rem;
font-family: 'Cairo', sans-serif;
&::placeholder{
    font-size: 1rem;
    font-family: 'Cairo', sans-serif;
    font-weight: 300;
}
`;