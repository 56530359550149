import { Container, Title, Directory, Item } from "./gallery.styles";
import ItemGallery from "../item-gallery/item-gallery.component";
import GalleryMobile from "../gallery-mobile/gallery-mobile.component";
const Gallery = () => {
    return (
        <Container>
            <Title>صور من المزرعة</Title>
            <Directory >
                <ItemGallery area="a" 
                backImg="./assets/gallery/1.webp"
                />
                <ItemGallery area="a"
                backImg="./assets/gallery/2.webp"
                />
                 <ItemGallery area="b"
                backImg="./assets/gallery/3.webp"
                />
                <ItemGallery area="c"
                backImg="./assets/gallery/4.webp"
                />
                <ItemGallery area="d"
                    backImg= "./assets/gallery/5.webp"
                />
                <ItemGallery area="e"
                 backImg="./assets/gallery/1.webp"
                />
                <ItemGallery area="e"
                 backImg="./assets/gallery/6.webp"
                />
                <ItemGallery area="e"
                 backImg="./assets/gallery/8.webp"
                />
            </Directory>
            <GalleryMobile />
        </Container>
    )
}

export default Gallery;