import {useNavigate, useParams} from 'react-router-dom'
import React, {useContext, useState} from "react"
import styled from 'styled-components'
import DATA from '../../data';
import CheckoutForm from '../../components/checkout-form/checkout-form.component';
import CheckoutImg from '../../components/checkout-img/checkout-img.component';
import { CartContext } from '../../context/cart';
import {Helmet} from "react-helmet"



const CheckoutSingleProduct  = () => {
    const {addItem} = useContext(CartContext);
    const [product, setProduct] = useState({})
    const {id} = useParams();
    const navigate = useNavigate();
    React.useEffect(() => {
        const prod = DATA.find(product => product.id == Number(id))

        if(prod){
            addItem(prod)
            setProduct(prod)
        }else{
            navigate('/')
        }

    }, [id])
    
    


    return (
        <Container>
            {
                product && <Helmet>
                <meta charSet="utf-8" />
                <title>{product.title}</title>
                <link rel="icon" href={product.icon} />
            </Helmet>
            }
            <CheckoutForm />
            <CheckoutImg cartItems={product}/>

        </Container>

    )
}
export default CheckoutSingleProduct;
export const Container = styled.div`
width: 100%;
display: flex;
align-items:center;
@media ${props => props.theme.devices.mobileXm}{
    flex-direction: column;
    min-height: auto;
}
@media ${props => props.theme.devices.tablet}{
    flex-direction: row;
    min-height: 100vh;
}
`;