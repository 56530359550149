import styled from "styled-components"

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items:center;
margin-bottom:.5rem;
`;

export const Content = styled.div`
flex: 1;
align-self:stretch;
padding-right: 1rem;

`;
export const ProductTitle = styled.h3`
font-weight: 600;
margin-bottom: 1.2rem;
${props => props.small && `
font-size: .8rem;
margin-bottom:0;
`};
`;

export const Quantity = styled.div`
display: flex;
flex-direction: row;
align-items:center;
span{
    padding: 0 1rem;
    font-size: 1.2rem;
    user-select: none;
}

`;
export const Operation = styled.div`
width: 25px;
height: 25px;
${props => props.small && `
width: 18px;
height: 18px;
`
};
background: lightgray;
border-radius: 50%;
display: flex;
flex-direction: row;
align-items:center;
justify-content:center;
cursor: pointer;
`;
export const Delete = styled.div`
display: flex;
flex-direction: row;
align-items:center;
justify-content:flex-start;
margin-top: .5rem;
cursor: pointer;
p{
    margin-right: .2rem;
}
`;