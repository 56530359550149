import styled from "styled-components";
import { keyframes } from "styled-components";

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction:column;
background-position: center;
background-size:cover;
color: #fff;
@media ${props => props.theme.devices.mobileXm}{
align-items:center;
justify-content:center;
height: auto;
padding: 1.5rem 0;
}
@media ${props => props.theme.devices.tablet}{
    justify-content:center;
    align-items:center;
    height: 28rem;
    
    }
`;

export const RightSide = styled.div`

background-size:cover;
@media ${props => props.theme.devices.mobileXm}{
background-position: top;
width: 100%;
height: 25rem;
 background-image: url(./assets/products/product1.png)
}
@media ${props => props.theme.devices.mobileM}{
    background-position: top;
    width: 100%;
    
    }
@media ${props => props.theme.devices.tablet}{
    background-position: center;
    width: 40%;
    align-self:stretch;
    height: 35rem;
    }
`;
export const LeftSide = styled.div`
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
color: #fff;
text-align:center;
background: ${props => props.theme.colors.primary};
@media ${props => props.theme.devices.mobileXm}{
width: 100%;
padding: 1rem 0;
}
@media ${props => props.theme.devices.tablet}{
    width: 60%;
    align-self:stretch;
    }
`;
export const Heading = styled.h1`
font-size: 2.2rem;
margin-bottom: 1.4rem;
line-height: 50px;
text-align:center;
@media ${props => props.theme.devices.mobileXm}{
    width: 90%;
}
@media ${props => props.theme.devices.tablet}{
    width: 100%;
}
`;
export const Subheading = styled.p`
font-weight: light;
font-size: 1.2rem;
margin-bottom: 1rem;
line-height: 30px;
text-align:center;
@media ${props => props.theme.devices.mobileXm}{
    width: 100%;

}
@media ${props => props.theme.devices.tablet}{
    
    width: 70%;
}
`;

export const ShopButton = styled.button`
background: #fff;
color: #000;
padding: .4rem 4rem;
border-radius: 20px;
font-family: 'Cairo', sans-serif;
border: 0;
font-size: 1.2rem;
margin-top: 1rem;
cursor: pointer;
position: relative;
z-index: 1;
&::after{
    content: "";
    border-radius: 20px;
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all .7s;
    background: white;
    z-index: -1;
}
&:hover::after{
 transform: scale(1.2);
 opacity: 0;
}
`;