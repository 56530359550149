import React from "react"
import styled from "styled-components"
import {IoMdClose} from "react-icons/io"
import Countdown from "react-countdown"
import moment from "moment"

const Completionist = () => <span>You are good to go!</span>;

const start = new Date()
start.setHours(0, 0, 0, 0);
console.log(start);

const end = new Date();
const endOfDay = end.setHours(23, 59, 59, 999);
console.log(end);

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return <div style={{display: "flex", flexDirection:'column',alignItems:'center'}}>
        <span style={{
            paddingLeft: '2rem',
            fontSize:'1rem'
        }}>
        العرض سينتهي بعد
        </span>
        <span style={{
        fontSize: '2rem'
    }}>{` ${hours} ساعات ${minutes} دقيقة  ${seconds} ثانية`} </span>

        </div>;
  }
};

const OffrePopup = ({open, setOpen}) => {
    return (
        <Container >
            <Wrapper >
                <ImgContainer>
                <img src="/assets/products/product7.png" />
                </ImgContainer>
                <Details >
                    <Header>
                        <IoMdClose size="20px" cursor="pointer" onClick={() => setOpen(false)}/>
                    </Header>
                    <Title>⚡العرض الاستثنائي ديالنا قرب إسالي الليلة مع 00:00 🔥</Title>
                    <Paragraph>
                    عسل المورينغا الطبيعي 100٪ 250g فقط <strong>ب180 درهم</strong>.
                    </Paragraph>
                    <Paragraph>
                    شاي المورينغا 80g ب95 درهم. 
                    </Paragraph>
                    <Paragraph>
                    مسحوق المورينغا  200g  فقط 180 درهم. 
                    </Paragraph>

                    <Countdown date={endOfDay} renderer={renderer} />
                </Details>
            </Wrapper>
        </Container>

    )
}

export default OffrePopup

const Container = styled.div`
width: 100%;
height: 100vh;
background: rgba(0, 0, 0, .5);
position: fixed;
top:0;
right: 0;
left:0;
bottom:0;
z-index: 20;
display: flex;
flex-direction: row;
align-items:center;
justify-content:center;

`;
const Wrapper = styled.div`
height: auto;
background: #fff;
display: flex;

@media ${props => props.theme.devices.mobileXm}{
    width: 80%;
    flex-direction: column;
}
@media ${props => props.theme.devices.tablet}{
    width: 80%;
    flex-direction: row;
}

`;

const ImgContainer = styled.div`
align-self:stretch;
& > img{
    height: 100%;
    width: 100%;
}
@media ${props => props.theme.devices.mobileXm}{
    display: none;
}
@media ${props => props.theme.devices.tablet}{
    width: 45%;
    display: flex;
    
}
`

export const Details = styled.div`
align-self:stretch;
padding: 1rem;
@media ${props => props.theme.devices.mobileXm}{
    width: 100%;
}
@media ${props => props.theme.devices.tablet}{
    width: 55%;
}
`;


const Title = styled.h1`
font-size: 1.3rem;
margin-bottom: 2rem;

`;
const Paragraph = styled.p`
margin: .7rem 0;
font-size: 1.2rem;
`;
const Header = styled.div`
width: 100%;
display: flex;
align-items:center;
justify-content:flex-end;
`;