import styled from "styled-components";

const GalleryMobile = () =>{
    return (
        <Container>
            <Item>
                <img src="/assets/products/product1.png" />
                                        </Item>
                                        <Item>
                <img src="/assets/products/product1.png" />
                                        </Item>
                                        <Item>
                <img src="/assets/products/product1.png" />
                                        </Item>

        </Container>

    )
}

export default GalleryMobile;

const Container = styled.div`
flex-direction: column;
align-items:center;
justify-content:center;
display: none;
`;
export const Item = styled.div`
position: relative;
cursor: pointer;
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
margin-bottom:1rem;

`;

const Details = styled.div`
z-index: 3;
`;
const Title = styled.h1`
font-size: 1rem;
`;