import styled from "styled-components"

export const Container = styled.div`

margin: 3rem auto;
display: flex;
@media ${props => props.theme.devices.mobileXm}{
    flex-direction: column;
    width: 100%;
    padding: 1rem;
}

@media ${props => props.theme.devices.tablet}{
    width: 80%;
    flex-direction: row;
}

`;
export const ImgContainer = styled.div`


display: flex;
align-items:center;
@media ${props => props.theme.devices.mobileXm}{
    width: 100%;
    height: 400px;
    
}
@media ${props => props.theme.devices.tablet}{
    width: 50%;
    height: 350px;
    
}
& > img{
   @media ${props => props.theme.devices.mobileXm}{
    height: 100%;
    width: 100%;
   }
   @media ${props => props.theme.devices.tablet}{
    height: 100%;
   
   }
}

`;
export const Content = styled.div`
width: 100%;
align-self:stretch;
padding-right: 2rem;

`;
export const Details = styled.div`
width: 100%;
& > p{
    margin-top: 1rem;
}
padding-bottom: 2rem;
border-bottom: 1px solid #e2e8f0;
`;
export const Title = styled.h1`
font-weight: bold;
font-size: 2rem;
`;
export const Button = styled.button`
padding: .5rem 4rem;
margin-top: 1rem;
background: ${props => props.theme.colors.primary};
color: white;
cursor: pointer;
font-family: 'Cairo', sans-serif;
font-size: 1rem;
@media ${props => props.theme.devices.mobileXm}{
    margin-bottom: 1rem;
    border: 0;
}
@media ${props => props.theme.devices.tablet}{
    margin-bottom: 0;
    border: ${props => props.border ? props.border : "0"};
   
}
`;

export const Price = styled.h1`
font-weight: bold;
margin: .6rem 0;
`;
export const Offre = styled.p`
padding: .7rem 0;
font-weight: bold;
`;
export const OffreWrapper = styled.div`

`;

export const OffreContainer = styled.div`
display: flex;
align-items:center;
margin-bottom: .5rem;
& > p{
    padding: 0;
    margin: 0;
}
`;

export const OrderWhatsApp = styled.div`
display: flex;
align-items:center;
margin-top: .8rem;
color: #008069;
cursor: pointer;
& >p{
    margin-right: .5rem;
}
`;