import { Container, Logo, Content , Text, List, ListItem} from "./who-we-are.styles";

const WhoWeAre = () => {
    return (
        <Container>
            <Logo>
                <img  src="/assets/logo.webp" />
            </Logo>
           <Content>
               <Text>
               مشروع مزرعة عضوية للمورينغا أول مزرعة على المستوى الوطني و التي تقع بالجنوب الشرقي للمملكة و التي يسهر عليها طاقم محترف من ذوي الإختصاص. 
               </Text>
               <Text>
               تحتوي المورينجا على العديد من العناصر الغذائية المهمة للجسم، الأمر الذي ينعكس إيجابًا على الصحة،من بين الفوائد :
               </Text>
               <List>
                   <ListItem>الوقاية من الإصابة بالسرطان</ListItem>
                   <ListItem>المساهمة في علاج الالتهابات</ListItem>
                   <ListItem>تخفّيض مستوى السكر في الدم</ListItem>
                   <ListItem>تخفّيض مستويات الكوليسترول، وحماية القلب</ListItem>
                   <ListItem>حماية وتغذية البشرة والشعر</ListItem>
                   <ListItem>المساهمة في المحافظة على صحة الدماغ</ListItem>
                   
               </List>
           </Content>

        </Container>
    )
}

export default WhoWeAre