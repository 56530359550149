import styled from "styled-components";

export const Container = styled.input`
width: 100%;
height: 100%;
border: ${props => props.error ? "1px solid red" : "0"};
outline: none;
padding: .5rem 0;
position: relative;
font-size: 1rem;
&::placeholder{
    font-size: 1rem;
    font-family: 'League Spartan', sans-serif;
    font-weight: 300;
}

`;