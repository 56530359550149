import { Container , Wrapper, StatsCard, StatsText} from "./stats.styles";
import {VscDebugStart} from "react-icons/vsc"
import {MdOutlineProductionQuantityLimits} from "react-icons/md"
import {BsTree} from "react-icons/bs"

const Stats = () => {
    return <Container >
        <Wrapper>
            <StatsCard>
                <VscDebugStart color="white" size="3.5rem"/>
                <StatsText>
                    <h3>بدايتنا </h3>
                    <h2>2015</h2>
                </StatsText>
            </StatsCard>
            <StatsCard>
                <BsTree color="white" size="3.5rem"/>
                <StatsText>
                    <h3>شجرة مورينغا </h3>
                    <h2>000 200 1</h2>
                </StatsText>
            </StatsCard>
            <StatsCard>
                <MdOutlineProductionQuantityLimits color="white" size="3.5rem"/>
                <StatsText>
                    <h3>منتج </h3>
                    <h2>12</h2>
                </StatsText>
            </StatsCard>
        </Wrapper>
    </Container>
}

export default Stats;