import {useNavigate, useParams} from 'react-router-dom'
import React, {useContext, useState} from "react"
import styled from 'styled-components'
import DATA from '../../data';
import CheckoutForm from '../../components/checkout-form/checkout-form.component';
import CheckoutImg from '../../components/checkout-img/checkout-img.component';
import { CartContext } from '../../context/cart';
import {Helmet} from "react-helmet"



const Checkout = () => {
    const {cartItems} = useContext(CartContext);
    
    


    return (
        <Container>
            <CheckoutForm />
            <CheckoutImg cartItems={cartItems}/>

        </Container>

    )
}
export default Checkout;
export const Container = styled.div`
width: 100%;
display: flex;
align-items:center;
@media ${props => props.theme.devices.mobileXm}{
    flex-direction: column;
    min-height: auto;
}
@media ${props => props.theme.devices.tablet}{
    flex-direction: row;
    min-height: 100vh;
}
`;