import { Container, LeftSide, RightSide, Heading, Subheading, ShopButton } from "./hero.styles";
import { useEffect, useState } from "react";
import {Link} from "react-scroll"

const Hero = () => {
    const [currentBack, setcurrentBack] = useState('url(./assets/products/product1.png)')

   var backs = ['url(./assets/products/product1.png)', 'url(./assets/products/product2.png)', 'url(./assets/products/product5.png), url(./assets/products/product6.png), url(./assets/products/product4.png)']
   var current = 0;
   const checkBack = () =>{
       current = Math.floor(Math.random()*5);
       setcurrentBack(backs[current])      
   }

   useEffect(() => {
   const  intervalId =  setInterval(() => {
        checkBack()
    }, 6000);
    return () => clearInterval(intervalId);
  }, []);
    return (
        <Container
        style={{
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(assets/bg-hero.jpg)'
        }}
        >
           <Heading>
           أول مزرعة عضوية متخصصة في زراعة أشجار المورينغا في المغرب منذ 2015
           </Heading>
           <Subheading>
           تعرف شجرة المورينغا بعدة أسماء مختلفة، مثل البان الزيتي، أو الشوع، أو شجرة الطبل، كما عرفت في أفريقيا باسم شجرة المعجزة، وذلك لأنهم استخدموها  أثناء أزمة الغذاء بسبب فوائدها   الطبية المتعددة وسرعة نموها، ويوجد الكثير من الأشجار التي تنتمي إلى عائلة المورينجا ولكن أشهرها شجرة المورينجا أوليفيرا.
           </Subheading>
           <Link
           activeClass="active"
           to="section3"
           spy={true}
           smooth={true}
           offset={-70}
           duration={500}
           >
           <ShopButton>اشتري الاَن</ShopButton>
           </Link>
           
        </Container>

    )
}

export default Hero;