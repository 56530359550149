import styled from "styled-components"

export const Container = styled.div`
width: 100%;
padding: .5rem 0;
`;
export const Wrapper = styled.div`
width: 80%;
margin: auto;
display: flex;
flex-direction: row;
align-items:center;
justify-content:space-between;
`;
export const Social = styled.div`
display: flex;
flex-direction: row;
align-items:center;
`;