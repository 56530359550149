import styled from "styled-components";

export const Container = styled.div`
align-self:stretch;
background: white;
border-right: 1px solid lightgray;;
color: #000;
padding: 1rem;
display: flex;
flex-direction: column;
align-self: stretch;
@media ${props => props.theme.devices.mobileXm}{
    width: 100%;
    height: auto;
}
@media ${props => props.theme.devices.tablet}{
    width: 40%;
    min-height: 100%;
    
}
`;
export const SectionTitle = styled.h1`
font-size: 1.5rem;
margin-bottom: 1rem;
`;
export const Directory = styled.div`
width: 100%;
flex: 1;
min-height: 300px;
overflow: auto;
`;
export const TotalToPay = styled.div`
width: 100%;
`;
export const Price = styled.div`
width: 100%;
display: flex;
align-items:center;
justify-content:space-between;
margin-bottom: .6rem;
`;