import React, {createContext, useState, useEffect} from "react";

export const CartContext = createContext({
    cartItems: [],
    addItem: () => null,
    cartPrice : 0,
    cartCount: 0,
    descreseItem : () => null,
    clearItem : () => null,
    clearAllItems : () => null,
    isProductExist: () => null

})

export const addItemToCart = (cartItems , productToAdd) => {
    console.log(cartItems)
    const existingProduct =   cartItems?.find(cart => cart.id == productToAdd.id)
    if(existingProduct){
        return cartItems.map(cart => {
            return cart.id === existingProduct.id ? {...cart, quantity: cart.quantity +1} : cart
        })
    }
    return [...cartItems, {...productToAdd, quantity: 1}]
    
}

export const descreaseItemToCart = (cartItems , productToDeacrese) => {
    const existingProduct = cartItems?.find(cart => cart.id == productToDeacrese.id)
    if(existingProduct && existingProduct.quantity >1 ){
        return cartItems.map(cart => {
            return cart.id === existingProduct.id ? {...cart, quantity: cart.quantity - 1} : cart
        })
    }
    return [...cartItems]
}



export const CartProvider = ({children}) => {
    const [cartItems, setCartItems] = useState([])
    const [cartPrice, setCartPrice] = useState(0)
    const [cartCount, setCartCount] = useState(0)

    useEffect(() => {
       if(Array.isArray(JSON.parse(localStorage.getItem('cartitems')))){
        if(localStorage.getItem('cartitems')){
            setCartItems(JSON.parse(localStorage.getItem('cartitems')))
        }
        if(localStorage.getItem('cartPrice')){
            setCartPrice(JSON.parse(localStorage.getItem('cartPrice')))
        }
        if(localStorage.getItem('cartCount')){
            setCartCount(JSON.parse(localStorage.getItem('cartCount')))
        }
       }else{
        localStorage.clear()
       }
    }, [])

    const updatePrice = (newCartItems) => {
        const newPrice = newCartItems.reduce((total, cartItem) => total + (cartItem.quantity * cartItem.price), 0)
        setCartPrice(newPrice)
        return newPrice;

    }
    const updateCount = (newCartItems) => {
        const newCount = newCartItems.reduce((total, cartItem) => total + (cartItem.quantity), 0)
        setCartCount(newCount)
        return newCount;

    }
    const clearAllItems = () => {
        setCartItems([])
        const  newPrice = updatePrice([])
        const newCount = updateCount([])
        localStorage.setItem('cartitems', JSON.stringify([]))
        localStorage.setItem('cartPrice', JSON.stringify(newPrice))
        localStorage.setItem('cartCount', JSON.stringify(newCount))

    }
     const clearItem = (productToDelete) => {
        const newCartItems = cartItems.filter(cart => cart.id != productToDelete.id)
        setCartItems(newCartItems)
        const  newPrice = updatePrice(newCartItems)
        const newCount = updateCount(newCartItems)
        localStorage.setItem('cartitems', JSON.stringify(newCartItems))
        localStorage.setItem('cartPrice', JSON.stringify(newPrice))
        localStorage.setItem('cartCount', JSON.stringify(newCount))
        
    }


    const addItem = (productToAdd) => {
        const newCartItems  = addItemToCart(cartItems , productToAdd);
        setCartItems(newCartItems)
        const  newPrice = updatePrice(newCartItems)
        const newCount = updateCount(newCartItems)
        localStorage.setItem('cartitems', JSON.stringify(newCartItems))
        localStorage.setItem('cartPrice', JSON.stringify(newPrice))
        localStorage.setItem('cartCount', JSON.stringify(newCount))
    }
    const descreseItem = (productToDeacrese) => {
        const newCartItems = descreaseItemToCart(cartItems, productToDeacrese)
        setCartItems(newCartItems)
        const newPrice = updatePrice(newCartItems)
        const newCount = updateCount(newCartItems)
        localStorage.setItem('cartitems', JSON.stringify(newCartItems))
        localStorage.setItem('cartPrice', JSON.stringify(newPrice))
        localStorage.setItem('cartCount', JSON.stringify(newCount))
    }

    const isProductExist = (productId) => {
        const existing = cartItems.find(cart => cart.id == productId)
        return existing ? existing  : false
    }
    const value = {cartItems, addItem, descreseItem,isProductExist,  cartPrice,clearAllItems,  clearItem,cartCount};
    return <CartContext.Provider value={value}>
        {children}
        </CartContext.Provider>

}