import styled from "styled-components"

export const Container = styled.div`
width: 100%;
padding: 1rem;
height: 100%;
overflow-y: auto;
`;
export const Title = styled.h2`
margin: 1rem 0;
color: #000;
text-align:center;
`;
export const List = styled.ul`
width: 100%;
margin: 0;
padding: 0;

`;
export const Item = styled.li`
width: 100%;
text-align:center;
list-style: none;
margin: 1rem 0;
`;
export const Header = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content:flex-end;
margin-bottom: 1rem;
cursor: pointer;
`;