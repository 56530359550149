
import styled from "styled-components";
import { useState } from "react";
import {BsInstagram} from "react-icons/bs"
import {AiOutlineEye} from "react-icons/ai"
import { Lightbox } from "react-modal-image";

const ItemGallery = ({area, backImg}) => {
    const [hover, setHover] = useState('')
    const [clicked, setClicked] = useState(false);
    const toggle = () => {
        setClicked(!clicked)
        setHover(false)
    }
    return <Item onClick={toggle} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} area={area} style={{
        backgroundImage: `url(${backImg})`
    }}>
       {
           hover && (
               <Details >
            <AiOutlineEye size="30px" color="white"/>
            </Details>
           )
       }

{
    clicked && <Lightbox
    small={backImg}
    large={backImg}
    onClose={toggle}
  />
}
    </Item>

}

export default ItemGallery;

export const Item = styled.div`
background-position: center;
background-size: cover;
position: relative;
cursor: pointer;
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
height: 250px;
&::after {
    content:"";
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    right: 0;
    left:0;
    bottom:0;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: all .5s;
}
&:hover::after{
    opacity: 1;
}
`;

const Details = styled.div`
z-index: 3;
`;
const Title = styled.h1`
font-size: 1rem;
`;