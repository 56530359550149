import React from "react";
import { useNavigate } from "react-router-dom";
import {Header, Directory} from './checkout.styles'
import {IoMdClose} from "react-icons/io"
import { useContext } from "react";
import { CartContext } from "../../context/cart";
import ProductCardCheckout from "../product-checkout-card/product-checkout-card.component";
import { Button } from "../checkout-form/checkout-form.styles";

const Checkout = ({close}) => {
    const {cartItems, cartCount} = useContext(CartContext)
    const navigate = useNavigate()
    return (
        <>
        <Header >
            <IoMdClose cursor="pointer" size="20px" color='black' onClick={close}/>
            <Directory>
                {
                   cartItems?.length >0 &&  cartItems?.map(cart => (
                        <ProductCardCheckout small key={cart.id} product={cart}/>
                    ))
                }

            </Directory>
           {
            cartCount > 0 ?  <Button type="button" background="#007145" color="white" marginLeft="2rem" onClick={() => navigate('/checkout')}>تاكيد</Button> : <p>السلة فارغة</p>
           }
        </Header>

        </>
    )
}

export default Checkout