import { Container, SectionTitle, Directory, TotalToPay, Price } from "./checkout-img.styles";
import ProductCardCheckout from "../product-checkout-card/product-checkout-card.component";
import { useContext } from "react";
import { CartContext } from "../../context/cart";

const CheckoutImg = ({cartItems}) => {
    const {cartPrice} = useContext(CartContext)
    return <Container>
      
            <SectionTitle>ملخص الطلب</SectionTitle>
            <Directory>
            {
                cartItems.length > 0 && cartItems.map(cart => (
                    <ProductCardCheckout key={cart.id} product={cart}/>
                ))
            }
            </Directory>
            <TotalToPay>
                
                <Price>
                    <span>المجموع</span>
                    <strong style={{fontSize: "1.5rem"}}>Dhs {cartPrice}</strong>
                </Price>
            </TotalToPay>
    
    </Container>
}

export default CheckoutImg;