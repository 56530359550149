import styled from "styled-components";

export const Container = styled.div`
padding: 4rem 0;
width: 80%;
margin: auto;
`;
export const Title = styled.h1`
font-size: 2.5rem;
margin-bottom:3rem;
`;
export const Directory = styled.div`
width: 100%;
margin: auto;
display: grid;
grid-column-gap: 1rem;
grid-row-gap: 1rem;
@media ${props => props.theme.devices.mobileXm}{
    padding: 1rem;
    grid-template-columns: repeat(1, 1fr);
}
@media ${props => props.theme.devices.tablet}{
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
}
`;
