import React, {useState, useEffect} from "react";

import Hero from "../../components/hero/hero.component"
import Gallery from "../../components/gallery/gallery.component"
import Footer from "../../components/footer/footer.component"
import LimitedEdition from "../../components/limited-edition/limited-edition.component"
import WhoWeAre from "../../components/who-we-are/who-we-are.component"
import Stats from "../../components/stats/stats.component"
import Goal from "../../components/our-goal/our-goal.component"
import Header from "../../components/header/header.component"
import OffrePopup from "../../components/offre-popup/offre-popup.component";

const  Homepage = () => {

    return (
        <>
                <Header />
    <Hero />
    <WhoWeAre />
    <LimitedEdition />
    <Stats />
    <Goal />
    <Gallery />

    
    </>
    )
}

export default Homepage;