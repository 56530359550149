import styled from "styled-components";

export const Container = styled.div`
width: 100%;
background: ${props => props.theme.colors.primary};
padding: 3rem 0;
margin: 2rem 0;
`;
export const Wrapper = styled.div`
width: 80%;
height: 100%;
margin: auto;
display: grid;
column-gap: 1.5rem;
row-gap: 1.5rem;
@media (max-width: 640px){
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media (min-width: 640px){
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media  (min-width: 900px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    
}
`;

export const StatsCard = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
`;
export const StatsText = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
color: #fff;
margin-top: .6rem;
h4{
    margin-top: .5rem;
}

`;