import logo from './logo.svg';
import './App.css';
import Homepage from './pages/Homepage/Homepage.component';
import CheckoutSingleProduct from './pages/checkout/Cechout';
import Checkout from './pages/checkout';
import SingleProduct from './pages/single-product/single-product.component';
import {Routes, Route} from "react-router-dom"


function App() {
  return (
   <Routes>
     <Route path='/' element={<Homepage />}/>
     <Route path='/checkout' element={<Checkout />}/>
     <Route path='/products/:id' element={<SingleProduct />}/>
   </Routes>
  );
}

export default App;
