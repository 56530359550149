import {Container, Wrapper, Social} from "./header.styles";
import {FiInstagram,} from "react-icons/fi"
import {FaFacebookF } from "react-icons/fa"
import {BiShoppingBag} from "react-icons/bi"
import {AiOutlineWhatsApp} from "react-icons/ai"
import {HiOutlineMail} from "react-icons/hi"
import {useContext} from "react"
import { CartContext } from "../../context/cart";
import Checkout from "../checkout/checkout.component";
import Drawer from 'react-modern-drawer'
import { useState } from "react";
import {Link} from "react-router-dom"
const Header = () => {
    const {cartCount} = useContext(CartContext)
    const [open, setOpen] = useState(false)
    return (
        <Container>
            <Wrapper>
            <Link to="/">
                <div
                style={{
                    display: "flex",
                    flexDirection: 'row',
                    alignItems:'center',
                    justifyContent:'center',
                    height: "5rem",
                    width: "5rem",
                    cursor:"pointer"
                }}
                >
                   
                    <img src="/assets/logo.webp" height="100%"/>
                  
                
                </div>
                </Link>
                <Social>
                    <div
                    style={{
                        position: "relative",
                        cursor: "pointer"
                    }}
                    onClick={() => setOpen(true)}
                    >
                        <div style={{width: "1.2rem", height: "1.2rem",fontSize: ".7rem", borderRadius: "50%", background: "red", position: "absolute", color: "white", display: 'flex', alignItems:"center", justifyContent:"center", bottom:"0", right: "-.5rem"}}>
                            {cartCount}
                        </div>
                    <BiShoppingBag size="1.8rem"/>
                    </div>
                    <a target="_blank" href="https://www.instagram.com/moringaherb.ma/">
                    <FiInstagram cursor="pointer" size="1.2rem" style={{marginRight: "1rem"}}/>
                    </a>
                    <a target="_blank" href="https://www.facebook.com/moringaherb.ma">
                    <FaFacebookF cursor="pointer" size="1.2rem" style={{marginRight: "1rem"}}/>
                    </a>
                   
                    <a href="mailto:contact@moringaherb.ma" target="_blank">
                    <HiOutlineMail cursor="pointer" size="1.2rem" style={{marginRight: "1rem"}}/>
                    </a>
                    
                   
                    <a href="https://api.whatsapp.com/send/?phone=212661801131" target="_blank">
                    <AiOutlineWhatsApp cursor="pointer" size="1.2rem" style={{marginRight: "1rem"}}/>
                    </a>
                    
                </Social>
            </Wrapper>
            <Drawer
                open={open}
                onClose={() => setOpen(!open)}
                direction='right'
                className='bla bla bla'
            >
                <Checkout close={() => setOpen(false)}/>
            </Drawer>

        </Container>
    )
}

export default Header;