import {Container, Content, ProductTitle, Quantity, Operation, Delete} from "./product-checkout-card.styles"
import {BsPlusLg} from "react-icons/bs"
import {FiMinus} from "react-icons/fi"
import {useState} from "react"
import { useContext } from "react"
import {CartContext} from "../../context/cart"
import {AiOutlineDelete} from "react-icons/ai"


const ProductCardCheckout = ({product, small}) => {
    const {addItem , descreseItem, clearItem, cartItems} = useContext(CartContext)
    const clearTheItem = () => {
        clearItem(product);
       
    }
    console.log("This is the product : ", product)
    return (
        <Container>
            
             {
                 product?.backImg && <img  src={product.backImg} width={small ? "60": "130"} height={small ? "60": "130"} />
             }
            
            <Content small={small}>
                <ProductTitle small={small}>{product.title}</ProductTitle>
                <Quantity>
                    <Operation small={small}>
                        <BsPlusLg color='black' size={small ? "9px": "13px"} onClick={() => addItem(product)}/>
                    </Operation>
                    <span>{product.quantity}</span>
                    {
                        product?.quantity > 1 ? <Operation small>
                        <FiMinus color='black' size={small ? "9px": "13px"}onClick={() => descreseItem(product)}/>
                    </Operation> : null
                    }
                </Quantity>
                <Delete onClick={() => clearTheItem(product)}>
                    <AiOutlineDelete size={small ? "17px": "23px"}/>
                    <p>ازالة</p>
                </Delete>

            </Content>

        </Container>
    )
}

export default ProductCardCheckout