import {Container, Title, Directory} from "./limited-edition.styles"
import LimitedEditionCard from "../limited-edition-card/limited-edition-card.component";
import DATA from "../../data"
import { Element } from "react-scroll";
import { ToastContainer } from "react-toastify";



const LimitedEdition = () => {
    return (
       <Element classID="section3" className="section3">
            <Container>
            <Title>منتجاتنا </Title>
            <Directory>
                {
                    DATA.map(product => (
                        <LimitedEditionCard key={product.id} product={product} />
                    ))
                }
                

            </Directory>
        </Container>
        <ToastContainer
position="top-center"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
       </Element>
    )
}
export default LimitedEdition;