import React, {useEffect, useState} from "react"
import { Container ,OffreWrapper,Offre,OrderWhatsApp,OffreContainer, ImgContainer, Content, Title, Button, Price, Details} from "./single-product.styles"
import { Quantity, Operation } from "../../components/product-checkout-card/product-checkout-card.styles"
import Header from "../../components/header/header.component"
import Footer from "../../components/footer/footer.component"
import {BsPlusLg} from "react-icons/bs"
import {FiMinus} from "react-icons/fi"
import { useContext } from "react"
import { CartContext } from "../../context/cart"
import {TbTruckDelivery} from "react-icons/tb"
import {BiSupport} from "react-icons/bi"
import data from "../../data"
import {useParams} from "react-router-dom"
import {useNavigate} from "react-router-dom"
import {FaWhatsappSquare} from "react-icons/fa"

const SingleProduct = ({small}) => {
    const {id} = useParams()
    const [product, setProduct] = useState('')
    const navigate = useNavigate()
    const {addItem, descreseItem, cartItems, isProductExist} = useContext(CartContext);
    useEffect(() => {
        const existing = data?.find(product => product.id == id);
        if(existing){
            setProduct(existing)
        }
    }, [id])

    return (
       <>
        <Header />
        <Container>
            <ImgContainer>
            {
                product && <img src={product.backImg} />
            }
            </ImgContainer>
            <Content>
                <Details>
                <Title>{product && product.title}</Title>
                <p>
                {product && product?.pros?.items?.map(item => (
                    <span key={item.id} >
                        {`${item.text} `} 
                    </span>
                ))}
                   
                </p>
                <Price>{product.price}dhs</Price>
                {
                    isProductExist(id) && <Quantity>
                    <Operation small={small}>
                        <BsPlusLg color='black' size={"13px"} onClick={() => addItem(product)}/>
                    </Operation>
                    <span>{isProductExist(id)?.quantity}</span>
                    <Operation small>
                        <FiMinus color='black' size={"14px"}onClick={() => descreseItem(product)}/>
                    </Operation> 
                </Quantity>
                }
               {
                 !isProductExist(id) &&  <Button onClick={() => addItem(product)}>أضف الى السلة</Button>
               }
               {
                isProductExist(id) &&  <Button onClick={() => navigate('/checkout')}>اشتري الآن </Button>
               }
               <a target="_blank" href="https://api.whatsapp.com/send/?phone=212661801131">
               <OrderWhatsApp>
                <FaWhatsappSquare color="#008069" size="26px"/>
                <p>اطلب عبر واتساب</p>
               </OrderWhatsApp>
               </a>
                </Details>
                <Offre>نوفر لكم</Offre>
               <OffreWrapper>
               <OffreContainer>
                    <TbTruckDelivery color="black" size="25px" style={{marginLeft: "10px"}}/>
                    <p>توصيل مجاني إلى كل المدن المغربية.</p>
                </OffreContainer>
                <OffreContainer>
                    <BiSupport color="black" size="25px" style={{marginLeft: "10px"}}/>
                    <p>دعم فني 7 ايام / 7 ايام.</p>
                </OffreContainer>
               </OffreWrapper>
            </Content>
        </Container>
     
       </>


    )
}

export default SingleProduct