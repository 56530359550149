import {Container, Title, List, Item, Header} from "./product-details.component.styles";
import {GrClose} from "react-icons/gr";

const ProductDetails = ({product, toggleDrawer}) => {
    return <Container >
        <Header><GrClose onClick={toggleDrawer} color="black"/></Header>
        <img src={product.backImg} width='200' height="200"/>
        <Title>{product.title}</Title>
        <List>
            {
                product.pros.items.map(item => (
                    <Item key={item.id}>{item.text}</Item>
                ))
            }
        </List>
    </Container>
}

export default ProductDetails;