export default [
    {
        id: 15,
        title: " عسل المورينغا",
        price: "180",
        backImg: "/assets/products/mieledi4.webp",
        icon: "/assets/products/mieledi4.ico",
        pros : {
            items : [
                {
                    id: 4,
                    text: "عسل المورينجا، عسل طبيعي وصافي 100% خال من أي مواد حافظة وصناعية"
                },
                {
                    id: 5,
                    text: "عسل المورينجا، عسل مُستخرج من نوارة زهور شجرة المورينجا البيضاء يأتيكم مباشرة من مزرعة النحل الطبيعية"
                },
                {
                    id: 6,
                    text: " يعتبر عسل المورينجا من أفضل أنواع العسل والذي لا يُسبب حساسية عند تناوله"
                },
                {
                    id: 7,
                    text: "عسل المورينجا واحدًا من أفضل العلاجات التي تمنع العديد من الأمراض الصحية"
                }
            ]
        }
    },
    {
        id: 13,
        title: "شاي المورينغا",
        price: "95",
        backImg: "/assets/products/product7.png",
        icon: "/assets/products/product1.ico",
        pros : {
            items : [
                {
                    id: 8,
                    text: "الوقاية من الإصابة بالسرطان."
                },
                {
                    id: 9,
                    text: "المساهمة في علاج الالتهابات"
                },
                {
                    id: 10,
                    text: "تخفّيض مستوى السكر في الدم."
                },
                {
                    id: 11,
                    text: "تخفّيض مستويات الكوليسترول، وحماية القلب."
                },
                {
                    id: 12,
                    text:"المساهمة في المحافظة على صحة الدماغ"
                }
            ]
        }
    },
    {
        id: 12,
        title: "مسحوق المورينغا",
        price: "180",
        backImg: "/assets/products/herb1editpodr.webp",
        icon: "assets/products/herb1editpodr.ico",
        pros : {
            items : [
                {
                    id: 13,
                    text: "يعزز القدرة على التحمل"
                },
                {
                    id: 14,
                    text:"مضاد لأكسدة"
                },
                {
                    id: 15,
                    text: "يخفض نسبة الكليستيرول  في الدم"
                },
                {
                    id: 16,
                    text: "يسيطر على ضغط الدم"
                },
                {
                    id: 17,
                    text: "جيدة للنساء المرضعات"
                },
                {
                    id: 18,
                    text: "يحفز نمو الشعر"
                }
            ]
        }
    }
]