import WooCommerceRestApi  from "@woocommerce/woocommerce-rest-api";
// import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api"; // Supports ESM

const WooCommerce = new WooCommerceRestApi({
  url: 'https://www.admin.moringaherb.ma',
  consumerKey: "ck_aa45bb94e507b2bbe5bb8e7d09007110b5a0dda8",
  consumerSecret: "cs_41cad9129b811a8eee012b20fabbd1dd17e62934",
  version: 'wc/v3',
  queryStringAuth: true 
});
export const sendData =  (query) => {
  console.log(query)
    const {first_name, last_name, id, addresse, email, phone, quantity, city} = query ;
        const data = {
            payment_method: "Livraison",
            payment_method_title: "Livraison",
            set_paid: true,
            billing: {
              first_name: first_name,
              last_name: last_name,
              address_1: addresse,
              country: "MA",
              email: email,
              phone: phone,
              state: 'Autre',
              city: city,
            },
            shipping: {
              first_name:  first_name,
              last_name: last_name,
              address_1: addresse,
              country: "MA",
              city: city,
              state: 'Autre'
            },

            line_items:  query?.cartItems?.length ? query?.cartItems?.map(cart => (
              {
                product_id: cart.id,
                quantity : cart.quantity
              }
            )): []
            
          };
          WooCommerce.post("orders", data)
      .then((res) => {
      return {
        status: true
      }
      })
      .catch((error) => {

        return {
          status: true
        }
      });
      return {
        status: true
      }
   

}