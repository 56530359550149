import { Container, Details, ShopNew, SeeMore } from "./limited-edition-card.styles";
import React, {useState, useContext} from "react";
import {CartContext} from "../../context/cart.js"
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import ProductDetails from "../product-details/product-details.component";
import {useNavigate, Link} from "react-router-dom"
import {ToastContainer, toast} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';


const LimitedEditionCard = ({product}) => {
    const navigate = useNavigate();
    
    const {addItem} = useContext(CartContext)
    const [hovered, setHovered] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    

    const handleClick = (data) => {
        addItem(data);
        toast.success('تم اضافة المنتج بنجاح', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        
    }
    return <Container onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{
        backgroundImage: `url(${product.backImg})`
    }} >
          
   
        {
            hovered && (
                <Details>
                    <h1>{product.title}</h1>
                    <p>{product.price} درهم</p>
                    <ShopNew onClick={() => handleClick(product)}>أضف الى السلة</ShopNew>
                    <Link 
                     to={{
                        pathname:`/products/${product.id}`,
                        state:product
                     }}
                    >
                    <SeeMore>التفاصيل </SeeMore>
                    </Link>
                </Details>
            )
        }
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className='bla bla bla'
            >
                <ProductDetails toggleDrawer={toggleDrawer} product={product}/>
            </Drawer>


    </Container>
}

export default LimitedEditionCard