import { Container, PaymentMethod,AdrDiv,Select, Title,Directory, Method, FormDetails, BtnGroup,Button, InputGroup, CustomRadio } from "./checkout-form.styles";
import Input from "../form-input/form-input.component";
import {Form, Formik, Field} from "formik";
import {useState} from "react"
import * as Yup from "yup";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {CartContext} from "../../context/cart"
import {useContext, useEffect } from "react"
import CITIES from "../../city"
import { useNavigate } from "react-router-dom";
import { sendData } from "../../service/woocommerce";

const CheckoutForm = () => {
    const navigate = useNavigate()
   
    const {cartItems, clearAllItems} = useContext(CartContext)
    const [isLoading, setIsLoading] = useState(false);
    const [cities, setCities] = useState([]);

    

    {
        
        useEffect(() => {
        if(CITIES){

            setCities(CITIES.data)
        }
       
        
    }, [])
        
    }
    return (
        <Container >
            <PaymentMethod>
                <Title>طريقة الدفع</Title>
                <Directory>
                    <Method margin>
                        <CustomRadio>
                        <input type="radio" checked="checked" name="radio" />
                        <span className="checkmark"></span>
                        </CustomRadio>
                        <div
                        style={{
                            marginTop: ".7rem"
                        }}
                        >
                        <h3>الدفع عند الإستلام </h3>
                        </div>
                    </Method>
                </Directory>
            </PaymentMethod>
               <FormDetails>
               <Title>المعلومات الشخصية</Title>
                   
               <Formik
               initialValues={{
                   first_name: "",
                   last_name: "",
                   email: "",
                   phone: "",
                   addresse : "",
                   city: ''
               }}
               validationSchema={Yup.object({
                first_name: Yup.string().required("First is required."),
                last_name: Yup.string().required("Last is required."),
                email: Yup.string().email().required("Email is required."),
                phone: Yup.string().required("Phone is required."),
                addresse: Yup.string().required("addresse is required."),
                city: Yup.string().required("City is required."),
              })}
              onSubmit={ (values, {resetForm}) => {
                setIsLoading(true)
                const resp = sendData({...values, cartItems})
                console.log(resp)
                if(resp.status){
                    const MySwal = withReactContent(Swal)
                    MySwal.fire(
                        'تم التاكيد !',
                        'لقد تلقينا طلبك ونعمل عليه!',
                        'success'
                      )
                      resetForm()
                      navigate('/')
                      clearAllItems()
                   

                }else{
                    const MySwal = withReactContent(Swal)
                    MySwal.fire(
                        'حدث خطا ما !',
                        'المرجو الاعادة',
                        'error'
                      )
                }
                setIsLoading(false)
              }}
               >
                    {({errors, touched}) =>{
                    
                         return (
                            <Form>
                                <AdrDiv >
                                <InputGroup addr error={errors.first_name}>
                                <Field  as={Input} name="first_name" placeholder="الاسم الاول" type="text"/>
                                </InputGroup>
                                <InputGroup addr error={errors.last_name}>
                                <Field  as={Input} name="last_name" placeholder="الاسم الثاني" type="text"/>
                                </InputGroup>
                                </AdrDiv>
                               <AdrDiv>
                               <InputGroup addr error={errors.addresse}>
                                <Field  as={Input} name="addresse" placeholder="العنوان الكامل" type="text"/>
                                </InputGroup>
                                <InputGroup addr error={errors.city}>
                                <Field  as={Select} name="city" placeholder="المدينة" type="select">
                                    <option  disabled value="">المدينة</option>
                                    {
                                        cities.length && cities.map((city, index) => (
                                            <option value={city.name} key={index}>{city.name}</option>
                                        ))
                                    }
                                </Field>
                                </InputGroup>
                               </AdrDiv>
                                <InputGroup error={errors.email }>
                                <Field  as={Input} name="email" placeholder="البريد الإلكتروني " type="text"/>
                                </InputGroup>
                                <InputGroup error={errors.phone}>
                                <Field  as={Input} name="phone" placeholder="رقم هاتف " type="text"/>
                                </InputGroup>
                                <BtnGroup>
                                    <Button type="button" onClick={() => navigate('/')}  background="white" color="black" border="1px solid black">العودة</Button>
                                    <Button type="submit" background="#007145" color="white" marginLeft="2rem">{isLoading ? "المرجو الانتظار" : "تاكيد"}</Button>
                                </BtnGroup>
                            </Form>
                        )
                    }}
                </Formik>
               </FormDetails>
        </Container>
    )
}

export default CheckoutForm