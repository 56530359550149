import styled from "styled-components"

export const Container = styled.div`
margin: auto;
display: flex;
align-items:center;
padding: 4rem 0;
@media ${props => props.theme.devices.mobileXm}{
    flex-direction: column;
    width: 90%;
}
@media ${props => props.theme.devices.tablet}{
    flex-direction: row;
    width: 80%;
}

`;
export const Logo = styled.div`
height: 300px;
display: flex;
flex-direction: row;
align-items:center;
justify-content:center;
@media ${props => props.theme.devices.mobileXm}{
    margin: 0;
    width: 100%;
    img{
        height: 60%;
    }
}
@media ${props => props.theme.devices.tablet}{
    margin-left: 1rem;
    width: 30%;
}
@media ${props => props.theme.devices.mobileM}{
    img{
        height: 70%;
    }
}
@media ${props => props.theme.devices.tablet}{
    img{
        height: 100%;
        width: 100%;
    }
}

`;
export const Content = styled.div`
flex: 1;
color: #000;
align-self:stretch;
margin-right: 2rem;


`;
export const Text = styled.p`
line-height: 30px;
font-size: 1.1rem;
margin-bottom: 1rem;

`;
export const List = styled.ul`
width: 90%;
@media ${props => props.theme.devices.mobileXm}{
    margin: 0;
}
@media ${props => props.theme.devices.tablet}{
    margin-right: 3rem;
}
`;
export const ListItem = styled.li`
margin: .5rem 0;
list-style: none;
position: relative;
&::before{
    content: " ";
    width: 10px;
    height: 4px;
    background: black;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
}
`;