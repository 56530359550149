import styled from "styled-components";

export const Container = styled.div`
width: 80%;
margin: auto;
padding: 1rem 0;
`;
export const Title = styled.h1`
color: #000;
`;
export const Directory = styled.div`
width: 100%;
display: grid;
column-gap: 1rem;
row-gap: 1.5rem;
padding-top: 2rem;
@media ${props => props.theme.devices.mobileXm}{
    grid-template-columns: repeat(1, minmax(0, 1fr));

}
@media ${props => props.theme.devices.tablet}{
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

`;

export const GoalCard = styled.div`
width: 90%;
padding: 1rem 2rem;
font-size: 1rem;

cursor: pointer;
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
background: ${props => props.background};
color: ${props => props.color};
text-align:center;

`;