import styled from "styled-components"

export const Container  = styled.div`
width: 100%;
height: 20rem;
position: relative;
background-position:center;
background-size:cover;
background-repeat: no-repeat;
display: flex;
flex-direction: row;
align-items:center;
justify-content:center;
align-items:center;
&::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    bottom:0;
    background: rgba(0, 0, 0, .6);
    opacity:0;
    transition: all .7s;
}
&:hover::after{
    opacity: 1;
}
`;

export const Details = styled.div`
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
align-items:center;
width: 100%;
height: 100%;
color: #fff;
z-index: 2;
p{
 display: block;
 font-size: 1.6rem;
 font-weight: bold;
}

`;
export const ShopNew = styled.button`
padding: .6rem 3rem;
color: #000;
background: #fff;
margin-top: 1.6rem;
border: 0;
font-family: 'Cairo', sans-serif;
cursor: pointer;
`;
export const SeeMore = styled.button`
margin-top: 1rem;
color: #fff;
border: 0;
background: transparent;
font-family: 'Cairo', sans-serif;
cursor: pointer;
font-size: 1.1rem;
`;
